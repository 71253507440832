import { AnimationClassNames, FontIcon, ActionButton, } from "office-ui-fabric-react";
import { store } from "react-notifications-component";
import React from "react";

import { generateUUID } from "./utils";

import NotificationSound1 from '../assets/kilaVat.mp3';
import NotificationSound2 from '../assets/goes-without-saying.mp3';

const Notify = {
    shownNotifications: {}, // {notificationID: notifactionSrc}
    notificationSoundPlayer1: new Audio(NotificationSound1),
    notificationSoundPlayer2: new Audio(NotificationSound2),

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Info] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=5] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    info: function (options) {
        this.show({
            ...{ title: options.title || "Info", type: "info", icon: "Info", duration: 5 },
            ...options,
        });
    },

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Success] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=5] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    success: function (options) {
        this.show({
            ...{ title: options.title || "Success", type: "success", icon: "CheckMark", duration: 5 },
            ...options,
        });
    },

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Error] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=10] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    error: function (options) {
        this.show({
            ...{ title: options.title || "Error", type: "danger", icon: "Blocked", duration: 10 },
            ...options,
        });
    },

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Warning] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=7] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    warning: function (options) {
        this.show({
            ...{ title: options.title || "Warning", type: "warning", icon: "Warning", duration: 7 },
            ...options,
        });
    },

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Awesome] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=5] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    awesome: function (options) {
        this.show({
            ...{ title: options.title || "Hello @Marjan!", type: "awesome", icon: "ChatBot", duration: 5 },
            ...options,
        });
    },

    /**
     * 
     * @param  {any} src - Notification source (required if you want to delete all notifications with this source)
     */
    removeNotificationsWithSrc(src) {
        Object.keys(this.shownNotifications)
            .filter((key) => this.shownNotifications[key] === src)
            .forEach((key) => store.removeNotification(key));
    },

    /**
     * @param  {Object} options - Notification options
     * @param {string} [options.title=Info] - Notification title
     * @param {string} options.message - Notification message
     * @param {string} [options.src] - Notification source (required if you want to delete all notifications with this source)
     * @param {number} [options.duration=5] - Notification duration in seconds
     * @param {boolean} [options.announce=false] - Notification announce (play a sound)
     */
    show: function (options) {
        const id = generateUUID();

        store.addNotification({
            id,
            onRemoval: (id) => {
                delete this.shownNotifications[id];
            },
            title: options.title,
            message: options.message,
            type: options.type || "default",
            insert: options.insert || "top",
            container: options.container || "bottom-right",
            // animationIn: ["animated", "fadeIn"],
            // animationOut: ["animated", "fadeOut"],
            animationIn: [AnimationClassNames.slideLeftIn400],
            animationOut: [AnimationClassNames.slideRightOut400],
            slidingEnter: {
                timingFunction: "ease",
            },
            dismiss: {
                duration: options.duration ? options.duration * 1000 : 5000,
                click: false,
            },
            content: (
                <div className={`notification-${options.type}`}>
                    <div className="notification-content">
                        {options.title && (
                            <div className="notification-title">
                                <div style={{ lineHeight: "20px" }}>
                                    {options.icon ? (
                                        <>
                                            <FontIcon iconName={options.icon} style={{ verticalAlign: "bottom" }} /> &nbsp;
                                        </>
                                    ) : null}
                                    {options.title}
                                </div>
                            </div>
                        )}

                        <div className="notification-message">{options.message}</div>

                        {options.actions && (
                            <div className="notification-actions">
                                <hr className="leftAlignedHr" />
                                {options.actions.map((a) => (
                                    <ActionButton
                                        key={generateUUID()}
                                        style={{ height: "auto", margin: 0, padding: 0 }}
                                        text={a.text}
                                        iconProps={{ iconName: a.icon }}
                                        onClick={() => {
                                            store.removeNotification(id);
                                            a.onClick();
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ),
        });

        if (options.announce) {
            if (options.type === "awesome") {
                this.notificationSoundPlayer2.play();
            }
            else {
                this.notificationSoundPlayer1.play();
            }
        }

        this.shownNotifications[id] = options.src;
    },
};

export default Notify;
