import moment from 'moment';
import React from 'react';

import { NContainer } from '../../components/nContainer/nContainer';
import EventEmitter from '../../shared/EventEmitter';
import SharedData from '../../shared/sharedData';

export class ServerTime extends React.Component {
    constructor(props) {
        super(props);

        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked");
                }
            },
            { text: 'Server time', key: 'serverTime', isCurrentItem: true }
        ]);

        this.state = {
            lastSync: Date.now() - SharedData.get('timesync'),
            moment: moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana')
        };
    }

    componentDidMount() {
        this._isMounted = true;

        EventEmitter.subscribe('APPLICATION_TIME', (value) => {
            this.setState({
                lastSync: Date.now() - SharedData.get('timesync'),
                moment: moment.tz(value * 1000, 'Europe/Ljubljana')
            });
        }, 'serverPageTime');
    }

    componentWillUnmount() {
        this._isMounted = false;
        EventEmitter.unsubscribe('APPLICATION_TIME', 'serverPageTime');
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title="Server time"
                            style={{
                                maxHeight: 'unset'
                            }}
                        >
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: '10vw',
                                    lineHeight: '10vw',
                                    textAlign: 'center'
                                }}
                            >
                                {this.state.moment.format('HH:mm:ss')}
                            </div>

                            <div
                                style={{
                                    color: 'white',
                                    fontSize: '3vw',
                                    lineHeight: '6vw',
                                    textAlign: 'center'
                                }}
                            >
                                {this.state.moment.format('dddd, MMMM D, YYYY')}
                            </div>


                            <div
                                style={{
                                    fontStyle: 'oblique',
                                    color: this.state.lastSync < 20000 ? 'slategray' : (this.state.lastSync < 40000 ? 'orange' : 'red'),
                                    // fontSize: '2vw',
                                    textAlign: 'center',
                                }}
                            >
                                {this.state.lastSync >= 20000 && this.state.lastSync < 40000 && <span>Warning: </span>}
                                {this.state.lastSync >= 40000 && <span>Fatal: </span>}
                               Synced {(this.state.lastSync / 1000).toFixed()} second(s) ago.
                            </div>

                            <br />

                        </NContainer>
                    </div>
                </div>






            </div >
        )
    }
}