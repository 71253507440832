import Export from "highcharts-export-csv";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";

import {
    restAPIget,
    getDefaultChartOptions,
    getBrandedColor,
    setInitialDataToGraphSeries,
    setDataPointsToGraphSeries,
    getDefaultChartPlotband,
    getDefaultChartPlotline,
} from "../../shared/utils";
import EventEmitter from "../../shared/EventEmitter";
import SharedData from "../../shared/sharedData";
import moment from "moment";
import { MessageBar, MessageBarType, MessageBarButton } from "office-ui-fabric-react";

import momentRounder from "../../shared/momentRounder";

momentRounder(moment);
Export(Highcharts);

export class SBSDriveLiveQuarter extends React.Component {
    constructor(props) {
        super(props);

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "SIJ Group drive", key: "sijgroupDrive", isCurrentItem: true },
        ]);

        this.state = {
            driveChartIsLoading: true,
            deviationChartIsLoading: true,
            deviationDetails: {
                color: "red",
            },

            showRefreshMessage: false,
        };

        this.abortController = new AbortController();

        this.driveChartRef = React.createRef();
        this.driveChartOptions = getDefaultChartOptions({
            chart: { height: "555px", tooltipShared: true },
            export: {
                filename: "SIJ Group drive",
                csv: {
                    itemDelimiter: ';',
                    decimalPoint: ','
                }
            },
            yAxis: [
                {
                    id: "energy",
                    name: "Energy (MWh)",
                    min: 0,
                },
            ],
            series: [
                {
                    id: "schedule",
                    name: "Schedule",
                    color: getBrandedColor("orange"),
                    type: "line",
                    step: "left",
                    lineWidth: 4,
                    valueSuffix: "MWh",
                    pointPlacement: "left",
                    zIndex: 3,
                },
                {
                    id: "realization",
                    name: "Realization",
                    type: "column",
                    color: getBrandedColor("green"),
                    borderColor: "rgba(30, 40, 60, 0.3)",
                    pointPlacement: "between",
                    valueSuffix: "MWh",
                    zIndex: 1,
                },
                {
                    id: "upperLimit",
                    name: "Upper limit",
                    step: "left",
                    type: "line",
                    color: getBrandedColor("red"),
                    pointPlacement: "left",
                    valueSuffix: "MWh",
                    zIndex: 2,
                    lineWidth: 2,
                    visible: false,
                },
                {
                    id: "lowerLimit",
                    type: "line",
                    step: "left",
                    name: "Lower limit",
                    color: getBrandedColor("blue"),
                    pointPlacement: "left",
                    valueSuffix: "MWh",
                    zIndex: 2,
                    lineWidth: 2,
                    visible: false,
                },
            ],
        });

        this.deviationThisQuarterData = [];
        this.deviationChartRef = React.createRef();
        this.deviationChartOptions = getDefaultChartOptions({
            chart: { height: "555px", tooltipShared: true },
            export: {
                filename: "SIJ Group deviation",
                csv: {
                    itemDelimiter: ';',
                decimalPoint: ','
                }
            },
            yAxis: [
                {
                    id: "energy",
                    name: "Energy (MWh)",
                    // softMin: -70,
                    // softMax: 70,
                },
            ],
            series: [
                {
                    id: "deviation",
                    name: "Deviation",
                    type: "column",
                    color: getBrandedColor("red"),
                    borderColor: "rgba(30, 40, 60, 0.3)",
                    pointPlacement: "between",
                    valueSuffix: "MWh",
                    zIndex: 100,
                },
            ],
        });

        this.WS_DRIVE = SharedData.deepGet("wsChannels", "driveAll", "channelId");
        this.WS_SCHEDULE_EVENT = SharedData.deepGet("wsChannels", "scheduleControl", "channelId");
        this.currentTimestamp = SharedData.get("timestamp");
    }

    componentDidMount() {
        this._isMounted = true;

        EventEmitter.subscribe(
            "APPLICATION_TIME",
            (value) => {
                this.drawCurrentTimeOnGraph(value);
                this.currentTimestamp = value;
            },
            "acroniDriveTime"
        );

        this.initialLoading();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this._isMounted = false;

        clearTimeout(this.forceRefreshTimeout);

        EventEmitter.unsubscribe("APPLICATION_TIME", "acroniDriveTime");
        EventEmitter.unsubscribe(`${this.WS_DRIVE}/unset`, "acroniDriveTime");
        EventEmitter.unsubscribe(`${this.WS_SCHEDULE_EVENT}/scheduleChange`, "acroniDriveTime");

        EventEmitter.dispatch("unsubscribeFrom", [this.WS_DRIVE, this.WS_SCHEDULE_EVENT]);
    }

    initialLoading() {
        const currentTs = SharedData.get("timestamp");
        let momentNow = moment.unix(currentTs);

        let nowQuarterStart = moment.unix(currentTs).floor(15, "minutes").unix();

        this.thereIsDataUpTo = momentNow.clone().add(10, "hours").startOf("minute").unix();

        restAPIget(
            `/measurements/energyandpower/interval/grouped/all/${momentNow.clone().startOf("hour").subtract(15, "minutes").unix()}/${this.thereIsDataUpTo}/30`,
            this.abortController.signal,
            { route: "" }
        ).then((result) => {
            const fromExtreme = momentNow.clone().startOf("hour").subtract(15, "minutes").unix() * 1000;
            const toExtreme = fromExtreme + 90 * 60 * 1000;

            this.driveChartRef.current.chart.xAxis[0].setExtremes(fromExtreme, toExtreme);
            this.deviationChartRef.current.chart.xAxis[0].setExtremes(fromExtreme, toExtreme);

            if (result.isOk) {
                setInitialDataToGraphSeries(
                    Object.values(result.data).map((d) => {
                        return {
                            timestamp: Number(d.intervalStart) * 1000,
                            schedule: Number(d.scheduleEnergy) / 1000,
                            upperLimit: Number(d.upperLimit) / 1000,
                            lowerLimit: Number(d.lowerLimit) / 1000,
                            realization: Number(d.overallCalculatedEnergy) / 1000,
                        };
                    }),
                    ["schedule", "upperLimit", "lowerLimit", "realization"],
                    this.driveChartRef.current.chart
                );

                setInitialDataToGraphSeries(
                    Object.values(result.data).map((d) => {
                        return {
                            timestamp: Number(d.intervalStart) * 1000,
                            deviation: Number(d.deviation) / 1000,
                        };
                    }),
                    ["deviation"],
                    this.deviationChartRef.current.chart
                );

                this.deviationsByQuarter = Object.values(result.data).reduce((resultObj, cv) => {
                    let startOfQuarter = moment.unix(cv.intervalStart).floor(15, "minutes").unix();
                    if (resultObj.hasOwnProperty(startOfQuarter)) {
                        resultObj[startOfQuarter].push(cv);
                    } else {
                        resultObj[startOfQuarter] = [cv];
                    }

                    return resultObj;
                }, {});

                this.deviationThisQuarterData = [...this.deviationsByQuarter[nowQuarterStart]];
            }

            this.recalculateEnergy();
            this.setState({ driveChartIsLoading: false, deviationChartIsLoading: false });

            EventEmitter.dispatch("subscribeTo", [this.WS_DRIVE, this.WS_SCHEDULE_EVENT]);
            EventEmitter.subscribe(
                `${this.WS_DRIVE}/unset`,
                (newValue) => {
                    //#region UPDATE THIS QUARTER DEVIATION
                    let filterFromTs = this.skipNewValuesBeforeTS ?? moment.unix(SharedData.get("timestamp")).floor(15, "minutes").unix() * 1000;

                    Object.keys(newValue).forEach((key) => {
                        let thisInterval = this.deviationThisQuarterData.find((f) => f.intervalStart.toString() === key);

                        if (thisInterval) {
                            thisInterval.overallCalculatedEnergy = newValue[key].overallCalculatedEnergy;
                            thisInterval.scheduleEnergy = newValue[key].scheduleEnergy;
                        } else {
                            if (newValue[key].intervalStart * 1000 >= filterFromTs) {
                                this.deviationThisQuarterData.push(newValue[key]);
                            }
                        }
                    });
                    //#endregion

                    setDataPointsToGraphSeries({
                        data: Object.values(newValue).map((d) => {
                            return {
                                timestamp: Number(d.intervalStart) * 1000,
                                realization: Number(d.overallCalculatedEnergy) / 1000,
                            };
                        }),
                        series: ["realization"],
                        chart: this.driveChartRef.current.chart,
                        deleteDataBeyondTS: this.chartsMinExtreme,
                    });

                    setDataPointsToGraphSeries({
                        data: Object.values(newValue).map((d) => {
                            return {
                                timestamp: Number(d.intervalStart) * 1000,
                                deviation: Number(d.deviation) / 1000,
                            };
                        }),
                        series: ["deviation"],
                        chart: this.deviationChartRef.current.chart,
                        deleteDataBeyondTS: this.chartsMinExtreme,
                    });

                    this.recalculateEnergy();
                },
                "acroniDriveTime"
            );

            EventEmitter.subscribe(
                `${this.WS_SCHEDULE_EVENT}/scheduleChange`,
                (newValue) => {
                    let start = moment(newValue.intervalStart).unix();
                    let stop = moment(newValue.intervalStop).unix();

                    if ((start >= this.currentTimestamp && start <= this.thereIsDataUpTo) || (stop >= this.currentTimestamp && stop <= this.thereIsDataUpTo)) {
                        this.showRefreshMessage();
                    }
                },
                "acroniDriveTime"
            );
        });
    }

    recalculateEnergy() {
        let schedule = 0;
        let energy = 0;
        let deviation = 0;
        let totalSchedule = 0;

        this.deviationThisQuarterData.forEach((obj) => {
            if (!isNaN(obj.overallCalculatedEnergy)) {
                schedule += obj.scheduleEnergy;
                energy += obj.overallCalculatedEnergy || 0;
                deviation += (obj.overallCalculatedEnergy || 0) - obj.scheduleEnergy;
            }
            totalSchedule += obj.scheduleEnergy;
        });

        this.setState({
            schedule: (schedule / 1000).toFixed(2),
            totalSchedule: (totalSchedule / 1000).toFixed(2),
            energy: (energy / 1000).toFixed(2),
            deviation: (deviation / 1000).toFixed(2),
            deviationDetails: {
                text: deviation > 0 ? "HIGH: " : "LOW: ",
                color: deviation > 0 ? "red" : "green",
                backgroundColor:
                    deviation > 0
                        ? "linear-gradient(45deg, rgba(204, 62, 68, 0.15), rgba(204, 62, 68, 0.03) 25%, rgba(204, 62, 68, 0.03) 75%, rgba(204, 62, 68, 0.15))"
                        : "linear-gradient(45deg, rgba(83, 153, 79, 0.15), rgba(83, 153, 79, 0.03) 25%, rgba(83, 153, 79, 0.03) 75%, rgba(83, 153, 79, 0.15))",
            },
        });
    }

    showRefreshMessage() {
        if (!this.isShownRefreshMessage) {
            this.isShownRefreshMessage = true;

            this.setState({
                showRefreshMessage: true,
            });

            this.forceRefreshTimeout = setTimeout(() => {
                if (this._isMounted) {
                    this.forceRefreshPage();
                }
            }, 60000);
        }
    }

    forceRefreshPage() {
        const current = this.props.location.pathname;
        this.props.history.replace(`/reload`);
        setTimeout(() => {
            this.props.history.replace(current);
        }, 1000);
    }

    drawCurrentTimeOnGraph(timestamp) {
        if (!this.isShownRefreshMessage && this.thereIsDataUpTo - timestamp < 2 * 60 * 60) {
            this.showRefreshMessage();
        }

        if (this._isMounted && this.driveChartRef.current && this.driveChartRef.current.chart) {
            let momentNow = moment.unix(timestamp);

            let minExtreme = momentNow.clone().startOf("hour").subtract(15, "minutes").unix() * 1000;
            let maxExtreme = minExtreme + 90 * 60 * 1000;

            if (this.chartsMinExtreme && this.chartsMinExtreme !== minExtreme) {
                let unixStartOfQuarter = moment.unix(timestamp).floor(15, "minutes").unix();
                this.skipNewValuesBeforeTS = unixStartOfQuarter * 1000;

                this.deviationThisQuarterData = this.deviationsByQuarter.hasOwnProperty(unixStartOfQuarter)
                    ? [...this.deviationsByQuarter[unixStartOfQuarter]]
                    : [];
                this.recalculateEnergy();
            }

            this.chartsMinExtreme = minExtreme;

            // #region  DRIVE CHART
            this.driveChartRef.current.chart.xAxis[0].removePlotLine("timeNow");
            this.driveChartRef.current.chart.xAxis[0].removePlotBand("timeBlocked");

            const startOfquarterTs = moment.unix(timestamp).floor(15, "minutes").unix() * 1000;
            const endOfQuarterTs = startOfquarterTs + 15 * 60 * 1000;

            this.driveChartRef.current.chart.xAxis[0].addPlotLine(
                getDefaultChartPlotline({
                    id: "timeNow",
                    label: "Now",
                    value: timestamp * 1000,
                    labelY: 25,
                })
            );

            this.driveChartRef.current.chart.xAxis[0].addPlotBand(
                getDefaultChartPlotband({
                    id: "timeBlocked",
                    color: "rgba(170, 170, 170, 0.1)",
                    from: startOfquarterTs,
                    to: endOfQuarterTs,
                    label: "Actual quarter",
                    labelColor: "orange",
                    labelVerticalAlign: "top",
                    labelY: 15,
                })
            );

            this.driveChartRef.current.chart.xAxis[0].setExtremes(minExtreme, maxExtreme);
            // #endregion

            // #region DEVIATION CHART
            this.deviationChartRef.current.chart.xAxis[0].removePlotLine("timeNow");
            this.deviationChartRef.current.chart.xAxis[0].removePlotBand("timeBlocked");

            this.deviationChartRef.current.chart.xAxis[0].addPlotLine(
                getDefaultChartPlotline({
                    id: "timeNow",
                    label: "Now",
                    value: timestamp * 1000,
                    labelY: 25,
                })
            );

            this.deviationChartRef.current.chart.xAxis[0].addPlotBand(
                getDefaultChartPlotband({
                    id: "timeBlocked",
                    color: "rgba(170, 170, 170, 0.1)",
                    from: startOfquarterTs,
                    to: endOfQuarterTs,
                    label: "Actual quarter",
                    labelColor: "orange",
                    labelVerticalAlign: "top",
                    labelY: 15,
                })
            );

            this.deviationChartRef.current.chart.xAxis[0].setExtremes(minExtreme, maxExtreme);
            // #endregion
        }
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                {this.state.showRefreshMessage && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                                actions={<MessageBarButton onClick={this.forceRefreshPage.bind(this)}>Refresh</MessageBarButton>}
                                styles={{ text: { fontSize: "14px" } }}
                            >
                                The schedule displayed in this page is old. The page will be automatically refreshed in about 1 minute or click on "Refresh" to
                                force it.
                            </MessageBar>
                        </div>
                    </div>
                )}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                        <NContainer title="Total schedule energy (this quarter)" isLoading={this.state.driveChartIsLoading}>
                            <div className="number" style={{ backgroundColor: getBrandedColor("orange") }}>
                                {this.state.totalSchedule}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                        <NContainer title="LIVE schedule energy (this quarter)" isLoading={this.state.driveChartIsLoading}>
                            <div className="number" style={{ backgroundColor: getBrandedColor("orange") }}>
                                {this.state.schedule}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                        <NContainer title="LIVE used energy (this quarter)" isLoading={this.state.driveChartIsLoading}>
                            <div className="number" style={{ backgroundColor: getBrandedColor("green") }}>
                                {this.state.energy}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                        <NContainer
                            title="LIVE deviation (this quarter)"
                            isLoading={this.state.driveChartIsLoading}
                            style={{
                                background: this.state.deviationDetails.backgroundColor,
                            }}
                            headerStyle={{
                                background: this.state.deviationDetails.backgroundColor,
                            }}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "ngenMarket",
                                    text: <span style={{ wordBreak: "keep-all" }}>[NGEN Market]</span>,
                                },
                            ]}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor(this.state.deviationDetails.color) }}>
                                {this.state.deviationDetails.text}
                                {this.state.deviation}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                </div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title="Drive - LIVE"
                            isLoading={this.state.driveChartIsLoading}
                            style={{ maxHeight: "600px" }}
                            rightItems={[
                                {
                                    key: "ExportToCSV",
                                    type: "actionButton",
                                    text: "CSV",
                                    icon: "DownloadDocument",
                                    onClick: function () {
                                        this.driveChartRef.current.chart.downloadCSV();
                                    }.bind(this),
                                },
                            ]}
                        >
                            <div id="chart">
                                <HighchartsReact highcharts={Highcharts} ref={this.driveChartRef} options={this.driveChartOptions} />
                            </div>
                        </NContainer>
                    </div>
                </div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title="Deviations this quarter"
                            isLoading={this.state.deviationChartIsLoading}
                            style={{ maxHeight: "600px" }}
                            rightItems={[
                                {
                                    key: "ExportToCSV",
                                    type: "actionButton",
                                    text: "CSV",
                                    icon: "DownloadDocument",
                                    onClick: function () {
                                        this.deviationChartRef.current.chart.downloadCSV();
                                    }.bind(this),
                                },
                            ]}
                        >
                            <div id="chart">
                                <HighchartsReact highcharts={Highcharts} ref={this.deviationChartRef} options={this.deviationChartOptions} />
                            </div>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
