import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";
import SharedData from "../../shared/sharedData";
import { Text, Link } from "office-ui-fabric-react";

const Restriction = Object.freeze({
    unatuhorized: {
        code: "401",
        text: "Not authorised",
    },
    forbidden: {
        code: "403",
        text: "Forbidden",
    },
});

export class NotAuthorised extends React.Component {
    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Not authorised", key: "notAuthorised", isCurrentItem: true },
        ]);

        this.state = {
            restriction: !props.isReadable ? Restriction.forbidden : Restriction.unatuhorized,
            userEmail: SharedData.deepGet("whoami", "user", "email"),
        };
    }

    render() {
        return (
            <>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer style={{ height: "unset", color: "slategray", textAlign: "center" }} title="Authorization failed!">
                                <Text variant="xLargePlus" block>
                                    Authorization failed!
                                </Text>

                                <Text variant="xLarge" block>
                                    #{this.state.restriction.code} - {this.state.restriction.text.toUpperCase()}
                                </Text>

                                <Text variant="large" block>
                                    Sorry, but '<i>{this.state.userEmail}</i>' is not authorized to access this page !
                                </Text>

                                <br />
                                <Text variant="mediumPlus" style={{ fontStyle: "oblique" }} block>
                                    If you think you should have permission to access a desired page, try to <b>log out</b> and <b>log in</b> again.
                                </Text>
                                <Text variant="mediumPlus" style={{ fontStyle: "oblique" }} block>
                                    If you have problems, contact us: <Link href="mailto: support@ngen.si">support@ngen.si</Link>
                                </Text>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
