import { Dialog, DialogType, DayOfWeek, DatePicker, DialogFooter, ActionButton } from 'office-ui-fabric-react';
import moment from 'moment';
import React from 'react';

import { getDayPickerSettings, _datepicker_formatDate, _datepicker_parseDateFromString, restAPIpostAsJson } from '../../shared/utils';
import SharedData from '../../shared/sharedData';

export class ActivationPlanCopyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date_min: '',
            date_from: '',
            date_to: '',
            hidden: true,
        };
    }

    showDialog(baselineId, minDateTS) {
        this.setState({
            baselineId: baselineId,
            date_min: moment(minDateTS).tz('Europe/Ljubljana').toDate(),
            date_from: moment(minDateTS).tz('Europe/Ljubljana').toDate(),
            date_to: moment(minDateTS).tz('Europe/Ljubljana').toDate(),
            hidden: false
        });
    }

    closeDialog(saveChanges) {
        if (saveChanges) {

            let arraysOfTimestamps = [];

            let momentFrom = moment.tz({ year: this.state.date_from.getFullYear(), month: this.state.date_from.getMonth(), day: this.state.date_from.getDate() }, 'Europe/Ljubljana').unix();
            let momentTo = moment.tz({ year: this.state.date_to.getFullYear(), month: this.state.date_to.getMonth(), day: this.state.date_to.getDate() }, 'Europe/Ljubljana').unix();

            for (let value = momentFrom; value <= momentTo; value += 24 * 60 * 60) {
                arraysOfTimestamps.push({ timestamp: value });
            }

            let copyPlanObj = {
                activation_plan_id: this.state.baselineId,
                days: arraysOfTimestamps
            };

            this.setState(
                { isSaving: true },
                () => {
                    restAPIpostAsJson(`/activationplan/${this.state.baselineId}/copy`, copyPlanObj).then(result => {
                        this.setState({ hidden: true }, () => {
                            if (this.props.onContinue) {
                                this.props.onContinue();
                            }
                        });
                    });
                }
            );
        }
        else {
            this.setState({ hidden: true });
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this.closeDialog.bind(this, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Copy baseline to'
                    // subText: "Carefully enter the details and continue..."
                }}
                // minWidth='450px'
                modalProps={{
                    isBlocking: false,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false
                }}
            >

                <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0 }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <DatePicker
                                onSelectDate={evt => {
                                    if (!evt) {
                                        return;
                                    }
                                    this.setState(prevstate => {
                                        return {
                                            date_from: evt,
                                            date_to: prevstate.date_to < evt ? evt : prevstate.date_to
                                        }
                                    });
                                }}
                                isRequired={true}
                                value={this.state.date_from}
                                minDate={this.state.date_min}
                                label="Copy to date from"
                                showMonthPickerAsOverlay={true}
                                today={new Date(SharedData.get('timeyear'), SharedData.get('timemonth'), SharedData.get('timedate'))}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDayPickerSettings()}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                allowTextInput={false}
                                formatDate={(someValue) => {
                                    return _datepicker_formatDate(someValue);
                                }}
                                parseDateFromString={(someValue) => {
                                    return _datepicker_parseDateFromString(this.state.date_from, someValue);
                                }}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <DatePicker
                                onSelectDate={evt => {
                                    if (!evt) {
                                        return;
                                    }
                                    this.setState({ date_to: evt });
                                }}
                                isRequired={true}
                                value={this.state.date_to}
                                minDate={this.state.date_from}
                                label="Copy to date to"
                                showMonthPickerAsOverlay={true}
                                today={new Date(SharedData.get('timeyear'), SharedData.get('timemonth'), SharedData.get('timedate'))}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDayPickerSettings()}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                allowTextInput={false}
                                formatDate={(someValue) => {
                                    return _datepicker_formatDate(someValue);
                                }}
                                parseDateFromString={(someValue) => {
                                    return _datepicker_parseDateFromString(this.state.date_to, someValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    {!this.state.readOnly && (
                        <ActionButton
                            iconProps={{ iconName: 'Save' }}
                            onClick={this.closeDialog.bind(this, true, false)}
                            text="Save"
                            disabled={
                                !this.state.date_from || this.state.isSaving
                            }
                        />
                    )}

                    <ActionButton iconProps={{ iconName: 'Cancel' }} onClick={this.closeDialog.bind(this, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        )
    }
}