import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import { initializeIcons } from "@uifabric/icons";
import { loadTheme } from "office-ui-fabric-react/lib/Styling";
import React from "react";
import ReactDOM from "react-dom";
import ReactNotification from "react-notifications-component";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import "./fabric-grid.css";
import "./highlight.css";
import "./index.css";
import "./notify.css";

initializeIcons(process.env.PUBLIC_URL + "/cdn/fonts/fabric-icons/");

loadTheme({
    palette: {
        themePrimary: "#5dbb46",
        themeLighterAlt: "#040703",
        themeLighter: "#0f1e0b",
        themeLight: "#1c3815",
        themeTertiary: "#377029",
        themeSecondary: "#51a43d",
        themeDarkAlt: "#69c154",
        themeDark: "#7dcb6a",
        themeDarker: "#9bd88c",
        neutralLighterAlt: "#1b2337",
        neutralLighter: "#1a2236",
        // NEUTRAL LIGHT IS USED AS BACKGROUND FOR SELECTED ITEM IN PICKERS (DATEPICKER, DROPDOWN)
        // neutralLight: '#192133',
        neutralLight: "#2e3649",
        // neutralLight: "#1e273b",
        neutralQuaternaryAlt: "#181e30",
        neutralQuaternary: "#161d2e",
        neutralTertiaryAlt: "#161c2c",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#1b2337",
    },
    isInverted: true,
    defaultFontStyle: { fontFamily: "Ubuntu, sans-serif" },
});

ReactDOM.render(
    <>
        <ReactNotification />
        <App />
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
