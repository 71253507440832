import { Depths } from "@uifabric/fluent-theme";
import { Dialog, DialogType, Spinner, SpinnerSize, ProgressIndicator } from "office-ui-fabric-react";
import React from "react";

import { restAPIget, getCdnImage, getSubdomain } from "../../shared/utils";
import SharedData from "../../shared/sharedData";

import loginBackgroundImage from "../../assets/images/electricity_lite.jpg";
import UserPreferences from "../../shared/userPreferences";

export class InitialLoading extends React.Component {
    constructor(props) {
        super(props);
        this.abortController = new AbortController();

        this.requiredData = ["serverTime", "assets"];
        if (getSubdomain() === "acroni") {
            this.requiredData.push("sbsUserPreferences");
        }

        this.state = {
            percentComplete: 0,
            tasksAvailable: this.requiredData.length,
            tasksDone: 0,
        };
    }

    componentDidMount() {
        this.getTheRequiredData();

        try {
            this.logoSrc = getCdnImage(`./partnerLogo/${getSubdomain()}.png`);
        } catch (error) {
            this.logoSrc = getCdnImage("./partnerLogo/ngen.png");
        }
    }

    getTheRequiredData() {
        const methods = {
            get_serverTime: function () {
                restAPIget("/servertime", this.abortController.signal).then((result) => {
                    if (result.isOk) {
                        SharedData.set("syncedTimestamp", result.data.timestamp);
                        this.verifyRequiredData();
                    }
                });
            }.bind(this),

            get_assets: function () {
                setTimeout(() => {
                    SharedData.set("assets", {
                        1: {
                            id: 1,
                            name: "Tesla storage #1",
                            isBattery: true,
                            code: "PNGEN1-RE1",
                        },
                        2: {
                            id: 2,
                            name: "Talum",
                            isBattery: false,
                            code: "TALUM-RE1",
                        },
                        3: {
                            id: 3,
                            name: "EG Suha",
                            isBattery: true,
                        },
                        4: {
                            id: 4,
                            name: "Tetol",
                            isBattery: false,
                            code: "PNGEN4-RE1",
                        },
                        5: {
                            id: 5,
                            name: "Tesla storage #2",
                            isBattery: true,
                            code: "PNGEN3-RE1",
                        },
                        6: {
                            id: 6,
                            name: "Iskra storage",
                            isBattery: true,
                            code: "DNGEN4-RE1",
                        },
                    });
                    this.verifyRequiredData();
                }, Math.random() * 200 + 100);
            }.bind(this),

            get_sbsUserPreferences: function () {
                restAPIget("/get/permanent/userPreferences", this.abortController.signal, { route: "" }).then((result) => {
                    if (result.isOk) {
                        try {
                            UserPreferences.init(JSON.parse(result.data.value));
                        } catch (e) {
                            console.warn("Failed to parse user preferences");
                        }

                        this.verifyRequiredData();
                    } else {
                        this.verifyRequiredData();
                    }
                });
            }.bind(this),
        };

        this.requiredData.forEach((functionName) => {
            methods[`get_${functionName}`]();
        });
    }

    verifyRequiredData() {
        this.setState(
            (prevstate) => {
                return {
                    tasksDone: prevstate.tasksDone + 1,
                    percentComplete: (prevstate.tasksDone + 1) / prevstate.tasksAvailable,
                };
            },
            () => {
                let everythingOkay = true;

                if (everythingOkay && !SharedData.get("timestamp")) {
                    everythingOkay = false;
                }

                if (everythingOkay && this.props.onContinue && this.state.tasksDone === this.state.tasksAvailable) {
                    this.props.onContinue();
                }
            }
        );
    }

    render() {
        return (
            <>
                <div
                    style={{
                        /* The image used */
                        /* https://www.freeimages.com/photo/windmills-1576113 */
                        background: `url(${loginBackgroundImage})`,
                        filter: "blur(20px)",
                        height: "100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        minWidth: "100vw",
                        minHeight: "100vh",
                        position: "absolute",
                    }}
                />

                <Dialog
                    hidden={false}
                    // onDismiss={this.closeDialog.bind(this, false)}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        // title: 'NCC Login',
                        showCloseButton: false,
                    }}
                    minWidth="350px"
                    modalProps={{
                        isBlocking: true,
                        dragOptions: false,
                        isDarkOverlay: true,
                        overlay: true,
                    }}
                    styles={{
                        main: {
                            boxShadow: Depths.depth64,
                        },
                    }}
                >
                    <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0 }}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <img alt="NGEN logo" src={this.logoSrc} style={{ maxWidth: "calc(100% - 12px)", margin: "6px" }}></img>
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <br />
                                <Spinner label="Initializing..., please wait." ariaLive="assertive" size={SpinnerSize.large} labelPosition="bottom" />
                                <br />
                                <ProgressIndicator
                                    label="Progress"
                                    description={this.state.tasksDone + "/" + this.state.tasksAvailable}
                                    percentComplete={this.state.percentComplete}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </>
        );
    }
}
