import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, DatePicker, DayOfWeek } from "office-ui-fabric-react";
import React from "react";

import { getDayPickerSettings, _datepicker_formatDate, _datepicker_parseDateFromString } from "../../shared/utils";
import SharedData from "../../shared/sharedData";

export class DateFromToPickerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            minData: "",
        };
    }

    _showDialog(dateFrom, dateTo, minDate) {
        this.controller = new AbortController();
        this.setState({
            hidden: false,
            dateFrom: dateFrom || "",
            dateTo: dateTo || "",
            minDate: minDate || "",
        });
    }

    _closeDialog(iAmSure) {
        this.setState({ hidden: true });

        if (iAmSure) {
            this.props.onContinue(this.state);
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this._closeDialog.bind(this, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Choose date from/to",
                    // subText: "Choose the desired date from/to"
                }}
                modalProps={{
                    isBlocking: true,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <DatePicker
                    onSelectDate={(evt) => {
                        if (!evt) {
                            return;
                        }
                        this.setState((prevstate) => {
                            return {
                                dateFrom: evt,
                                dateTo: prevstate.dateTo < evt ? evt : prevstate.dateTo,
                            };
                        });
                    }}
                    isRequired={true}
                    value={this.state.dateFrom}
                    minDate={this.state.minDate}
                    label="Date from"
                    showMonthPickerAsOverlay={true}
                    today={new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate"))}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={getDayPickerSettings()}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    allowTextInput={false}
                    formatDate={(someValue) => {
                        return _datepicker_formatDate(someValue);
                    }}
                    parseDateFromString={(someValue) => {
                        return _datepicker_parseDateFromString(this.state.date_from, someValue);
                    }}
                />

                <DatePicker
                    onSelectDate={(evt) => {
                        if (!evt) {
                            return;
                        }
                        this.setState((prevstate) => {
                            return {
                                dateTo: evt,
                                dateFrom: prevstate.dateFrom > evt ? evt : prevstate.dateFrom,
                            };
                        });
                    }}
                    isRequired={true}
                    value={this.state.dateTo}
                    minDate={this.state.minDate}
                    label="Date to"
                    showMonthPickerAsOverlay={true}
                    today={new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate"))}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={getDayPickerSettings()}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    allowTextInput={false}
                    formatDate={(someValue) => {
                        return _datepicker_formatDate(someValue);
                    }}
                    parseDateFromString={(someValue) => {
                        return _datepicker_parseDateFromString(this.state.dateTo, someValue);
                    }}
                />

                <DialogFooter>
                    <PrimaryButton text="Continue" onClick={this._closeDialog.bind(this, true)} />
                    <DefaultButton text="Cancel" onClick={this._closeDialog.bind(this, false)} />
                </DialogFooter>
            </Dialog>
        );
    }
}
