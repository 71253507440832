const EventEmitter = {
    _events: {},
    _eventsToDispatchWhenSubscriberIsAvailable: {},

    dispatch: function (event, data) {
        if (!this._events[event]) {
            this._eventsToDispatchWhenSubscriberIsAvailable[event] = data;
            return;
        }

        this._events[event].forEach(callbackObject => callbackObject.eventToDo(data))
    },
    dispatchTypes: function (type, data) {
        // currently this is used for all values data are defined as property, the most famous use case:
        // when the WS connection fails, we want to set the user count to empty, and other count of errors and warnings as well
        Object.keys(this._events).forEach(key => {
            this._events[key]
                .filter(callbackObject => callbackObject.propertyType === type)
                .forEach(callbackObject => callbackObject.eventToDo(data))
        });

    },

    /**
     * @param  {String} event Unique name of the event you are subscribing to
     * @param  {Function} callback Function to be executed when the event is called.
     * @param  {String} eventSrc Unique name with which is called to unsubscribe.
     * @param  {String} [propertyType] Can be used later to broadcast a value to all of the functions of that type
     */
    subscribe: function (event, callback, eventSrc, propertyType) {
        if (!this._events[event]) {
            this._events[event] = [];
        }

        this._events[event].push(
            {
                eventUniqId: eventSrc,
                eventToDo: callback,
                propertyType
            }
        );

        if (this._eventsToDispatchWhenSubscriberIsAvailable[event] !== undefined) {
            this.dispatch(event, this._eventsToDispatchWhenSubscriberIsAvailable[event]);
            delete this._eventsToDispatchWhenSubscriberIsAvailable[event];
        }
    },

    /**
     * @param  {String} event Unique name of the event you are unsubscribinf grom
     * @param  {String} eventSrc Unique name with which is was subscribed
     */
    unsubscribe: function (event, eventSrc) {
        if (!this._events[event]) {
            return;
        }

        this._events[event] = this._events[event].filter(
            e => {
                return e.eventUniqId !== eventSrc
            }
        );

        if (this._eventsToDispatchWhenSubscriberIsAvailable[event] !== undefined) {
           // delete this._eventsToDispatchWhenSubscriberIsAvailable[event];
           console.warn("Unsubscribe from a undispatched function happened")
        }
    }

}

module.exports = EventEmitter;
