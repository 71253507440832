import { Depths } from '@uifabric/fluent-theme';
import { FontIcon, ContextualMenuItemType, CommandButton, TooltipHost } from 'office-ui-fabric-react';
import Boost from 'highcharts/modules/boost';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';

import { addDataPointsToGraphSeries, setInitialDataToGraphSeries, getBrandedColor, restAPIget, restAPIpost, getDefaultChartPlotline, generateUUID, hasReadAccess, hasWriteAccess } from '../../shared/utils';
import { IAccept } from '../../components/iAccept/iaccept';
import { NBar } from '../../components/nBar/nBar';
import { NContainer } from '../../components/nContainer/nContainer';
import { NewRampDialog } from '../../components/newRampDialog/newRampDialog';
import { OtherEnergyScheme } from '../../components/energySchemes/otherEnergyScheme';
import { RawDataGrid } from '../../components/rawDataGrid/rawDataGrid';
import { TowerToTowerPowermeter } from '../../components/towerToTowerPowermeter/towerToTowerPowermeter';
import EventEmitter from '../../shared/EventEmitter';
import Notify from '../../shared/notify';
import SharedData from '../../shared/sharedData';

Boost(Highcharts);

export class TalumElectrolysis extends React.Component {
    constructor(props) {
        super(props);

        this.WS_CHANNEL = SharedData.deepGet("wsChannels", "assetMeasurement", "channelId");
        this.PAGE_ID = generateUUID();
        this.SHOW_PRIORITIES = hasReadAccess("assetPriorities");
        this.SHOW_ASSET_SETTINGS = hasWriteAccess("assetSettings");

        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked")
                }
            },
            { text: 'Talum electrolysis', key: 'talumElectrolysis', isCurrentItem: true }
        ]);

        this.abortController = new AbortController();

        this.mostRecentMeasureObj = null; //USED FOR SVGS 

        this.iAcceptReference = React.createRef();
        this.newRampDialogRef = React.createRef();

        //#region powermeterchart relates attributes
        this.powerMeter = React.createRef();

        this.powerChartRef = React.createRef();
        this.powerChartCanAppendData = false;
        this.powerChartMinExtremeTS = 0;
        this.powerChartShowIntervalOfMins = 15;
        this.powerchartIsZoomed = false;

        this.powerChartOptions = {
            chart: {
                zoomType: 'x',
                type: "line",
                height: '355px',
                events: {
                    selection: function (event) {
                        this.powerchartIsZoomed = true;
                    }.bind(this)
                }
            },
            boost: {
                useGPUTranslations: false
            },
            time: {
                timezone: 'Europe/Ljubljana'
            },
            title: {
                text: undefined
            },

            credits: {
                enabled: false
            },

            legend: {
                itemStyle: {
                    color: 'white'
                },
                itemHoverStyle: {
                    color: '#5dbb46'
                }
            },

            tooltip: {
                crosshairs: [
                    true,
                    false
                ],
                shared: true,
                valueDecimals: 2,
                backgroundColor: 'rgba(27, 35, 55, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: 'white'
                    }
                },
                events: {
                    setExtremes: function (e) {
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            this.powerchartIsZoomed = false;
                        }
                    }.bind(this)
                }
            },
            yAxis: [
                {
                    // id: 'power',
                    title: {
                        text: "Power (MW)",
                        style: {
                            color: 'white'
                        }
                    },
                    gridLineColor: 'darkgray',
                    gridLineWidth: 0.5,
                    labels: {
                        style: {
                            color: 'white'
                        }
                    },

                    startOnTick: false,
                    endOnTick: false
                }
            ],
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                },
                area: {
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    boostThreshold: 5000,
                    states: {
                        hover:
                        {
                            enabled: false
                        }
                    }
                }
            },
            series: [

                {
                    id: 'pafrr',
                    name: 'Actual AFRR power',
                    type: 'line',
                    color: '#5dbb46',
                    lineWidth: 2,
                    zIndex: 4,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'safrr',
                    name: 'AFRR setpoint NGEN',
                    type: 'line',
                    color: '#bb7e0e',
                    lineWidth: 1,
                    zIndex: 4,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'sngen',
                    name: 'SNGEN',
                    type: 'line',
                    color: 'deepskyblue',
                    lineWidth: 1,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'sngendyn',
                    name: 'SNGEN DYN',
                    type: 'line',
                    color: 'yellow',
                    lineWidth: 1,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'sramp',
                    name: 'SRAMP',
                    type: 'line',
                    color: 'orangered',
                    lineWidth: 1,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'anafrr',
                    name: 'Max negative AFRR',
                    type: 'area',
                    color: '#cc3e44',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0.95,
                            x2: 0,
                            y2: 0
                        },
                        stops: [
                            [0, 'rgba(255, 0, 0, 0.2)'],
                            [1, 'rgba(255, 0, 0, 0.05)']
                        ]
                    },
                    fillOpacity: 0.95,
                    lineWidth: 1,
                    zIndex: 3,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'apafrr',
                    name: 'Max positive AFRR',
                    type: 'area',
                    color: '#3477aa',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0.95
                        },
                        stops: [
                            [0, 'rgba(0, 89, 255, 0.2)'],
                            [1, 'rgba(0, 89, 255, 0.05)']
                        ]
                    },
                    fillOpacity: 0.95,
                    lineWidth: 1,
                    zIndex: 3,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                }
            ],
        };
        //#endregion


        //#region powerscheme energy relates attributes
        this.powerScheme = React.createRef();
        //#endregion

        this.state = {
            powerMeterIsLoading: true,
            powerChartIsLoading: true,
            powerChartGraphType: '15M',
            powerSchemeIsLoading: true,

            rawDataIsLoading: true,

            assetStatuses: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getInitialData();

        EventEmitter.subscribe('APPLICATION_TIME', (value) => {
            this.drawCurrentTimeOnGraphs(value * 1000);
        }, 'talumElectrolysisDashbardTime');
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();
        Notify.removeNotificationsWithSrc(this.PAGE_ID);
        EventEmitter.unsubscribe('APPLICATION_TIME', 'talumElectrolysisDashbardTime');
        EventEmitter.unsubscribe(`${this.WS_CHANNEL}/unset`, this.PAGE_ID);
        EventEmitter.dispatch('unsubscribeFrom', [this.WS_CHANNEL]);
    }

    getInitialData() {
        restAPIget('/measurements/raw/2/15', this.abortController.signal).then(result => {
            if (result.isOk) {
                let isUnderControl = false;
                if (result.data.length > 0) {
                    this.mostRecentMeasureObj = result.data[result.data.length - 1];
                    isUnderControl = this.mostRecentMeasureObj.statusafrr;

                    if (this.powerMeter && this.powerMeter.current) {
                        if (!isNaN(this.mostRecentMeasureObj.anafrr))
                            this.powerMeter.current.setValue('minPowerScale', (this.mostRecentMeasureObj.anafrr / 1000).toFixed(1));

                        if (!isNaN(this.mostRecentMeasureObj.apafrr))
                            this.powerMeter.current.setValue('maxPowerScale', (this.mostRecentMeasureObj.apafrr / 1000).toFixed(1));

                        if (!isNaN(this.mostRecentMeasureObj.pafrr))
                            this.powerMeter.current.setValue('absPower', (this.mostRecentMeasureObj.pafrr / 1000));

                        let fixFactor = -1; // because the percent have to be negative to make the powermeter work nice     
                        if (this.mostRecentMeasureObj.pafrr <= 0) {
                            if (!isNaN(this.mostRecentMeasureObj.pafrr) && !isNaN(this.mostRecentMeasureObj.anafrr))
                                this.powerMeter.current.setValue('relPower', (this.mostRecentMeasureObj.pafrr / this.mostRecentMeasureObj.anafrr * 100 * fixFactor));
                        }
                        else {
                            if (!isNaN(this.mostRecentMeasureObj.pafrr) && !isNaN(this.mostRecentMeasureObj.apafrr))
                                this.powerMeter.current.setValue('relPower', this.mostRecentMeasureObj.pafrr / this.mostRecentMeasureObj.apafrr * 100);
                        }
                    }


                    if (this.powerScheme && this.powerScheme.current) {
                        if (!isNaN(this.mostRecentMeasureObj.b))
                            this.powerScheme.current.setValue('basepower', this.mostRecentMeasureObj.b);

                        if (!isNaN(this.mostRecentMeasureObj.p))
                            this.powerScheme.current.setValue('currentpower', this.mostRecentMeasureObj.p);

                        if (!isNaN(this.mostRecentMeasureObj.pafrr))
                            this.powerScheme.current.setValue('pafrr', this.mostRecentMeasureObj.pafrr);
                    }

                    if (this.powerChartRef.current && this.powerChartRef.current.chart) {
                        setInitialDataToGraphSeries(
                            result.data.map(
                                d => {
                                    return {
                                        timestamp: Number(d.timestamp),
                                        pafrr: Number(d.pafrr) / 1000,
                                        safrr: Number(d.safrr) / 1000,
                                        anafrr: Number(d.anafrr) / 1000,
                                        apafrr: Number(d.apafrr) / 1000,
                                        sngen: Number(d.sngen),
                                        sngendyn: Number(d.sngendyn),
                                        sramp: Number(d.sramp)
                                    };
                                }
                            ),
                            ['pafrr', 'safrr', 'anafrr', 'apafrr', 'sngen', 'sngendyn', 'sramp'],
                            this.powerChartRef.current.chart
                        );
                    }


                }

                this.powerChartCanAppendData = true;


                this.setState({
                    underControl: isUnderControl,

                    powerMeterIsLoading: false,
                    powerChartIsLoading: false,
                    rawDataIsLoading: false,
                    powerSchemeIsLoading: false,

                    connectionRawData: this.mostRecentMeasureObj,

                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        deviceActivated: {
                            code: 'A',
                            source: 'NGEN',
                            color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        },
                        deviceReady: {
                            code: 'R',
                            source: 'TALUM',
                            color: this.mostRecentMeasureObj.devrdy ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.devrdy ? 'Ready' : 'NOT ready'
                        },
                        ...this.SHOW_PRIORITIES && {
                            priority_apafrr: {
                                code: (<FontIcon iconName="Up" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Positive priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_pos,
                                shortDescription: (<><FontIcon iconName="Add" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_pos}</>),
                            },
                            priority_anafrr: {
                                code: (<FontIcon iconName="Down" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Negative priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_neg,
                                shortDescription: (<><FontIcon iconName="Remove" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_neg}</>),
                            }
                        }
                    }
                }, () => {
                    this.props.setPath(null, this.state.assetStatuses);
                    this.subsbribeToWebsocket();
                });
            }
            else if (result.error.code !== 20) {
                Notify.error({ message: result.error.message, src: this.PAGE_ID });
            }
        });
    }

    subsbribeToWebsocket() {
        EventEmitter.dispatch('subscribeTo', [this.WS_CHANNEL]);
        EventEmitter.subscribe(`${this.WS_CHANNEL}/unset`, (newValue) => {
            if (this._isMounted && newValue.data.length > 0) {
                this.mostRecentMeasureObj = newValue.data[0];

                if (this.powerMeter && this.powerMeter.current) {
                    if (!isNaN(this.mostRecentMeasureObj.anafrr))
                        this.powerMeter.current.setValue('minPowerScale', (this.mostRecentMeasureObj.anafrr / 1000).toFixed(1));

                    if (!isNaN(this.mostRecentMeasureObj.apafrr))
                        this.powerMeter.current.setValue('maxPowerScale', (this.mostRecentMeasureObj.apafrr / 1000).toFixed(1));

                    if (!isNaN(this.mostRecentMeasureObj.pafrr))
                        this.powerMeter.current.setValue('absPower', (this.mostRecentMeasureObj.pafrr / 1000));

                    let fixFactor = -1; // because the percent have to be negative to make the powermeter work nice     
                    if (this.mostRecentMeasureObj.pafrr <= 0) {
                        if (!isNaN(this.mostRecentMeasureObj.pafrr) && !isNaN(this.mostRecentMeasureObj.anafrr))
                            this.powerMeter.current.setValue('relPower', (this.mostRecentMeasureObj.pafrr / this.mostRecentMeasureObj.anafrr * 100 * fixFactor));
                    }
                    else {
                        if (!isNaN(this.mostRecentMeasureObj.pafrr) && !isNaN(this.mostRecentMeasureObj.apafrr))
                            this.powerMeter.current.setValue('relPower', this.mostRecentMeasureObj.pafrr / this.mostRecentMeasureObj.apafrr * 100);
                    }
                }

                if (this.powerChartRef.current && this.powerChartRef.current.chart && this.powerChartCanAppendData) {
                    addDataPointsToGraphSeries(
                        //DATA IS DESC SORTED, BUT WE WANT TO MAINTAIN ASC SORTING FOR HIGHCHARTS (because reasons)
                        newValue.data.reverse().map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    pafrr: Number(d.pafrr) / 1000,
                                    safrr: Number(d.safrr),
                                    anafrr: Number(d.anafrr) / 1000,
                                    apafrr: Number(d.apafrr) / 1000,
                                    sngen: Number(d.sngen),
                                    sngendyn: Number(d.sngendyn),
                                    sramp: Number(d.sramp)
                                };
                            }
                        ),
                        ['pafrr', 'safrr', 'anafrr', 'apafrr', 'sngen', 'sngendyn', 'sramp'],
                        this.powerChartRef.current.chart,
                        this.powerChartMinExtremeTS
                    );
                }

                if (this.powerScheme && this.powerScheme.current) {
                    if (!isNaN(this.mostRecentMeasureObj.b))
                        this.powerScheme.current.setValue('basepower', this.mostRecentMeasureObj.b);

                    if (!isNaN(this.mostRecentMeasureObj.p))
                        this.powerScheme.current.setValue('currentpower', this.mostRecentMeasureObj.p);

                    if (!isNaN(this.mostRecentMeasureObj.pafrr))
                        this.powerScheme.current.setValue('pafrr', this.mostRecentMeasureObj.pafrr);
                }

                this.setState({
                    underControl: this.mostRecentMeasureObj.statusafrr,
                    connectionRawData: this.mostRecentMeasureObj,
                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        deviceActivated: {
                            code: 'A',
                            source: 'NGEN',
                            color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        },
                        deviceReady: {
                            code: 'R',
                            source: 'TALUM',
                            color: this.mostRecentMeasureObj.devrdy ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.devrdy ? 'Ready' : 'NOT ready'
                        },
                        ...this.SHOW_PRIORITIES && {
                            priority_apafrr: {
                                code: (<FontIcon iconName="Up" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Positive priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_pos,
                                shortDescription: (<><FontIcon iconName="Add" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_pos}</>),
                            },
                            priority_anafrr: {
                                code: (<FontIcon iconName="Down" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Negative priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_neg,
                                shortDescription: (<><FontIcon iconName="Remove" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_neg}</>),
                            }
                        }
                    }
                }, () => {
                    this.props.setPath(null, this.state.assetStatuses)
                });
            }
        }, this.PAGE_ID);
    }

    reinitPowerChartData() {
        this.powerChartCanAppendData = false;
        this.setState({
            powerChartIsLoading: true
        });

        this.powerChartRef.current.chart.xAxis[0].setExtremes(undefined, undefined);

        restAPIget(`/measurements/raw/2/${this.powerChartShowIntervalOfMins}`, this.abortController.signal).then(result => {
            if (result.isOk) {
                if (result.data.length > 0) {
                    setInitialDataToGraphSeries(
                        result.data.map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    pafrr: Number(d.pafrr) / 1000,
                                    safrr: Number(d.safrr) / 1000,
                                    anafrr: Number(d.anafrr) / 1000,
                                    apafrr: Number(d.apafrr) / 1000,
                                    sngen: Number(d.sngen),
                                    sngendyn: Number(d.sngendyn),
                                    sramp: Number(d.sramp)
                                };
                            }
                        ),
                        ['pafrr', 'safrr', 'anafrr', 'apafrr', 'sngen', 'sngendyn', 'sramp'],
                        this.powerChartRef.current.chart
                    );
                }
            }

            this.powerChartCanAppendData = this.powerChartShowIntervalOfMins <= 60;
            this.setState({
                powerChartIsLoading: false
            });
        });
    }

    toggleAssetAvailability(additionalValue) {
        restAPIpost(
            '/asset/2/status',
            { pw: "GESLO123", activated: !this.state.underControl }
        ).then(() => {
            Notify.info({ message: "Action sent, please wait few seconds to see if it works, the data is shown with delay!" });
        });
    }

    setNewRamp(value) {
        restAPIpost('/asset/2/ramp', { max_power_ramp_step: value.rampValue }).then(result => {
            if (result.isOk) {
                Notify.success({ message: "New ramp succesfully saved!" });
            }
            else {
                Notify.error({ message: "New ramp failed to save, try again and/or contact administrator!" });
            }
        });
    }

    drawCurrentTimeOnGraphs(Now) {
        if (this._isMounted && this.powerChartRef.current && this.powerChartRef.current.chart) {
            this.powerChartRef.current.chart.xAxis[0].removePlotLine('timeNow');

            if (this.powerChartShowIntervalOfMins <= 60) {
                this.powerChartMinExtremeTS = Now - this.powerChartShowIntervalOfMins * 60/*s*/ * 1000/*ms*/;

                if (!this.powerchartIsZoomed) {
                    this.powerChartRef.current.chart.xAxis[0].setExtremes(undefined, Now + 5000);
                }

                this.powerChartRef.current.chart.xAxis[0].addPlotLine(
                    getDefaultChartPlotline({
                        id: 'timeNow',
                        label: 'Now',
                        value: Now,
                    })
                );
            }
        }
    }

    render() {
        let assetStatusesUI = Object.keys(this.state.assetStatuses).map((statusKey, statusIndex, statusArray) => {
            let theCurrentStatus = this.state.assetStatuses[statusKey];

            if (theCurrentStatus.shortDescription) {
                return (
                    <TooltipHost
                        // content={theCurrentStatus.description}
                        // tooltipProps={() => {
                        //     return <> {theCurrentStatus.shortDescription}  (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source}) </>
                        // }}
                        tooltipProps={{
                            onRenderContent: () => {
                                return <> {theCurrentStatus.description}  (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source}) </>
                            }
                        }}
                        key={statusKey}
                    >
                        <span
                            style={{
                                backgroundColor: theCurrentStatus.color,
                                padding: '3px 6px',
                                boxShadow: Depths.depth4,
                                cursor: 'default',
                                whiteSpace: 'nowrap'
                            }}
                        >{theCurrentStatus.shortDescription}</span>

                        {statusIndex !== statusArray.length - 1 && <>&nbsp;</>}
                    </TooltipHost>
                );
            }
            else {
                return (
                    <span key={statusKey}>
                        <span
                            style={{
                                backgroundColor: theCurrentStatus.color,
                                padding: '3px 6px',
                                boxShadow: Depths.depth4,
                                cursor: 'default',
                                whiteSpace: 'nowrap'
                            }}
                        >{theCurrentStatus.description} (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source})</span>

                        {statusIndex !== statusArray.length - 1 && <>&nbsp;</>}
                    </span>
                );
            }
        });

        return (
            <>
                <IAccept ref={this.iAcceptReference} strictMode="true" onContinue={this.toggleAssetAvailability.bind(this)}></IAccept>

                <NewRampDialog ref={this.newRampDialogRef} assetId="2" onContinue={this.setNewRamp.bind(this)}></NewRampDialog>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NBar>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ padding: '0 8px' }}>
                                        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Talum electrolysis</span>
                                    </div>

                                    <div className="ms-Grid-col ms-xxl6 ms-hiddenXlDown" style={{ textAlign: "center", padding: '0' }}>
                                        {assetStatusesUI}
                                    </div>

                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ textAlign: "right", padding: '0 12px' }}>
                                        {this.SHOW_ASSET_SETTINGS &&
                                            <CommandButton iconProps={{ iconName: 'SetAction' }} text="Asset actions" menuProps={{
                                                items: [
                                                    {
                                                        key: 'enableDisable',
                                                        text: (<><span>{this.state.underControl ? "Disable AFRR" : "Enable AFRR"} </span> &nbsp;<span>(<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> NGEN)</span></>),
                                                        iconProps: { iconName: this.state.underControl === 'true' ? 'Blocked' : 'ReturnToSession' },
                                                        disabled: this.state.underControl === null,
                                                        onClick: () => {
                                                            this.iAcceptReference.current._showDialog();
                                                        }
                                                    },

                                                    {
                                                        key: 'apManual',
                                                        text: (<><span>Set new ramp</span></>),
                                                        iconProps: { iconName: 'ChartYAngle' },
                                                        onClick: () => {
                                                            this.newRampDialogRef.current._showDialog();
                                                        }
                                                    }
                                                ]
                                            }} />
                                        }
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-hiddenXxlUp" style={{ textAlign: "left", padding: '0 8px' }}>
                                        {assetStatusesUI}
                                    </div>
                                </div>
                            </NBar>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl5 ms-xxl4 ms-xxxl3">
                            <NContainer
                                title="Power meter"
                                isLoading={this.state.powerMeterIsLoading}
                                style={{
                                    height: '400px'
                                }}
                            >
                                <TowerToTowerPowermeter
                                    id="TalumElectrolysisPowerMeter"
                                    ref={this.powerMeter}
                                />
                            </NContainer>
                        </div>
                        <div className={'ms-Grid-col ms-sm12 ' + (this.state.powerChartExpanded ? '' : ' ms-xl7 ms-xxl8 ms-xxxl9')}>
                            <NContainer
                                title="Power chart"
                                isLoading={this.state.powerChartIsLoading}
                                style={{
                                    height: this.state.powerChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'graphIsLive',
                                        type: 'customText',
                                        style: { color: ['NOW', '15M', '30M', '60M'].includes(this.state.powerChartGraphType) ? getBrandedColor('green') : getBrandedColor('red') },
                                        text: <><FontIcon iconName={['NOW', '15M', '30M', '60M'].includes(this.state.powerChartGraphType) ? 'Streaming' : 'StreamingOff'} style={{ fontSize: '1.3em', lineHeight: '1em', verticalAlign: 'middle', marginTop: '-6px' }} /></>
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        type: 'chooseOption',
                                        icon: "DateTime2",
                                        key: 'ChooseViewType',
                                        selectedOption: this.state.powerChartGraphType,
                                        options: [{
                                            key: 'NOW',
                                            text: 'Now',
                                            iconProps: {
                                                iconName: 'ReminderTime'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 2;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: 'NOW' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: 'TODAY',
                                            text: 'Today',
                                            iconProps: {
                                                iconName: 'GotoToday'
                                            },
                                            onClick: () => {
                                                let startOfDay = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').startOf('day');
                                                this.powerChartMinExtremeTS = 0;
                                                this.powerChartShowIntervalOfMins = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').diff(startOfDay, 'minutes');
                                                this.setState({ powerChartGraphType: 'TODAY' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: 'divider_1',
                                            itemType: ContextualMenuItemType.Divider
                                        },
                                        {
                                            key: '15M',
                                            text: 'Last 15 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 15;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '15M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '30M',
                                            text: 'Last 30 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {

                                                this.powerChartShowIntervalOfMins = 30;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '30M' });
                                                this.reinitPowerChartData();

                                            }
                                        },
                                        {
                                            key: '60M',
                                            text: 'Last hour',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 60;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '60M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '180M',
                                            text: 'Last 3 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 180;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '180M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '360M',
                                            text: 'Last 6 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 360;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '360M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '720M',
                                            text: 'Last 12 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 720;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '720M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '1440M',
                                            text: 'Last 24 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 1440;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '1440M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        ]
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        icon: this.state.powerChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.powerChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    powerChartExpanded: !(prevstate.powerChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.powerChartExpanded ? 655 : 355;
                                                this.powerChartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('powerChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="powerChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.powerChartRef}
                                        options={this.powerChartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl5 ms-xxl4 ms-xxxl3">
                            <NContainer
                                title="Power scheme"
                                isLoading={this.state.powerSchemeIsLoading}
                                style={{
                                    height: '400px'
                                }}
                            >
                                <OtherEnergyScheme id="talumPowerScheme" ref={this.powerScheme} ></OtherEnergyScheme>
                            </NContainer>
                        </div>
                        <div className={'ms-Grid-col ms-sm12 ' + (this.state.energyChartExpanded ? '' : ' ms-xl7 ms-xxl8 ms-xxxl9')}>
                            <NContainer
                                title="Energy chart"
                                style={{
                                    height: this.state.energyChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        disabled: true,
                                        icon: this.state.energyChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.energyChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    energyChartExpanded: !(prevstate.energyChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.energyChartExpanded ? 655 : 355;
                                                this.energyChartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('energyChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="energyChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.energyChartRef}
                                        options={this.state.EnergyChartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer title="Connection raw data" isLoading={this.state.rawDataIsLoading}>
                                <RawDataGrid data={this.state.connectionRawData}></RawDataGrid>
                            </NContainer>
                        </div>
                    </div>
                </div >
            </>
        );
    }

}