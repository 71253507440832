import React from "react";

import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, TextField, MessageBar } from "office-ui-fabric-react";
import { restAPIget } from "../../shared/utils";

export class NewRampDialog extends React.Component {
    controller = new AbortController();

    constructor(props) {
        super(props);
        this.state = {
            rampValue: "",
            allowedToContinue: false,
            currentRamp: undefined,
        };
    }

    _showDialog() {
        this.controller = new AbortController();
        this.setState({
            hidden: false,
            rampValue: "",
            allowedToContinue: false,
            currentRamp: undefined,
        });

        restAPIget("/asset/" + this.props.assetId + "/settings", this.controller.signal).then((result) => {
            if (result.isOk) {
                this.setState({
                    currentRamp: result.data.max_ramp_power_step,
                });
            }
        });
    }

    _closeDialog(iAmSure) {
        this.controller.abort();

        this.setState({ hidden: true });

        if (iAmSure) {
            this.props.onContinue(this.state);
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this._closeDialog.bind(this, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Ramp value",
                    subText: "Set your new desired ramp!",
                }}
                modalProps={{
                    isBlocking: true,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <TextField
                    label="New ramp (kW / s)"
                    type="number"
                    name="newRampValue"
                    min={0}
                    required={true}
                    value={this.state.rampValue}
                    onChange={(evt) => {
                        let theValue = Math.abs(Number(evt.target.value));
                        this.setState({
                            rampValue: theValue,
                            allowedToContinue: theValue ? true : false,
                        });
                    }}
                />

                <MessageBar styles={{ root: { background: "unset" }, iconContainer: { marginLeft: "0" } }}>
                    Current value: {this.state.currentRamp === null ? "Unknown" : this.state.currentRamp || "Loading ..."}
                </MessageBar>

                <DialogFooter>
                    <PrimaryButton disabled={!this.state.allowedToContinue} onClick={this._closeDialog.bind(this, true)} text="Continue" />
                    <DefaultButton onClick={this._closeDialog.bind(this, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }
}
