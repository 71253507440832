import React from 'react';
import * as moment from "moment";

export class RawDataGrid extends React.Component {

    getReadableNumber(value, divideBy) {
        if (value !== null) {
            // if (divideBy) {
            //     return value / divideBy;
            // }
            // else {
            //     return value;
            // }
            return value.toFixed(2);
        }
        else {
            return 'N/A';
        }
    }

    getReadableBoolean(value) {
        if (value !== null) {
            // if (divideBy) {
            //     return value / divideBy;
            // }
            // else {
            //     return value;
            // }
            return value === 0 ? 'FALSE' : 'TRUE';
        }
        else {
            return 'N/A';
        }
    }

    render() {
        return (
            <>
                <div className="ms-Grid rawDataGrid" dir="ltr" style={{ margin: '12px' }}>
                    <div className="ms-Grid-row">
                        {this.props.data && this.props.data.timestamp !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {moment.tz(this.props.data.timestamp, 'Europe/Ljubljana').format('HH:mm:ss')}
                                    </div>
                                    <div className="description">Last change</div>
                                </div>
                            </div>
                        )}

                        {this.props.data && this.props.data.devrdy !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableBoolean(this.props.data.devrdy)}
                                    </div>
                                    <div className="description">Device ready</div>
                                </div>
                            </div>

                        )}

                        {this.props.data && this.props.data.an !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.an, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Current (-) max AFRR</div>
                                </div>
                            </div>
                        )}

                        {this.props.data && this.props.data.ap !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.ap, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Current (+) max AFRR</div>
                                </div>
                            </div>
                        )}


                        {this.props.data && this.props.data.safrr !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.safrr, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">AFRR setpoint NGEN</div>
                                </div>
                            </div>
                        )}

                        {this.props.data && this.props.data.pafrr !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.pafrr, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Actual AFRR power</div>
                                </div>
                            </div>
                        )}


                        {this.props.data && this.props.data.p !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.p, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Actual power</div>
                                </div>
                            </div>
                        )}

                        {this.props.data && this.props.data.b !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.b, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Current base line</div>
                                </div>
                            </div>
                        )}

                        {this.props.data && this.props.data.b5 !== undefined && (
                            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                                <div className="container">
                                    <div className="number">
                                        {this.getReadableNumber(this.props.data.b5, 1000)}<span>kW</span>
                                    </div>
                                    <div className="description">Base line 5 mins</div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </>
        );
    }

}