import { Separator, TooltipHost, FontIcon } from "office-ui-fabric-react";
import React from "react";

import { getBrandedColor, restAPIget, convertSecondsToString } from "../../shared/utils";
import { NContainer } from "../../components/nContainer/nContainer";
import { PositiveMaxPowermeter } from "../../components/positiveMaxPowermeter/positiveMaxPowermeter";
import EventEmitter from "../../shared/EventEmitter";
import SharedData from "../../shared/sharedData";

function TrafoStatus(props) {
    return (
        <TooltipHost
            tooltipProps={{
                // MOŽNA NAPAKA V 15 MINUTAH
                // ZARADI ZANESLJIVOSTI METIREV SE PRIMERIJO PODATKI IZ ENERDATA IN PODATKE KI SO OCITANI S PLCIEM NA 2 SEK NEIVOJU. iN VSAKA ODSTOPANJA KI SO VIŠJA KOT 20KWH SE JAVAI NAPAKA

                onRenderContent: () => (
                    <span style={{ fontSize: "1rem" }}>
                        A difference of {props.deviation}KWh in last 15 minutes!
                        <br />
                        <span style={{ fontStyle: "oblique", color: "gray" }}>
                            - For data reliability, the measurements are compared between "Enerdata" and "PLC measures every 2 seconds". Every difference bigger
                            than 20KWh triggers a possible error.
                        </span>
                    </span>
                ),
            }}
        >
            <FontIcon
                iconName="Warning"
                style={{
                    color: "orange",
                    fontSize: "24px",
                    lineHeight: "20px",
                    verticalAlign: "middle",
                }}
            />
        </TooltipHost>
    );
}

export class SBSHome extends React.Component {
    controller = new AbortController();

    constructor(props) {
        super(props);

        this.acroniTotalPowerRef = null; //IT IS A REFERENCE
        this.trafo1PowerRef = null; //IT IS A REFERENCE
        this.trafo2PowerRef = null; //IT IS A REFERENCE
        this.trafo3PowerRef = null; //IT IS A REFERENCE

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Home", key: "home", isCurrentItem: true },
        ]);

        this.state = {
            isLoadingMeters: true,
            isLoadingEnergyToday: true,

            currentTs: SharedData.get("timestamp"),
        };

        this.WS_MEASURED_POWER = SharedData.deepGet("wsChannels", "latestMeasuredPower", "channelId");
        this.WS_ENERGY_TODAY = SharedData.deepGet("wsChannels", "energyToday", "channelId");
    }

    setEnergyToday(newObj) {
        this.setState({
            totalPosEnergyToday: Math.round(newObj.overallCalculatedEnergyPositive.energy / 1000),
            totalTrafo1PosEnergyToday: Math.round(newObj["TRAFO1_A+"].energy / 1000),
            totalTrafo2PosEnergyToday: Math.round(newObj["TRAFO2_A+"].energy / 1000),
            totalTrafo3PosEnergyToday: Math.round(newObj["TRAFO3_A+"].energy / 1000),

            // totalNegEnergyToday: Math.round(newObj.overallCalculatedEnergyNegative.energy / 1000),
            // totalTrafo1NegEnergyToday: Math.round(newObj["TRAFO1_A-"].energy / 1000),
            // totalTrafo2NegEnergyToday: Math.round(newObj["TRAFO2_A-"].energy / 1000),
            // totalTrafo3NegEnergyToday: Math.round(newObj["TRAFO3_A-"].energy / 1000),
            isLoadingEnergyToday: false,
        });

        try {
            this.setState({
                AcroniTotalPosEnergyToday: Math.round(
                    (newObj["TRAFO1_A+"].energy + newObj["TRAFO2_A+"].energy + newObj["TRAFO3_A+"].energy - newObj["BAT_A+"].energy + newObj["BAT_A-"].energy) /
                        1000
                ),
                RavneTotalPosEnergyToday: Math.round((newObj["F-ELMET162"].energy + newObj["F-ELSIS048"].energy + newObj["F-ELZIP000"].energy) / 1000),
                isLoadingEnergyToday: false,
            });
        } catch (error) {
            console.log(error);
        }
    }

    checkErrors() {
        restAPIget("/measurements/errorcheck", this.controller.signal, { route: "" }).then((result) => {
            if (result.isOk) {
                this.setState({
                    posEnergyTrafo1Error: result.data["TRAFO1_A+"],
                    posEnergyTrafo2Error: result.data["TRAFO2_A+"],
                    posEnergyTrafo3Error: result.data["TRAFO3_A+"],
                });
            }

            this.checkErrosTimeout = setTimeout(() => {
                this.checkErrors();
            }, 60 * 1000);
        });
    }

    calculateAge(compareFrom, compareTo) {
        if (!compareFrom || !compareTo) {
            return "0s";
        }

        const diff = compareFrom - compareTo;
        if (diff <= 0) {
            return "0s";
        } else {
            return convertSecondsToString(diff);
        }
    }

    componentDidMount() {
        restAPIget("/measurements/energy/interval/all", this.controller.signal, { route: "" }).then((result) => {
            if (result.isOk) {
                this.setEnergyToday(result.data);
            }
        });

        this.checkErrors();

        EventEmitter.subscribe(
            "APPLICATION_TIME",
            (value) => {
                this.setState({ currentTs: value });
            },
            "homeTime"
        );

        EventEmitter.dispatch("subscribeTo", [this.WS_MEASURED_POWER, this.WS_ENERGY_TODAY]);
        EventEmitter.subscribe(
            `${this.WS_MEASURED_POWER}/unset`,
            (newValue) => {
                let acroniData = newValue["cf8d8228-71c2-472e-885b-be400e852a9b"];
                let ravneData = newValue["2be0b917-13d6-4ab7-9d87-71be5078abfb"];

                try {
                    this.acroniTotalPowerRef.setValue("absPower", acroniData.overallCalculatedPower.power / 1000);
                    this.acroniTotalPowerRef.setValue("relPower", (acroniData.overallCalculatedPower.power / 1000 / 125) * 100);

                    this.trafo1PowerRef.setValue("absPower", acroniData["TRAFO1_A+"].power / 1000);
                    this.trafo1PowerRef.setValue("relPower", (acroniData["TRAFO1_A+"].power / 1000 / 40) * 100);

                    this.trafo2PowerRef.setValue("absPower", acroniData["TRAFO2_A+"].power / 1000);
                    this.trafo2PowerRef.setValue("relPower", (acroniData["TRAFO2_A+"].power / 1000 / 75) * 100);

                    this.trafo3PowerRef.setValue("absPower", acroniData["TRAFO3_A+"].power / 1000);
                    this.trafo3PowerRef.setValue("relPower", (acroniData["TRAFO3_A+"].power / 1000 / 100) * 100);
                } catch (error) {
                    console.log(error);
                }

                try {
                    this.ravneTotalPowerRef.setValue("absPower", ravneData.overallCalculatedPower.power / 1000);
                    this.ravneTotalPowerRef.setValue("relPower", (ravneData.overallCalculatedPower.power / 1000 / 55) * 100);
                } catch (error) {
                    console.log(error);
                }

                try {
                    let totalPower = ravneData.overallCalculatedPower.power + acroniData.overallCalculatedPower.power;
                    let relTotalPower = 125 + 55;
                    this.totalPowerRef.setValue("absPower", totalPower / 1000);
                    this.totalPowerRef.setValue("relPower", (totalPower / 1000 / relTotalPower) * 100);
                } catch (error) {
                    console.log(error);
                }

                try {
                    this.setState({
                        ravneTimestamp: ravneData.overallCalculatedPower.timestamp,
                        acroniTimestamp: acroniData.overallCalculatedPower.timestamp,
                        totalTimestamp: Math.max(ravneData.overallCalculatedPower.timestamp, acroniData.overallCalculatedPower.timestamp),
                    });
                } catch (error) {
                    console.log(error);
                }

                if (this.state.isLoadingMeters) {
                    this.setState({ isLoadingMeters: false });
                }
            },
            "AcroniHomePage"
        );

        EventEmitter.subscribe(
            `${this.WS_ENERGY_TODAY}/unset`,
            (newValue) => {
                this.setEnergyToday(newValue);
            },
            "AcroniHomePage"
        );
    }

    componentWillUnmount() {
        this.controller.abort();
        clearTimeout(this.checkErrosTimeout);

        EventEmitter.unsubscribe("APPLICATION_TIME", "homeTime");
        EventEmitter.unsubscribe(`${this.WS_MEASURED_POWER}/unset`, "AcroniHomePage");
        EventEmitter.unsubscribe(`${this.WS_ENERGY_TODAY}/unset`, "AcroniHomePage");

        EventEmitter.dispatch("unsubscribeFrom", [this.WS_MEASURED_POWER, this.WS_ENERGY_TODAY]);
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE SIJ GROUP TOTAL power"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.totalTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="totalPowerRef"
                                scaleDecimals="0"
                                color={getBrandedColor("green")}
                                ref={(thisRef) => {
                                    this.totalPowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.totalPowerRef.setValue("maxPowerOnlyScale", 125 + 55);
                                }}
                            />
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE Acroni TOTAL power"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.acroniTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="acroniTotalPowerRef"
                                scaleDecimals="0"
                                color={getBrandedColor("green")}
                                ref={(thisRef) => {
                                    this.acroniTotalPowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.acroniTotalPowerRef.setValue("maxPowerOnlyScale", 125);
                                }}
                            />
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE Ravne TOTAL power"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.ravneTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="ravneTotalPowerRef"
                                scaleDecimals="0"
                                color={getBrandedColor("green")}
                                ref={(thisRef) => {
                                    this.ravneTotalPowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.ravneTotalPowerRef.setValue("maxPowerOnlyScale", 55);
                                }}
                            />
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE Acroni Trafo 1 40MVA"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.acroniTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="trafo1PowerRef"
                                scaleDecimals="0"
                                ref={(thisRef) => {
                                    this.trafo1PowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.trafo1PowerRef.setValue("maxPowerOnlyScale", 40);
                                }}
                            />
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE Acroni Trafo 2 75MVA"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.acroniTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="trafo2PowerRef"
                                scaleDecimals="0"
                                ref={(thisRef) => {
                                    this.trafo2PowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.trafo2PowerRef.setValue("maxPowerOnlyScale", 75);
                                }}
                            />
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl4">
                        <NContainer
                            style={{ height: "400px" }}
                            title="LIVE Acroni Trafo 3 100MVA"
                            titleIsBig={true}
                            isLoading={this.state.isLoadingMeters}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "dataAge",
                                    text: (
                                        <TooltipHost content="Measurement time">
                                            <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.8em", cursor: "default" }}>
                                                <FontIcon iconName="History" /> {this.calculateAge(this.state.currentTs, this.state.acroniTimestamp)}
                                            </span>
                                        </TooltipHost>
                                    ),
                                },
                            ]}
                        >
                            <PositiveMaxPowermeter
                                id="trafo3PowerRef"
                                scaleDecimals="0"
                                ref={(thisRef) => {
                                    this.trafo3PowerRef = thisRef;
                                }}
                                onInit={() => {
                                    this.trafo3PowerRef.setValue("maxPowerOnlyScale", 100);
                                }}
                            />
                        </NContainer>
                    </div>
                </div>

                <br />

                <Separator
                    styles={{
                        content: { fontSize: "1.5em" },
                        root: { selectors: { "::before": { background: "rgba(255, 255, 255, 0.3)" } } },
                    }}
                >
                    Delivered (+) energy today
                </Separator>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="SIJ GROUP TOTAL (+) used energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("green") }}>
                                {this.state.totalPosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="ACRONI (+) used energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("green") }}>
                                {this.state.AcroniTotalPosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="RAVNE (+) used energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("green") }}>
                                {this.state.RavneTotalPosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="ACRONI TRAFO1 (+) energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "errorCheck",
                                    text: <>{this.state.posEnergyTrafo1Error ? <TrafoStatus deviation={this.state.posEnergyTrafo1Error} /> : null}</>,
                                },
                            ]}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("blue") }}>
                                {this.state.totalTrafo1PosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="ACRONI TRAFO2 (+) energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "errorCheck",
                                    text: <>{this.state.posEnergyTrafo2Error ? <TrafoStatus deviation={this.state.posEnergyTrafo2Error} /> : null}</>,
                                },
                            ]}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("blue") }}>
                                {this.state.totalTrafo2PosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-xl6  ms-xxxl4">
                        <NContainer
                            isLoading={this.state.isLoadingEnergyToday}
                            title="ACRONI TRAFO3 (+) energy today"
                            loadingSpinnerPosition="left"
                            titleIsBig={true}
                            rightItems={[
                                {
                                    type: "customText",
                                    key: "errorCheck",
                                    text: <>{this.state.posEnergyTrafo3Error ? <TrafoStatus deviation={this.state.posEnergyTrafo3Error} /> : null}</>,
                                },
                            ]}
                        >
                            <div className="number" style={{ backgroundColor: getBrandedColor("blue") }}>
                                {this.state.totalTrafo3PosEnergyToday}
                                <span>MWh</span>
                            </div>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
