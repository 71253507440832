import { Depths } from "@uifabric/fluent-theme";
import {
    Dialog,
    DialogType,
    DialogFooter,
    ActionButton,
    TextField,
    MessageBar,
    MessageBarType,
    Spinner,
    ProgressIndicator,
    SpinnerSize,
    AnimationStyles,
} from "office-ui-fabric-react";
import React from "react";

import { restAPIpostAsJson, restAPIget, getSubdomain, getCdnImage } from "../../shared/utils";

import loginBackgroundImage from "../../assets/images/electricity_lite.jpg";

export class Login extends React.Component {
    constructor(props) {
        super(props);

        let tempLogoSrc = null;

        try {
            tempLogoSrc = getCdnImage(`./partnerLogo/${getSubdomain()}.png`);
        } catch (error) {
            tempLogoSrc = getCdnImage("./partnerLogo/ngen.png");
        }

        this.state = {
            // username: 'info@acroni.si',
            // password: 'secret',
            autoLogining: false,
            logoSrc: tempLogoSrc,
        };
    }

    //FUTURE REQUESTS NEED TO HAVE:  X-CSRF-TOKEN za header name WITH THE TOKEN FROM LOGIN

    manual_tryToLogMeIn() {
        if (!this.state.username) {
            this.setState({ error: "Undefined username!" });
        } else if (!this.state.password) {
            this.setState({ error: "Undefined password!" });
        } else {
            restAPIpostAsJson("/login", { email: this.state.username, password: this.state.password }, { route: "" }).then((result) => {
                if (result.isOk) {
                    if (result.data.status === "success") {
                        this.props.onContinue(result.data);
                    } else {
                        this.setState({ error: "Wrong username and/or password!" });
                    }
                } else {
                    this.setState({ error: result.error });
                }
            });
        }
    }

    auto_tryToLogMeIn() {
        restAPIget("/whoami", undefined, { route: "" }).then((result) => {
            if (result.isOk && result.data.status === "success") {
                this.props.onContinue(result.data);
            } else {
                this.setState({ autoLogining: false });
            }
        });
    }

    componentDidMount() {
        if (this.props.autoLogin) {
            this.setState({ autoLogining: true }, () => {
                this.auto_tryToLogMeIn();
            });
        }
    }

    render() {
        let Logo = (
            <div className="ms-Grid-row">
                <div
                    className="ms-Grid-col ms-sm12"
                    style={{
                        textAlign: "center",
                        height: "120px",
                        lineHeight: "100px",
                        background: "radial-gradient(at center top, rgba(255,255,255,0.12), transparent)",
                    }}
                >
                    <img
                        alt="NGEN logo"
                        src={this.state.logoSrc}
                        style={{
                            maxWidth: "calc(100% - 12px)",
                            margin: "6px",
                            maxHeight: "100%",
                            verticalAlign: "middle",
                        }}
                    ></img>
                </div>
            </div>
        );

        return (
            <>
                <div
                    style={{
                        /* The image used */
                        /* https://www.freeimages.com/photo/windmills-1576113 */
                        background: `url(${loginBackgroundImage})`,
                        filter: "blur(20px)",
                        height: "100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        minWidth: "100vw",
                        minHeight: "100vh",
                        position: "absolute",
                    }}
                />

                <Dialog
                    hidden={false}
                    // onDismiss={this.closeDialog.bind(this, false)}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        // title: "NCC Login",
                        showCloseButton: false,
                        titleProps: {
                            // hidden: true,
                            style: {
                                padding: 0,
                            },
                        },
                    }}
                    minWidth="350px"
                    modalProps={{
                        isBlocking: true,
                        dragOptions: false,
                        isDarkOverlay: true,
                        overlay: true,
                    }}
                    styles={{
                        main: {
                            boxShadow: Depths.depth64,
                        },
                    }}
                >
                    {!this.state.autoLogining && (
                        <>
                            <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0, ...AnimationStyles.fadeIn500 }}>
                                {Logo}

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        <TextField
                                            iconProps={{ iconName: "Contact", styles: { root: { color: "white" } } }}
                                            label="User name"
                                            type="text"
                                            required
                                            value={this.state.username}
                                            onChange={(evt) => {
                                                this.setState({
                                                    username: evt.target.value,
                                                });
                                            }}
                                            onKeyDown={(evt) => {
                                                if (evt.key === "Enter") {
                                                    this.manual_tryToLogMeIn();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        <TextField
                                            iconProps={{ iconName: "Lock", styles: { root: { color: "white" } } }}
                                            label="Password"
                                            type="password"
                                            required
                                            value={this.state.password}
                                            onChange={(evt) => {
                                                this.setState({
                                                    password: evt.target.value,
                                                });
                                            }}
                                            onKeyDown={(evt) => {
                                                if (evt.key === "Enter") {
                                                    this.manual_tryToLogMeIn();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        {this.state.error && (
                                            <MessageBar
                                                messageBarType={MessageBarType.error}
                                                // styles={
                                                //     {
                                                //         iconContainer: { marginLeft: 0 },
                                                //         innerText: { fontSize: '12px', whiteSpace: 'normal' },
                                                //         icon: { color: 'white', paddingLeft: '5px' }
                                                //     }
                                                // }
                                            >
                                                {this.state.error}
                                            </MessageBar>
                                        )}
                                        {!this.state.error && (
                                            <MessageBar
                                                messageBarType={MessageBarType.info}
                                                styles={{
                                                    // iconContainer: { marginLeft: 0 },
                                                    // innerText: { fontSize: '12px', whiteSpace: 'normal' },
                                                    // icon: { color: 'white', paddingLeft: '5px' }
                                                    root: {
                                                        background: "#80808012",
                                                    },
                                                }}
                                            >
                                                Enter username and password to login.
                                            </MessageBar>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <DialogFooter styles={{}}>
                                <ActionButton text="Login" iconProps={{ iconName: "SignIn" }} onClick={this.manual_tryToLogMeIn.bind(this)} />
                            </DialogFooter>

                            <div
                                style={{
                                    color: "gray",
                                    textAlign: "center",
                                    fontStyle: "oblique",
                                    // padding: '0px 10px 10px 10px',
                                    fontSize: "0.9em",
                                    marginBottom: "-17px",
                                }}
                            >
                                Powered by <span style={{ color: "lightgray" }}>N</span>
                                <span style={{ color: "#5fba46" }}>GEN</span>
                            </div>
                        </>
                    )}

                    {this.state.autoLogining && (
                        <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0, ...AnimationStyles.fadeIn500 }}>
                            {Logo}

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <br />
                                    <Spinner label="Auto logining..., please wait." ariaLive="assertive" size={SpinnerSize.large} labelPosition="bottom" />
                                    <br />
                                    <ProgressIndicator label="Progress" description="Magic is happening ..." />
                                </div>
                            </div>
                        </div>
                    )}
                </Dialog>
            </>
        );
    }
}
