import { Text, FontIcon, Link } from "office-ui-fabric-react";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import React from "react";

import { getBrandedColor, getCdnImage } from "../../shared/utils";
import { HtmlField } from "../../components/htmlField/htmlField";
import { NContainer } from "../../components/nContainer/nContainer";
import SharedData from "../../shared/sharedData";

import changelog from "./changelog.md";
import sbsChangelog from "./sbsChangelog.md";

const versions = {
    sbs: {
        title: "NCC SBS",
        version: "1.0.3.4",
        description: ["NCC - NGEN Control Center", "SBS - Schedule Balancing System"],
        changelog: sbsChangelog,
    },
    ext: {
        title: "NCC",
        version: "1.0.0.2",
        description: ["NCC - NGEN Control Center"],
        changelog: changelog,
    },
};

export class About extends React.Component {
    constructor(props) {
        super(props);

        this.abortController = new AbortController();

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "About", key: "about", isCurrentItem: true },
        ]);

        this.currentYear = moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").format("YYYY");
        this.logoSrc = getCdnImage("./partnerLogo/ngen.png");
        this.currentVersion = ["acroni"].includes(this.props.workspace) ? versions.sbs : versions.ext;

        this.state = {
            isLoading: true,
            changelogText: "",
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getChangelog();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();
    }

    getChangelog() {
        fetch(this.currentVersion.changelog, { signal: this.abortController.signal })
            .then((response) => response.text())
            .then((data) => {
                if (this._isMounted && data) {
                    this.setState({
                        changelogText: data,
                        isLoading: false,
                    });
                }
            });
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            // style={{ height: '150px' }}
                            title="About"
                            style={{ color: "white" }}
                        >
                            <br />
                            <div style={{ textAlign: "center", height: "100%" }}>
                                <img alt="NGEN logo" src={this.logoSrc}></img>
                            </div>
                            <br />

                            <Text variant="xLarge" style={{ textAlign: "center" }} block>
                                {this.currentVersion.title} <span style={{ fontSize: "0.9em", color: "darkgray" }}> version {this.currentVersion.version}</span>
                            </Text>

                            {this.currentVersion.description.map((d, i) => (
                                <Text key={i} variant="smallPlus" style={{ textAlign: "center" }} block>
                                    {d}
                                </Text>
                            ))}

                            <br />
                            <Text variant="mediumPlus" style={{ textAlign: "center", color: "darkgray" }} block>
                                © {this.currentYear} NGEN
                            </Text>
                            <br />

                            <Text variant="mediumPlus" style={{ textAlign: "center" }} block>
                                <FontIcon iconName="CheckMark" style={{ color: getBrandedColor("green"), fontSize: "20px", verticalAlign: "bottom" }} />
                                <span>&nbsp; You're on the latest version of {this.currentVersion.title}.</span>
                            </Text>
                            <br />

                            <Text variant="mediumPlus" style={{ textAlign: "center", color: "darkgray", fontStyle: "oblique" }} block>
                                Contact us: <Link href="mailto: support@ngen.si">support@ngen.si</Link>
                            </Text>
                            <br />
                        </NContainer>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer style={{ maxHeight: "unset" }} isLoading={this.state.isLoading} title="Changelog">
                            <HtmlField
                                disabled
                                label="List of changes"
                                style={{
                                    lineHeight: "20px",
                                    // textAlign: "center"
                                    // listStylePosition: "inside",
                                }}
                            >
                                <Markdown
                                    // style={{ maxWidth: "400px", margin: "auto auto" }}
                                    options={{
                                        forceBlock: true,
                                        overrides: {
                                            hr: { props: { className: "leftAlignedHr" } },
                                            h2: {
                                                component: Text,
                                                props: { variant: "mediumPlus", block: true, style: { lineHeight: "initial", padding: "0.5em 0 0.5em 0" } },
                                            },
                                            h3: { component: Text, props: { variant: "medium", block: true, style: { lineHeight: "initial" } } },
                                            // li: { component: Text, props: { variant: "smallPlus", block: true } },
                                        },
                                    }}
                                >
                                    {this.state.changelogText}
                                </Markdown>
                            </HtmlField>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
