import * as moment from "moment";
import Boost from "highcharts/modules/boost";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";
import { restAPIget, getDefaultChartOptions, setInitialDataToGraphSeries, getBrandedColor } from "../../shared/utils";
import SharedData from "../../shared/sharedData";

Boost(Highcharts);

export class AssetHistory extends React.Component {
    getAssetDependentPowerChartOptions(asset) {
        let result = null;
        switch (asset.id) {
            case 1:
            case 5:
                result = getDefaultChartOptions({
                    chart: {
                        height: "600px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `Power history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "power",
                            name: "Power (MW)",
                        },
                        {
                            id: "temperatureAxe",
                            name: "Temperature (°C)",
                            gridLineColor: "transparent",
                            opposite: true,
                        },
                    ],
                    series: [
                        {
                            id: "p",
                            name: "Power",
                            color: "#5dbb46",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "MW",
                            zIndex: 4,
                        },
                        {
                            id: "tp",
                            name: "Targeted power",
                            color: "turquoise",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "MW",
                            zIndex: 4,
                        },
                        {
                            id: "s",
                            name: "Setpoint",
                            color: "red",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "MW",
                            zIndex: 3,
                        },
                        {
                            id: "b",
                            name: "Base point",
                            // color: "rgb(124,181,236)",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "MW",
                            zIndex: 3,
                        },
                        {
                            id: "ap",
                            name: "Max discharge power",
                            color: "#3477aa",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "MW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(0, 89, 255, 0.2)"],
                                    [1, "rgba(0, 89, 255, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "an",
                            name: "Max charge power",
                            color: "#cc3e44",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "MW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0.95,
                                    x2: 0,
                                    y2: 0,
                                },
                                stops: [
                                    [0, "rgba(255, 0, 0, 0.2)"],
                                    [1, "rgba(255, 0, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "temp",
                            name: "Temperature",
                            color: "yellow",
                            type: "line",
                            lineWidth: 0.7,
                            valueSuffix: "°C",
                            yAxis: "temperatureAxe",
                            visible: false,
                        },
                        {
                            id: "safrr",
                            name: "SAFRR",
                            color: "orange",
                            type: "line",
                            valueSuffix: "MW",
                        },
                        {
                            id: "scorrection",
                            name: "SCORR",
                            color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "bafrr",
                            name: "BAFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "b5afrr",
                            name: "B5AFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "bmfrr",
                            name: "BMFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "smfrr",
                            name: "SMFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "smarket",
                            name: "BSP Market",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "sngen",
                            name: "NGEN Market",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "sngendyn",
                            name: "NGEN Market (dynamic)",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "apafrr",
                            name: "APAFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "anafrr",
                            name: "ANAFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                    ],
                });
                break;

            case 2:
            case 4:
                result = getDefaultChartOptions({
                    chart: {
                        height: "600px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `Power history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "power",
                            name: "Power (MW)",
                        },
                    ],
                    series: [
                        {
                            id: "p",
                            name: "Current power",
                            color: "#7cb5ec",
                            type: "line",
                            valueSuffix: "MW",
                            zIndex: 4,
                            visible: false,
                        },
                        {
                            id: "pafrr",
                            name: "Actual AFRR power",
                            color: "#5dbb46",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "MW",
                            zIndex: 4,
                        },
                        {
                            id: "safrr",
                            name: "AFRR setpoint NGEN",
                            color: "#bb7e0e",
                            type: "line",
                            valueSuffix: "MW",
                            zIndex: 4,
                        },
                        {
                            id: "s",
                            name: "Setpoint",
                            color: "green",
                            type: "line",
                            valueSuffix: "MW",
                            zIndex: 3,
                        },
                        {
                            id: "sngen",
                            name: "SNGEN",
                            type: "line",
                            color: "deepskyblue",
                            lineWidth: 1,
                            tooltip: {
                                valueSuffix: "MW",
                            },
                        },
                        {
                            id: "sngendyn",
                            name: "SNGEN DYN",
                            type: "line",
                            color: "yellow",
                            lineWidth: 1,
                            tooltip: {
                                valueSuffix: "MW",
                            },
                        },
                        {
                            id: "b",
                            name: "Base line",
                            color: "orange",
                            type: "line",
                            valueSuffix: "MW",
                            zIndex: 3,
                            visible: false,
                        },
                        {
                            id: "b5",
                            name: "Base line 5",
                            color: "orangered",
                            type: "line",
                            valueSuffix: "MW",
                            zIndex: 3,
                            visible: false,
                        },
                        {
                            id: "ap",
                            name: "Max positive AFRR",
                            color: "#3477aa",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(0, 89, 255, 0.2)"],
                                    [1, "rgba(0, 89, 255, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "an",
                            name: "Max negative AFRR",
                            color: "#cc3e44",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0.95,
                                    x2: 0,
                                    y2: 0,
                                },
                                stops: [
                                    [0, "rgba(255, 0, 0, 0.2)"],
                                    [1, "rgba(255, 0, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "apafrr",
                            name: "APAFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                        {
                            id: "anafrr",
                            name: "ANAFRR",
                            // color: "white",
                            type: "line",
                            valueSuffix: "MW",
                            visible: false,
                        },
                    ],
                });
                break;

            case 6:
                result = getDefaultChartOptions({
                    chart: {
                        height: "600px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `Power history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "power",
                            name: "Power (KW)",
                        },
                    ],
                    series: [
                        {
                            id: "p",
                            name: "Power",
                            color: "#5dbb46",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "tp",
                            name: "Targeted power",
                            color: "turquoise",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "s",
                            name: "Setpoint",
                            color: "red",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "KW",
                            zIndex: 3,
                        },
                        {
                            id: "b",
                            name: "Baseline",
                            color: "rgb(124,181,236)",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "KW",
                            zIndex: 2,
                        },
                        {
                            id: "psite",
                            name: "Power site",
                            color: "orange",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "pgrid",
                            name: "Power site",
                            color: getBrandedColor("washedViolet"),
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "ap",
                            name: "Max discharge power",
                            color: "#3477aa",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(0, 89, 255, 0.2)"],
                                    [1, "rgba(0, 89, 255, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "an",
                            name: "Max charge power",
                            color: "#cc3e44",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0.95,
                                    x2: 0,
                                    y2: 0,
                                },
                                stops: [
                                    [0, "rgba(255, 0, 0, 0.2)"],
                                    [1, "rgba(255, 0, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                    ],
                });
                break;

            case 3:
                result = getDefaultChartOptions({
                    chart: {
                        height: "600px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `Power history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "power",
                            name: "Power (KW)",
                        },
                    ],
                    series: [
                        {
                            id: "p",
                            name: "Power",
                            color: "#5dbb46",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "s",
                            name: "Setpoint",
                            color: "red",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "KW",
                            zIndex: 3,
                        },
                        {
                            id: "b",
                            name: "Baseline",
                            color: "rgb(124,181,236)",
                            type: "line",
                            lineWidth: 1,
                            valueSuffix: "KW",
                            zIndex: 2,
                        },
                        {
                            id: "pgrid",
                            name: "Grid power",
                            color: "orange",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 4,
                        },
                        {
                            id: "ap",
                            name: "Max discharge power",
                            color: "#3477aa",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(0, 89, 255, 0.2)"],
                                    [1, "rgba(0, 89, 255, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "an",
                            name: "Max charge power",
                            color: "#cc3e44",
                            type: "area",
                            lineWidth: 2,
                            valueSuffix: "KW",
                            zIndex: 3,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0.95,
                                    x2: 0,
                                    y2: 0,
                                },
                                stops: [
                                    [0, "rgba(255, 0, 0, 0.2)"],
                                    [1, "rgba(255, 0, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                    ],
                });
                break;

            default:
                break;
        }
        return result;
    }

    getAssetDependentSocChartOptions(asset) {
        let result = null;
        switch (asset.id) {
            case 1:
            case 5:
                result = getDefaultChartOptions({
                    chart: {
                        height: "355px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `SOC history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "socAxe",
                            name: "SoC (%)",
                            softMin: 0,
                            softMax: 100,
                            alignTicks: true,
                        },
                        {
                            id: "energyAxe",
                            name: "Energy (KWh)",
                            opposite: true,
                            min: 0,
                            softMax: 24,
                            alignTicks: true,
                            endOnTick: true,
                            gridLineColor: "transparent",
                        },
                    ],
                    series: [
                        {
                            id: "soc",
                            name: "SoC",
                            color: "#5fba46",
                            type: "area",
                            lineWidth: 1,
                            valueSuffix: "%",
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(102, 255, 0, 0.2)"],
                                    [1, "rgba(102, 255, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "ae",
                            name: "To discharge energy",
                            color: "dodgerblue",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KWh",
                            yAxis: "energyAxe",
                        },
                        {
                            id: "ce",
                            name: "To charge energy",
                            color: "red",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KWh",
                            yAxis: "energyAxe",
                        },
                    ],
                });
                break;
            case 3:
            case 6:
                result = getDefaultChartOptions({
                    chart: {
                        height: "355px",
                        tooltipShared: true,
                    },
                    export: {
                        filename: `SOC history ${asset.name}`,
                        csv: {
                            itemDelimiter: ';',
                            decimalPoint: ','
                        }
                    },
                    yAxis: [
                        {
                            id: "socAxe",
                            name: "SoC (%)",
                            softMin: 0,
                            softMax: 100,
                            alignTicks: true,
                        },
                        {
                            id: "energyAxe",
                            name: "Energy (KWh)",
                            opposite: true,
                            min: 0,
                            softMax: 20,
                            alignTicks: true,
                            endOnTick: true,
                            gridLineColor: "transparent",
                        },
                    ],
                    series: [
                        {
                            id: "soc",
                            name: "SoC",
                            color: "#5fba46",
                            type: "area",
                            lineWidth: 1,
                            valueSuffix: "%",
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0.95,
                                },
                                stops: [
                                    [0, "rgba(102, 255, 0, 0.2)"],
                                    [1, "rgba(102, 255, 0, 0.05)"],
                                ],
                            },
                            fillOpacity: 0.95,
                        },
                        {
                            id: "ae",
                            name: "To discharge energy",
                            color: "dodgerblue",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KWh",
                            yAxis: "energyAxe",
                        },
                        {
                            id: "ce",
                            name: "To charge energy",
                            color: "red",
                            type: "line",
                            lineWidth: 2,
                            valueSuffix: "KWh",
                            yAxis: "energyAxe",
                        },
                    ],
                });
                break;

            default:
                break;
        }
        return result;
    }

    constructor(props) {
        super(props);

        this.assetId = props.match.params.id + "";
        this.asset = SharedData.deepGet("assets", this.assetId);
        this.asset.name = this.asset.name || "Asset #" + this.assetId;

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            {
                text: "History",
                key: "history",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            {
                text: this.asset.name,
                key: "assetHistory" + this.assetId,
                isCurrentItem: true,
            },
        ]);

        this.powerChartRef = React.createRef();
        this.powerChartOptions = this.getAssetDependentPowerChartOptions(this.asset);

        this.socChartRef = React.createRef();
        this.socChartOptions = this.getAssetDependentSocChartOptions(this.asset);

        this.abortController = new AbortController();

        this.state = {
            isLoading: false,
            isBattery: this.asset.isBattery,

            selectedDate: new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData(this.state.selectedDate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();
    }

    loadData(date) {
        this.abortController.abort();
        this.abortController = new AbortController();

        if (!date) {
            return;
        }

        this.setState(
            {
                isLoading: true,
            },
            () => {
                let startOfDayTs = moment
                    .tz({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }, "Europe/Ljubljana")
                    .startOf("day")
                    .unix();

                restAPIget(`/measurements/raw/${this.assetId}/24h/${startOfDayTs}`, this.abortController.signal).then((result) => {
                    if (result.isOk && this._isMounted) {
                        let reversedData = result.data.reverse();
                        switch (this.assetId) {
                            case "1":
                            case "5":
                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        p: Number(d.p) / 1000,
                                        tp: Number(d.tp) / 1000,
                                        s: Number(d.s) / 1000,
                                        b: Number(d.b) / 1000,
                                        an: Number(d.an) / 1000,
                                        ap: Number(d.ap) / 1000,
                                        temp: Number(d.temp),
                                        safrr: Number(d.safrr) / 1000,
                                        scorrection: Number(d.scorrection),
                                        bafrr: Number(d.bafrr) / 1000,
                                        b5afrr: Number(d.b5afrr) / 1000,
                                        bmfrr: Number(d.bmfrr) / 1000,
                                        smfrr: Number(d.smfrr) / 1000,
                                        smarket: Number(d.smarket) / 1000,
                                        sngen: Number(d.sngen),
                                        sngendyn: Number(d.sngendyn),
                                        apafrr: Number(d.apafrr) / 1000,
                                        anafrr: Number(d.anafrr / 1000),
                                    })),
                                    [
                                        "p",
                                        "tp",
                                        "s",
                                        "b",
                                        "an",
                                        "ap",
                                        "temp",
                                        "safrr",
                                        "scorrection",
                                        "bafrr",
                                        "b5afrr",
                                        "bmfrr",
                                        "smfrr",
                                        "smarket",
                                        "sngen",
                                        "sngendyn",
                                        "apafrr",
                                        "anafrr",
                                    ],
                                    this.powerChartRef.current.chart
                                );

                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        soc: Number(d.soc),
                                        ae: Number(d.ae) / 1000,
                                        ce: (Number(d.te) - Number(d.ae)) / 1000,
                                    })),
                                    ["soc", "ae", "ce"],
                                    this.socChartRef.current.chart
                                );
                                break;

                            case "2":
                            case "4":
                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        p: Number(d.p) / 1000,
                                        pafrr: Number(d.pafrr) / 1000,
                                        safrr: Number(d.safrr) / 1000,
                                        ap: Number(d.ap) / 1000,
                                        an: Number(d.an) / 1000,
                                        s: Number(d.s) / 1000,
                                        sngen: Number(d.sngen),
                                        sngendyn: Number(d.sngendyn),
                                        b: Number(d.b) / 1000,
                                        b5: Number(d.b5) / 1000,
                                        apafrr: Number(d.apafrr) / 1000,
                                        anafrr: Number(d.anafrr) / 1000,
                                    })),
                                    ["p", "pafrr", "safrr", "s", "sngen", "sngendyn", "b", "b5", "apafrr", "anafrr", "an", "ap"],
                                    this.powerChartRef.current.chart
                                );
                                break;

                            case "3":
                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        p: Number(d.p),
                                        pgrid: Number(d.pgrid),
                                        s: Number(d.s),
                                        b: Number(d.b),
                                        an: Number(d.an),
                                        ap: Number(d.ap),
                                    })),
                                    ["p", "pgrid", "s", "b", "an", "ap"],
                                    this.powerChartRef.current.chart
                                );

                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        soc: Number(d.soc),
                                        ae: Number(d.ae),
                                        ce: Number(d.te) - Number(d.ae),
                                    })),
                                    ["soc", "ae", "ce"],
                                    this.socChartRef.current.chart
                                );
                                break;

                            case "6":
                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        p: Number(d.p),
                                        tp: Number(d.tp),
                                        psite: Number(d.psite),
                                        pgrid: Number(d.pgrid),
                                        s: Number(d.s),
                                        b: Number(d.b),
                                        an: Number(d.an),
                                        ap: Number(d.ap),
                                    })),
                                    ["p", "tp", "psite", "pgrid", "s", "b", "an", "ap"],
                                    this.powerChartRef.current.chart
                                );

                                setInitialDataToGraphSeries(
                                    reversedData.map((d) => ({
                                        timestamp: Number(d.timestamp),
                                        soc: Number(d.soc),
                                        ae: Number(d.ae),
                                        ce: Number(d.te) - Number(d.ae),
                                    })),
                                    ["soc", "ae", "ce"],
                                    this.socChartRef.current.chart
                                );
                                break;

                            default:
                                break;
                        }

                        this.setState({ isLoading: false });
                    }
                });
            }
        );
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            isLoading={this.state.isLoading}
                            style={{ maxHeight: "655px" }}
                            title="Power history"
                            rightItems={[
                                {
                                    type: "actionButton",
                                    icon: "DownloadDocument",
                                    key: "downloadCsv",
                                    text: "CSV",
                                    onClick: function () {
                                        this.powerChartRef.current.chart.downloadCSV();
                                    }.bind(this),
                                },
                                {
                                    type: "emptySpace",
                                },
                                {
                                    type: "datePicker",
                                    key: "selectedDate",
                                    value: this.state.selectedDate,
                                    onClick: function (newValue) {
                                        this.setState(
                                            {
                                                selectedDate: newValue,
                                            },
                                            () => {
                                                this.loadData(newValue);
                                            }
                                        );
                                    }.bind(this),
                                },
                            ]}
                        >
                            <div id="chart">
                                <HighchartsReact ref={this.powerChartRef} highcharts={Highcharts} options={this.powerChartOptions} />
                            </div>
                        </NContainer>
                    </div>
                </div>

                {this.state.isBattery && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer isLoading={this.state.isLoading} style={{ maxHeight: "400px" }} title="SoC history">
                                <div id="chart2">
                                    <HighchartsReact ref={this.socChartRef} highcharts={Highcharts} options={this.socChartOptions} />
                                </div>
                            </NContainer>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
