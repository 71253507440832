import React from 'react';
import ReactSVG from 'react-svg';

import svgSrc from './positiveMaxPowermeter.svg';

export class PositiveMaxPowermeter extends React.Component {
    wholeElement = null;
    scaleqMin4Element = null;
    scaleqMin3Element = null;
    scaleqMin2Element = null;
    scaleqMin1Element = null;
    scaleqMax4Element = null;
    scaleqMax3Element = null;
    scaleqMax2Element = null;
    scaleqMax1Element = null;
    scaleq0Element = null;
    relPowerElement = null;
    absPowerElement = null;
    fullPathElement = null;

    initElements() {
        this.wholeElement = document.getElementById(this.props.id);
        this.scaleqMin4Element = this.wholeElement.getElementsByClassName('scaleq-4')[0];
        this.scaleqMin3Element = this.wholeElement.getElementsByClassName('scaleq-3')[0];
        this.scaleqMin2Element = this.wholeElement.getElementsByClassName('scaleq-2')[0];
        this.scaleqMin1Element = this.wholeElement.getElementsByClassName('scaleq-1')[0];
        this.scaleqMax4Element = this.wholeElement.getElementsByClassName('scaleq4')[0];
        this.scaleqMax3Element = this.wholeElement.getElementsByClassName('scaleq3')[0];
        this.scaleqMax2Element = this.wholeElement.getElementsByClassName('scaleq2')[0];
        this.scaleqMax1Element = this.wholeElement.getElementsByClassName('scaleq1')[0];
        this.scaleq0Element = this.wholeElement.getElementsByClassName('scaleq0')[0];
        this.relPowerElement = this.wholeElement.getElementsByClassName('relPower')[0];
        this.absPowerElement = this.wholeElement.getElementsByClassName('absPower')[0];
        this.fullPathElement = this.wholeElement.getElementsByClassName('fullPath')[0].getElementsByTagName('path')[0];

        if (this.props.color) {
            this.fullPathElement.style.stroke = this.props.color;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    setValue(type, value) {
        if (!this.wholeElement) {
            // console.warn("Element not yet loaded");
            return;
        }

        switch (type) {
            case 'relPower':
                let percent = value;
                if (percent > 100) {
                    percent = 100;
                }
                if (percent < -100) {
                    percent = -100;
                }

                this.relPowerElement.textContent = Math.abs(percent).toFixed(1) + '%';
                this.fullPathElement.style.strokeDashoffset = 180 - percent * 1.8 + 'px';
                break;

            case 'absPower':
                this.absPowerElement.textContent = value.toFixed(2) + 'MW';
                break;


            case 'maxPowerOnlyScale':
                this.scaleqMin4Element.textContent = 0;
                this.scaleqMin3Element.textContent = (value * 0.125).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMin2Element.textContent = (value * 0.25).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMin1Element.textContent = (value * 0.375).toFixed(this.props.scaleDecimals || 1);
                this.scaleq0Element.textContent = (value * 0.5).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMax1Element.textContent = (value * 0.625).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMax2Element.textContent = (value * 0.75).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMax3Element.textContent = (value * 0.875).toFixed(this.props.scaleDecimals || 1);
                this.scaleqMax4Element.textContent = value.toFixed(this.props.scaleDecimals || 1);
                break;

            default:
                console.error("Invalid type!");
                break;
        }
    }

    render() {
        return (
            <div id={this.props.id}>
                <ReactSVG style={{ textAlign: 'center' }}
                    beforeInjection={svg => {
                        svg.setAttribute('style', 'display: block;position: absolute;height: 70%;width: 84%;top: 100px; left: 8%;')
                    }}
                    src={svgSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }} />
            </div>
        );
    }
}