import { Text, ProgressIndicator, AnimationStyles } from 'office-ui-fabric-react';
import React from 'react';

import { NContainer } from '../../components/nContainer/nContainer';


export class Reload extends React.Component {
    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked")
                }
            },
            { text: 'Reloading...', key: 'reloading', isCurrentItem: true }
        ]);
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ ...AnimationStyles.fadeIn500 }}>
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            // style={{ height: '150px' }}
                            title="Reloading..."
                            style={{ color: 'white' }}
                        >

                            <Text variant="xLarge" style={{ textAlign: "center" }} block>Reloading previous page ...</Text>
                            <ProgressIndicator
                            // label="Progress"
                            // description="Magic is happening ..."
                            />
                            <Text variant="smallPlus" style={{ textAlign: "center" }} block>If nobody comes back from the future to stop you, then how bad of a decision can it really be ?</Text>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }

}