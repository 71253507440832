import { DatePicker, DayOfWeek } from "office-ui-fabric-react";
import { DefaultButton, IconButton } from "office-ui-fabric-react/lib/Button";
import { Depths } from "@uifabric/fluent-theme";
import React from "react";

import { getDayPickerSettings, _datepicker_formatDate, _datepicker_parseDateFromString, _datepicker_formatMonth } from "../../shared/utils";
import SharedData from "../../shared/sharedData";

import "./cardHeader.css";

export class CardHeader extends React.Component {
    render() {
        return (
            <div
                className="cardHeaderContainer"
                style={{
                    color: "white",
                    boxShadow: Depths.depth4,
                    margin: "-10px",
                    padding: "10px",
                    lineHeight: "40px",
                    fontWeight: "bold",
                    // display: 'inline',
                    backgroundColor: "#1e273b",
                    ...this.props.headerStyle,
                }}
            >
                <div className="cardHeaderLeft noSelect">
                    <span
                        style={{
                            cursor: this.props.onCollapseToggle ? "pointer" : "unset",
                            fontSize: this.props.textIsBig ? "1.2em" : "unset",
                            lineHeight: "1em",
                        }}
                        onClick={this.props.onCollapseToggle}
                    >
                        {this.props.text}
                    </span>
                </div>
                <div className="cardHeaderRight">
                    {this.props.rightItems &&
                        this.props.rightItems.map((item, id, arr) => {
                            if (item.hidden) {
                                return null;
                            }

                            switch (item.type) {
                                case "actionButton":
                                    return (
                                        <DefaultButton
                                            key={item.key}
                                            style={{
                                                padding: "4px",
                                                // marginTop: "4px",
                                                minWidth: "unset",
                                            }}
                                            text={item.text}
                                            checked={item.checked}
                                            disabled={item.disabled !== undefined ? item.disabled : false}
                                            iconProps={{ iconName: item.icon }}
                                            onClick={() => {
                                                item.onClick();
                                            }}
                                            title={item.title}
                                        />
                                    );

                                case "customText":
                                    return (
                                        <span
                                            key={item.key}
                                            style={{
                                                ...item.style,
                                            }}
                                        >
                                            {item.text}
                                        </span>
                                    );

                                case "chooseOption":
                                    return (
                                        <DefaultButton
                                            iconProps={{ iconName: item.icon }}
                                            key={item.key}
                                            styles={{
                                                root: {
                                                    // marginTop: "4px",
                                                },
                                            }}
                                            text={item.options.find((i) => i.key === item.selectedOption).text}
                                            menuProps={{
                                                items: item.options,
                                            }}
                                        />
                                    );

                                case "datePicker":
                                    return (
                                        <DatePicker
                                            key={item.key}
                                            style={{
                                                padding: "2px 0",
                                                maxWidth: "200px",
                                                float: "right",
                                                ...item.style,
                                            }}
                                            onSelectDate={(evt) => {
                                                item.onClick(evt);
                                            }}
                                            isRequired={false}
                                            value={item.value}
                                            label={item.label}
                                            showMonthPickerAsOverlay={true}
                                            showGoToToday={false}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            initialPickerDate={null}
                                            today={new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate"))}
                                            strings={getDayPickerSettings()}
                                            placeholder="Select a date..."
                                            ariaLabel="Select a date"
                                            allowTextInput={false}
                                            formatDate={(someValue) => {
                                                return _datepicker_formatDate(someValue);
                                            }}
                                            parseDateFromString={(someValue) => {
                                                return _datepicker_parseDateFromString(item.value, someValue);
                                            }}
                                        />
                                    );

                                case "monthPicker":
                                    return (
                                        <DatePicker
                                            key={item.key}
                                            style={{
                                                padding: "2px 0",
                                                maxWidth: "200px",
                                                float: "right",
                                                ...item.style,
                                            }}
                                            onSelectDate={(evt) => {
                                                item.onClick(evt);
                                            }}
                                            isRequired={false}
                                            value={item.value}
                                            label={item.label}
                                            // showMonthPickerAsOverlay={true}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            strings={getDayPickerSettings()}
                                            today={new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate"))}
                                            placeholder="Select a month..."
                                            ariaLabel="Select a month"
                                            allowTextInput={false}
                                            formatDate={(someValue) => {
                                                return _datepicker_formatMonth(someValue);
                                            }}
                                            parseDateFromString={(someValue) => {
                                                return _datepicker_parseDateFromString(item.value, someValue);
                                            }}
                                            showGoToToday={false}
                                            highlightSelectedMonth={true}
                                            calendarProps={{
                                                showMonthPickerAsOverlay: true,
                                                showGoToToday: false,
                                                isDayPickerVisible: false,
                                                isMonthPickerVisible: true,
                                            }}
                                        />
                                    );

                                case "emptySpace":
                                    return <span key={id}>&nbsp;&nbsp;</span>;

                                default:
                                    return <span key={id}>Oh nooo!, uknown type!</span>;
                            }
                        })}

                    {this.props.onCollapseToggle && (
                        <IconButton
                            style={{
                                padding: "4px",
                                marginTop: "4px",
                                minWidth: "unset",
                            }}
                            iconProps={{
                                iconName: this.props.collapsed ? "ExploreContent" : "CollapseContent",
                            }}
                            onClick={this.props.onCollapseToggle}
                        />
                    )}
                </div>
            </div>
        );
    }
}
