import { Depths } from "@uifabric/fluent-theme";
import { IconButton, Stack, Breadcrumb, FontIcon, TooltipHost, DirectionalHint } from "office-ui-fabric-react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import React from "react";

import { AppContext } from "../../shared/contexts";
import { showMenu, hideMenu } from "../sideMenu/sideMenu";
import EventEmitter from "../../shared/EventEmitter";
import SharedData from "../../shared/sharedData";

export class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breads: [],
            statuses: {},
            noOfPeople: "",
            noOfErrors: 0,
            noOfWarnings: 0,
            timestamp: SharedData.get("timestamp"),
            lastSync: Date.now() - SharedData.get("timesync"),
            // timestamp: moment().tz('Europe/Ljubljana').utc().unix(),
        };
        this.prevWs = null;

        this.isProduction = process.env.REACT_APP_IS_PRODUCTION === "true" ? true : false;
    }

    componentDidMount() {
        this._isMounted = true;
        EventEmitter.dispatch("APPLICATION_TIME", this.state.timestamp);
        this.updateTimeInterval = setInterval(() => this.tick(), 1000);

        EventEmitter.subscribe(
            "APPLICATION_WARNINGS",
            (newObj) => {
                let errorNo = 0;
                let warnNo = 0;

                if (newObj && newObj.data && Object.keys(newObj.data).length > 0) {
                    Object.entries(newObj.data).forEach(([key, value]) => {
                        if (key === "errors") {
                            errorNo += value.length;
                        } else {
                            console.log(key, value);
                            warnNo += 1;
                        }
                    });
                }

                this.setState({
                    noOfErrors: errorNo,
                    noOfWarnings: warnNo,
                });
            },
            "topbar",
            "property"
        );

        EventEmitter.subscribe(
            "SYSTEM/DATETIME",
            (value) => {
                let currentTimeStamp = value.timestamp;
                SharedData.set("syncedTimestamp", currentTimeStamp);

                if (this._isMounted) {
                    if (this.state.timestamp !== currentTimeStamp) {
                        clearInterval(this.updateTimeInterval);
                        this.setState(
                            {
                                timestamp: currentTimeStamp,
                                lastSync: Date.now() - SharedData.get("timesync"),
                            },
                            () => {
                                EventEmitter.dispatch("APPLICATION_TIME", this.state.timestamp);
                                this.updateTimeInterval = setInterval(() => this.tick(), 1000);
                            }
                        );
                    } else {
                        this.setState({
                            lastSync: Date.now() - SharedData.get("timesync"),
                        });
                    }
                }
            },
            "topbar",
            "property"
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.updateTimeInterval);
    }
    tick() {
        this.setState(
            (prevstate) => ({
                timestamp: prevstate.timestamp + 1,
                lastSync: Date.now() - SharedData.get("timesync"),
            }),
            () => {
                SharedData.set("timestamp", this.state.timestamp);
                EventEmitter.dispatch("APPLICATION_TIME", this.state.timestamp);
            }
        );
    }

    setNavigation(newBreads, newStatuses) {
        let newState = {};

        if (newBreads) {
            newState.breads = newBreads;
        }

        if (newStatuses) {
            newState.statuses = newStatuses;
        } else {
            newState.statuses = {};
        }

        if (newState !== {}) {
            this.setState(newState);
        } else {
            console.error("No input paramaters!");
        }
    }

    TheTopBar = withRouter(({ history }) => {
        return (
            <Stack horizontal disableShrink style={{ boxShadow: Depths.depth4, backgroundColor: "#1e273b", zIndex: "100", position: "sticky", top: 0 }}>
                {!this.isProduction && (
                    <Stack.Item
                        align="center"
                        className="opacity_blink"
                        styles={{ root: { color: "orangered", letterSpacing: "2px", padding: "1px 5px", fontWeight: "bold" } }}
                    >
                        DEV. /
                    </Stack.Item>
                )}
                <Stack.Item align="center" styles={{ root: { marginLeft: "10px" } }}>
                    <IconButton
                        id="nav-show"
                        className="nav-show"
                        style={{ height: "30px" }}
                        onClick={showMenu}
                        iconProps={{ iconName: "GlobalNavButton" }}
                        title="Show menu"
                        ariaLabel="Show menu"
                    />
                    <IconButton
                        id="nav-hide"
                        className="nav-hide"
                        style={{ height: "30px" /*borderRadius: '30px'*/ }}
                        onClick={hideMenu}
                        iconProps={{ iconName: "GlobalNavButton" }}
                        checked={true}
                        title="Hide menu"
                        ariaLabel="Hide menu"
                    />
                </Stack.Item>
                <Stack.Item grow align="center">
                    <Breadcrumb
                        items={this.state.breads}
                        style={{ margin: 0 }}
                        onRenderItem={(theProps, defaultRenderer) => {
                            if (!theProps.isCurrentItem || (theProps.isCurrentItem && this.state.statuses.length === 0)) {
                                return defaultRenderer(theProps);
                            } else {
                                return (
                                    <>
                                        {defaultRenderer(theProps)}
                                        <TooltipHost
                                            tooltipProps={{
                                                onRenderContent: () => {
                                                    return (
                                                        <>
                                                            {/* <div style={{ color: 'white', fontSize: '1.3em' }}>Asset statuses:</div> */}
                                                            {Object.keys(this.state.statuses).map((skye, i) => {
                                                                return (
                                                                    <p
                                                                        key={i}
                                                                        style={{
                                                                            color: "white",
                                                                            fontWeight: "bold",
                                                                            backgroundColor: this.state.statuses[skye].color,
                                                                            padding: "3px 6px",
                                                                            margin: "6px 0",
                                                                            boxShadow: Depths.depth4,
                                                                        }}
                                                                    >
                                                                        <span>{this.state.statuses[skye].description}</span>
                                                                        &nbsp;
                                                                        <span>
                                                                            (<FontIcon iconName="OpenSource" style={{ fontSize: "0.8em" }} />{" "}
                                                                            {this.state.statuses[skye].source})
                                                                        </span>
                                                                    </p>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                },
                                            }}
                                            // delay={TooltipDelay.zero}

                                            id="mysuperpowerhover"
                                            directionalHint={DirectionalHint.bottomCenter}
                                            styles={{ root: { display: "inline-block" } }}
                                        >
                                            <div aria-describedby="mysuperpowerhover" style={{ cursor: "pointer" }}>
                                                {Object.keys(this.state.statuses).map((skey, i) => {
                                                    return (
                                                        <span
                                                            key={i}
                                                            style={{
                                                                backgroundColor: this.state.statuses[skey].color || "#2e3649",
                                                                padding: "2px 8px",
                                                                color: "white",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {this.state.statuses[skey].code}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </TooltipHost>
                                    </>
                                );
                            }
                        }}
                    />
                </Stack.Item>

                <Stack.Item>
                    <div
                        style={{
                            textAlign: "right",
                            color: "white",
                            padding: "10px 10px",
                            fontSize: "1.5em",
                            fontWeight: "lighter",
                            lineHeight: "40px",
                            // letterSpacing: '1px',
                            // fontFamily: 'monospace'
                        }}
                    >
                        <AppContext.Consumer>
                            {(app) => (
                                <>
                                    {app.wsConnected && (
                                        <>
                                            {(this.state.noOfWarnings > 0 || this.state.noOfErrors > 0) && (
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        history.push("/sbs/warningsAndErrors");
                                                    }}
                                                >
                                                    {this.state.noOfWarnings > 0 && (
                                                        <span>
                                                            <FontIcon style={{ fontSize: "0.7em", color: "orange" }} iconName="Warning" />{" "}
                                                            {this.state.noOfWarnings} &nbsp; | &nbsp;
                                                        </span>
                                                    )}

                                                    {this.state.noOfErrors > 0 && (
                                                        <span>
                                                            <FontIcon style={{ fontSize: "0.7em", color: "orangered" }} iconName="Error" />{" "}
                                                            {this.state.noOfErrors} &nbsp; | &nbsp;
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            {/* <span>
                                                <FontIcon style={{ fontSize: "0.7em" }} iconName="People" /> {this.state.noOfPeople} &nbsp; | &nbsp;
                                            </span> */}
                                        </>
                                    )}

                                    {!app.wsConnected && (
                                        <span>
                                            <span style={{ color: "orangered" }}>
                                                <FontIcon style={{ fontSize: "0.7em" }} iconName="Error" /> Connection lost!
                                            </span>
                                            &nbsp; | &nbsp;
                                        </span>
                                    )}
                                </>
                            )}
                        </AppContext.Consumer>

                        <TooltipHost content={"Synced " + (this.state.lastSync / 1000).toFixed() + " seconds ago."}>
                            <span
                                style={{
                                    fontFamily: "sans-serif",
                                    fontSize: "1.1ems",
                                    cursor: "pointer",
                                    color: this.state.lastSync < 20000 ? "white" : this.state.lastSync < 40000 ? "orange" : "orangered",
                                }}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    history.push("/time");
                                }}
                            >
                                {moment(this.state.timestamp * 1000)
                                    .tz("Europe/Ljubljana")
                                    .format(window.innerWidth < 600 ? "HH:mm:ss" : "DD.MM.YYYY HH:mm:ss")}
                            </span>
                        </TooltipHost>
                    </div>
                </Stack.Item>
            </Stack>
        );
    });

    render() {
        return <this.TheTopBar></this.TheTopBar>;
    }
}
