import Export from "highcharts-export-csv";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";

import {
    restAPIget,
    getDefaultChartOptions,
    setInitialDataToGraphSeries,
    getBrandedColor,
    setDataPointsToGraphSeries,
    getDefaultChartPlotline,
} from "../../shared/utils";
import EventEmitter from "../../shared/EventEmitter";
import momentRound from "../../shared/momentRounder";
import SharedData from "../../shared/sharedData";
import { CustomScheduleDialog } from "./customScheduleDialog";

import AlertSound from "./alert.mp3";
import { Separator } from "office-ui-fabric-react";
import UserPreferences from "../../shared/userPreferences";
import Notify from "../../shared/notify";

momentRound(moment);
Export(Highcharts);

export class SBSPeakBy10Sec extends React.Component {
    constructor(props) {
        super(props);

        this.preferences = UserPreferences.getProps(["peakAlertIsMuted", "customScheduleValue", "peakDisplayScheduleType"]);
        this.customSchedule = this.preferences.customScheduleValue ?? 20; //MWH
        this.deviationType = this.preferences.peakDisplayScheduleType ?? "compareToCustom";

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Acroni peak", key: "acroniPeak", isCurrentItem: true },
        ]);

        this.alertPlayer = new Audio(AlertSound);
        this.alertPlayer.loop = true;

        this.state = {
            peakChartDataIsLoading: true,
            scheduleThisHourIsLoading: true,
            energyThisHoursIsLoading: true,

            setCustomSchedule: Number(this.customSchedule),
            setDeviationType: this.deviationType,

            isLoadingHistory: true,
            alertIsMuted: this.preferences.peakAlertIsMuted ?? true,
        };

        this.abortController = new AbortController();

        this.customScheduleDialogRef = React.createRef();

        this.chartRef = React.createRef();
        this.chartCanAppendData = true;
        this.chartMaxExtreme = null;
        this.chartIsZoomed = false;
        this.chartOptions = {
            ...getDefaultChartOptions({
                chart: {
                    height: "555px",
                    tooltipShared: true,
                    zoomType: undefined,
                    onSelection: function () {
                        this.chartIsZoomed = true;
                    }.bind(this),
                },
                export: {
                    filename: "Acroni peak",
                    csv: {
                        itemDelimiter: ';',
                        decimalPoint: ','
                    }
                },
                yAxis: [
                    {
                        id: "power",
                        name: "Energy (MWh)",
                        // softMax: 3
                    },
                ],
                xAxis: {
                    type: "datetime",
                    // minTickInterval: 60000,
                    // tickAmount: 15,
                    onSetExtremes: function (min, max) {
                        if (typeof min === "undefined" && typeof max === "undefined") {
                            this.chartIsZoomed = false;
                        }
                    }.bind(this),
                },
                series: [
                    {
                        id: "schedule",
                        name: "Schedule",
                        color: getBrandedColor("orange"),
                        type: "line",
                        lineWidth: 3,
                        zIndex: 2,
                        valueSuffix: "MWh",
                        pointPlacement: "between",
                        valueDecimals: 2,
                        visible: false,
                    },
                    {
                        id: "custom_schedule",
                        name: "Custom schedule",
                        color: getBrandedColor("blue"),
                        type: "line",
                        lineWidth: 3,
                        zIndex: 2,
                        valueSuffix: "MWh",
                        pointPlacement: "between",
                        valueDecimals: 2,
                    },
                    {
                        id: "trend",
                        name: "Trend",
                        color: getBrandedColor("red"),
                        type: "line",
                        lineWidth: 2,
                        zIndex: 2,
                        valueSuffix: "MWh",
                        pointPlacement: "between",
                        valueDecimals: 2,
                        connectNulls: true,
                    },
                    {
                        id: "responsiveTrend",
                        name: "Responsive trend",
                        // color: getBrandedColor("washedViolet"),
                        color: "#ab47bc",
                        type: "line",
                        lineWidth: 2,
                        zIndex: 2,
                        valueSuffix: "MWh",
                        pointPlacement: "between",
                        valueDecimals: 2,
                        connectNulls: true,
                    },
                    {
                        id: "energy",
                        name: "Energy",
                        color: getBrandedColor("green"),
                        borderColor: "rgba(0,255,0, 0.1)",
                        type: "column",
                        valueSuffix: "MWh",
                        pointPlacement: "between",
                        valueDecimals: 2,
                    },
                ],
            }),
        };

        this.currentTimestamp = SharedData.get("timestamp");
        this.currentHourStart = moment.unix(this.currentTimestamp).startOf("hour").unix();

        this.WS_PEAK = SharedData.deepGet("wsChannels", "peak", "channelId");
        this.WS_DRIVE = SharedData.deepGet("wsChannels", "driveAcroniByDay", "channelId");
    }

    componentDidMount() {
        this._isMounted = true;
        this.initData();
        this.getHistoryData();

        EventEmitter.subscribe(
            "APPLICATION_TIME",
            (value) => {
                this.currentTimestamp = value;

                let currentHourStart = moment.unix(this.currentTimestamp).startOf("hour").unix();
                if (currentHourStart !== this.currentHourStart) {
                    this.setState({
                        scheduleThisHourIsLoading: true,
                    });
                }
                this.currentHourStart = currentHourStart;

                this.drawCurrentTimeOnGraph(value);
            },
            this.constructor.name
        );
    }

    componentWillUnmount() {
        this.abortController.abort();
        this._isMounted = false;

        clearTimeout(this.keepRefreshignHistoryTimeOut);

        EventEmitter.unsubscribe("APPLICATION_TIME", this.constructor.name);
        EventEmitter.unsubscribe(`${this.WS_PEAK}/unset`, this.constructor.name);
        EventEmitter.unsubscribe(`${this.WS_DRIVE}/unset`, this.constructor.name);

        EventEmitter.dispatch("unsubscribeFrom", [this.WS_PEAK, this.WS_DRIVE]);

        this.alertPlayer.pause();
        this.alertPlayer.currentTime = 0;
    }

    initData() {
        restAPIget(`/measurements/peak/900/10`, this.abortController.signal, { route: "" }).then((result) => {
            if (result.isOk) {
                let minExtreme = moment.unix(this.currentTimestamp).floor(15, "minutes").unix() * 1000;
                let maxExtreme = moment.unix(this.currentTimestamp).ceil(15, "minutes").unix() * 1000;

                if (maxExtreme === minExtreme) {
                    maxExtreme += 15 * 60 * 1000;
                }

                if (!this.chartIsZoomed) {
                    this.chartRef.current.chart.xAxis[0].setExtremes(minExtreme, maxExtreme);
                }

                let data = result.data.map((d) => {
                    return {
                        timestamp: Number(d.intervalStart) * 1000,
                        energy: Number(d.overallCalculatedEnergy) / 1000,
                        schedule: Number(d.scheduleEnergy) / 1000,
                        custom_schedule: ((Number(d.intervalStart) - minExtreme / 1000 + 10) * this.customSchedule) / 60 / 60,
                    };
                });

                if (data.length > 0) {
                    data[0].trend = 0;
                    data[0].responsiveTrend = 0;

                    let lastEl = [...result.data].reverse().find((i) => i.predictedEnergyAtTheEnd) || {};

                    data[data.length - 1].trend = lastEl.predictedEnergyAtTheEnd / 1000 || 0;
                    data[data.length - 1].responsiveTrend = lastEl.responsivePredictedEnergyAtTheEnd / 1000 || 0;

                    this.setState({
                        currentDeviationCustom: this.state.setCustomSchedule
                            ? (lastEl.predictedEnergyAtTheEnd / 1000 || 0) - this.state.setCustomSchedule / 4
                            : 0,
                        currentTrend: lastEl.predictedEnergyAtTheEnd / 1000 || 0,
                        currentResponsiveTrend: lastEl.responsivePredictedEnergyAtTheEnd / 1000 || 0,
                    });
                }

                this.maxTrendTS = data[data.length - 1].timestamp;

                setInitialDataToGraphSeries(data, ["energy", "schedule", "custom_schedule", "trend", "responsiveTrend"], this.chartRef.current.chart);

                EventEmitter.dispatch("subscribeTo", [this.WS_PEAK, this.WS_DRIVE]);
                EventEmitter.subscribe(
                    `${this.WS_PEAK}/unset`,
                    (newValue) => {
                        if (this.chartCanAppendData) {
                            let data = newValue
                                .filter((d) => d.intervalStart * 1000 >= this.chartMinExtreme)
                                .map((d) => {
                                    let tempTimestamp = Number(d.intervalStart) * 1000;
                                    return {
                                        timestamp: tempTimestamp,
                                        energy: Number(d.overallCalculatedEnergy) / 1000,
                                        trend: tempTimestamp === this.chartMinExtreme ? 0 : undefined,
                                        responsiveTrend: tempTimestamp === this.chartMinExtreme ? 0 : undefined,
                                    };
                                });

                            let chartData = [
                                ...data,
                                ...(newValue[newValue.length - 1]
                                    ? [
                                        {
                                            timestamp: this.maxTrendTS,
                                            trend: Number(newValue[newValue.length - 1].predictedEnergyAtTheEnd) / 1000,
                                            responsiveTrend: Number(newValue[newValue.length - 1].responsivePredictedEnergyAtTheEnd) / 1000,
                                        },
                                    ]
                                    : []),
                            ];

                            this.setState(
                                {
                                    currentDeviationCustom: this.state.setCustomSchedule
                                        ? Number(newValue[newValue.length - 1]?.predictedEnergyAtTheEnd) / 1000 - this.state.setCustomSchedule / 4
                                        : 0,
                                    currentTrend: Number(newValue[newValue.length - 1]?.predictedEnergyAtTheEnd) / 1000,
                                    currentResponsiveTrend: Number(newValue[newValue.length - 1]?.responsivePredictedEnergyAtTheEnd) / 1000,
                                },
                                () => {
                                    this.alertIfRequired();
                                }
                            );

                            setDataPointsToGraphSeries({
                                data: chartData,
                                series: ["energy", "trend", "responsiveTrend"],
                                chart: this.chartRef.current.chart,
                                animate: true,
                            });
                        }
                    },
                    this.constructor.name
                );

                EventEmitter.subscribe(
                    `${this.WS_DRIVE}/unset`,
                    (newValue) => {
                        if (newValue && this._isMounted) {
                            const latestEntry = Object.entries(newValue ?? {}).sort(([entryAkey], [entryBkey]) => Number(entryBkey) - Number(entryAkey))[0];
                            if (latestEntry) {
                                this.setState((prevstate) => ({
                                    isLoadingUploadedSchedule: false,
                                    uploadedSchedule: latestEntry[1].schedulePower / 1000,
                                    currentDeviationSchedule: Number(this.state.currentTrend) - latestEntry[1].schedulePower / 1000 / 4,
                                }));
                            }
                        }
                    },
                    this.constructor.name
                );
            }

            this.setState({ peakChartDataIsLoading: false }, () => {
                this.alertIfRequired();
            });
        });
    }

    getHistoryData() {
        this.setState({ isLoadingHistory: true }, () => {
            let now = moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana");

            restAPIget(`/measurements/maxktvtmt/acroni/${now.clone().startOf("month").unix()}/${now.unix()}/3600`, this.abortController.signal, {
                route: "",
            }).then((result) => {
                if (this._isMounted && result.data) {
                    this.setState({
                        history: {
                            pt: {
                                value: result.data?.kt?.overallCalculatedEnergy / 1000,
                                date: result.data?.kt?.intervalStart
                                    ? moment.unix(result.data?.kt?.intervalStart).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm") +
                                    (result.data?.kt?.intervalEnd
                                        ? " - " + moment.unix(result.data?.kt?.intervalEnd).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm")
                                        : "")
                                    : null,
                            },
                            bt: {
                                value: result.data?.vt?.overallCalculatedEnergy / 1000,
                                date: result.data?.vt?.intervalStart
                                    ? moment.unix(result.data?.vt?.intervalStart).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm") +
                                    (result.data?.vt?.intervalEnd
                                        ? " - " + moment.unix(result.data?.vt?.intervalEnd).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm")
                                        : "")
                                    : null,
                            },
                            st: {
                                value: result.data?.mt?.overallCalculatedEnergy / 1000,
                                date: result.data?.mt?.intervalStart
                                    ? moment.unix(result.data?.mt?.intervalStart).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm") +
                                    (result.data?.mt?.intervalEnd
                                        ? " - " + moment.unix(result.data?.mt?.intervalEnd).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm")
                                        : "")
                                    : null,
                            },
                        },
                        historyMonth: now.format("MMMM yyyy"),
                        isLoadingHistory: false,
                    }, () => {
                        this.keepRefreshignHistoryTimeOut = setTimeout(() => {
                            this.getHistoryData();
                        }, 30 * 60 * 1000);
                    });
                }
            });
        });
    }

    reinitData() {
        this.chartCanAppendData = false;

        this.setState({ peakChartDataIsLoading: true }, () => {
            this.chartRef.current.chart.series.forEach((serie) => {
                serie.setData([]);
            });

            restAPIget(`/measurements/peak/900/10`, this.abortController.signal, { route: "" }).then((result) => {
                if (result.isOk) {
                    let minExtreme = moment.unix(this.currentTimestamp).floor(15, "minutes").unix() * 1000;
                    let maxExtreme = moment.unix(this.currentTimestamp).ceil(15, "minutes").unix() * 1000;

                    if (maxExtreme === minExtreme) {
                        maxExtreme += 15 * 60 * 1000;
                    }

                    let data = result.data.map((d) => {
                        return {
                            timestamp: Number(d.intervalStart) * 1000,
                            energy: Number(d.overallCalculatedEnergy) / 1000,
                            schedule: Number(d.scheduleEnergy) / 1000,
                            custom_schedule: ((Number(d.intervalStart) - minExtreme / 1000 + 10) * this.customSchedule) / 60 / 60,
                        };
                    });

                    if (data.length > 0) {
                        data[0].trend = 0;
                        data[0].responsiveTrend = 0;

                        let lastEl = [...result.data].reverse().find((i) => i.predictedEnergyAtTheEnd) || {};

                        data[data.length - 1].trend = lastEl.predictedEnergyAtTheEnd / 1000 || 0;
                        data[data.length - 1].responsiveTrend = lastEl.responsivePredictedEnergyAtTheEnd / 1000 || 0;
                    }

                    this.maxTrendTS = data[data.length - 1].timestamp;

                    setInitialDataToGraphSeries(data, ["energy", "schedule", "custom_schedule", "trend", "responsiveTrend"], this.chartRef.current.chart);

                    this.chartCanAppendData = true;

                    this.setState({ peakChartDataIsLoading: false });
                }
            });
        });
    }

    drawCurrentTimeOnGraph(timestamp) {
        if (this._isMounted && this.chartRef.current && this.chartRef.current.chart) {
            this.chartRef.current.chart.xAxis[0].removePlotLine("timeNow");
            this.chartRef.current.chart.xAxis[0].addPlotLine(
                getDefaultChartPlotline({
                    id: "timeNow",
                    label: "Now",
                    value: timestamp * 1000,
                })
            );

            let minExtreme = moment.unix(timestamp).floor(15, "minutes").unix() * 1000;
            let maxExtreme = moment.unix(timestamp).ceil(15, "minutes").unix() * 1000;

            if (!this.chartIsZoomed) {
                this.chartRef.current.chart.xAxis[0].setExtremes(minExtreme, maxExtreme);
            }
            this.chartMaxExtreme = maxExtreme;
            this.chartMinExtreme = minExtreme;

            if (this.chartMaxExtreme && minExtreme >= this.chartMaxExtreme) {
                this.reinitData();
            }

            if (this.state.quarterFrom !== this.chartMinExtreme) {
                this.setState({
                    quarterFrom: this.chartMinExtreme / 1000,
                    quarterTo: this.chartMaxExtreme / 1000,
                });
            }
        }
    }

    toggleAlert() {
        this.setState(
            (prevstate) => {
                return {
                    alertIsMuted: !prevstate.alertIsMuted,
                };
            },
            () => {
                UserPreferences.set("peakAlertIsMuted", this.state.alertIsMuted, (data) => {
                    restAPIget("/set/permanent/userPreferences/" + JSON.stringify(data), this.abortController.signal, { route: "" }).then((result) => {
                        if (!result.isOk) {
                            Notify.error({ message: "Failed to save user preferences" });
                        }
                    });
                });

                this.alertIfRequired();
            }
        );
    }

    alertIfRequired() {
        if (!this.state.alertIsMuted) {
            if (
                (this.state.setDeviationType === "compareToCustom" && this.state.currentDeviationCustom > 0) ||
                (this.state.setDeviationType === "compareToSchedule" && this.state.currentDeviationSchedule > 0)
            ) {
                this.alertPlayer.play();
            } else {
                this.alertPlayer.pause();
                this.alertPlayer.currentTime = 0;
            }
        }
    }

    render() {
        const deviationValue = this.state.setDeviationType === "compareToCustom" ? this.state.currentDeviationCustom : this.state.currentDeviationSchedule;
        const scheduleValue =
            this.state.setDeviationType === "compareToCustom" ? (this.state.setCustomSchedule ?? 0) / 4 : (this.state.uploadedSchedule ?? 0) / 4;

        return (
            <>
                <CustomScheduleDialog
                    ref={this.customScheduleDialogRef}
                    onContinue={(newState) => {
                        this.setState(
                            {
                                setCustomSchedule: newState.customSchedule,
                                setDeviationType: newState.deviationType,
                                currentDeviationCustom: newState.customSchedule ? Number(this.state.currentTrend) - newState.customSchedule / 4 : 0,
                                currentDeviationSchedule: this.state.uploadedSchedule ? Number(this.state.currentTrend) - this.state.uploadedSchedule / 4 : 0,
                            },
                            () => {
                                this.customSchedule = newState.customSchedule;
                                this.deviationType = newState.deviationType;

                                UserPreferences.setProps(
                                    {
                                        customScheduleValue: this.customSchedule,
                                        peakDisplayScheduleType: this.deviationType,
                                    },
                                    (data) => {
                                        restAPIget("/set/permanent/userPreferences/" + JSON.stringify(data), this.abortController.signal, { route: "" }).then(
                                            (result) => {
                                                if (!result.isOk) {
                                                    Notify.error({ message: "Failed to save user preferences" });
                                                }
                                            }
                                        );
                                    }
                                );

                                this.reinitData();

                                this.alertIfRequired();
                            }
                        );
                    }}
                />

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                            <NContainer
                                title={`Energy trend (${this.state.quarterFrom ? moment.unix(this.state.quarterFrom).tz("Europe/Ljubljana").format("HH:mm") : ""
                                    } - ${this.state.quarterTo ? moment.unix(this.state.quarterTo).tz("Europe/Ljubljana").format("HH:mm") : ""})`}
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor("red") }}>
                                    {this.state.currentTrend?.toFixed(2)} <span>MWh</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                            <NContainer
                                title={`Current trend (${this.state.quarterFrom ? moment.unix(this.state.quarterFrom).tz("Europe/Ljubljana").format("HH:mm") : ""
                                    } - ${this.state.quarterTo ? moment.unix(this.state.quarterTo).tz("Europe/Ljubljana").format("HH:mm") : ""})`}
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor("washedViolet") }}>
                                    {this.state.currentResponsiveTrend?.toFixed(2)} <span>MWh</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                            <NContainer
                                title={`Deviation (${this.state.quarterFrom ? moment.unix(this.state.quarterFrom).tz("Europe/Ljubljana").format("HH:mm") : ""
                                    } - ${this.state.quarterTo ? moment.unix(this.state.quarterTo).tz("Europe/Ljubljana").format("HH:mm") : ""})`}
                                style={{
                                    background:
                                        deviationValue > 0
                                            ? "linear-gradient(45deg, rgba(204, 62, 68, 0.15), rgba(204, 62, 68, 0.03) 25%, rgba(204, 62, 68, 0.03) 75%, rgba(204, 62, 68, 0.15))"
                                            : "linear-gradient(45deg, rgba(83, 153, 79, 0.15), rgba(83, 153, 79, 0.03) 25%, rgba(83, 153, 79, 0.03) 75%, rgba(83, 153, 79, 0.15))",
                                }}
                                headerStyle={{
                                    background:
                                        deviationValue > 0
                                            ? "linear-gradient(45deg, rgba(204, 62, 68, 0.15), rgba(204, 62, 68, 0.03) 25%, rgba(204, 62, 68, 0.03) 75%, rgba(204, 62, 68, 0.15))"
                                            : "linear-gradient(45deg, rgba(83, 153, 79, 0.15), rgba(83, 153, 79, 0.03) 25%, rgba(83, 153, 79, 0.03) 75%, rgba(83, 153, 79, 0.15))",
                                }}
                                rightItems={[
                                    {
                                        key: "toggleAlert",
                                        type: "actionButton",
                                        text: "Toggle alert",
                                        checked: !this.state.alertIsMuted,
                                        icon: this.state.alertIsMuted ? "Volume0" : "Volume3",
                                        onClick: this.toggleAlert.bind(this),
                                    },
                                ]}
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor(deviationValue < 0 ? "green" : "red") }}>
                                    <span>{deviationValue < 0 ? "LOW: " : "HIGH: "}</span> {deviationValue?.toFixed(2)} <span>MWh</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxxl3">
                            <NContainer
                                title={`${this.state.setDeviationType === "compareToCustom" ? "Custom" : "Uploaded"} schedule (${this.state.quarterFrom ? moment.unix(this.state.quarterFrom).tz("Europe/Ljubljana").format("HH:mm") : ""
                                    } - ${this.state.quarterTo ? moment.unix(this.state.quarterTo).tz("Europe/Ljubljana").format("HH:mm") : ""})`}
                                rightItems={[
                                    {
                                        key: "forceRefresh",
                                        type: "actionButton",
                                        text: "Toggle schedule",
                                        icon: "SetAction",
                                        onClick: function () {
                                            this.customScheduleDialogRef.current._showDialog(this.deviationType, this.customSchedule);
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <div
                                    className="number"
                                    style={{ backgroundColor: getBrandedColor(this.state.setDeviationType === "compareToCustom" ? "blue" : "orange") }}
                                >
                                    {scheduleValue?.toFixed(2)} <span>MWh</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title="These 15 minutes live chart"
                                isLoading={this.state.peakChartDataIsLoading}
                                style={{ maxHeight: "600px" }}
                                rightItems={[
                                    {
                                        key: "ExportToCSV",
                                        type: "actionButton",
                                        text: "CSV",
                                        icon: "DownloadDocument",
                                        onClick: function () {
                                            this.chartRef.current.chart.downloadCSV();
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <div id="chart">
                                    <HighchartsReact highcharts={Highcharts} ref={this.chartRef} options={this.chartOptions} />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <br />
                    <Separator
                        styles={{
                            content: { fontSize: "1.5em" },
                            root: { selectors: { "::before": { background: "rgba(255, 255, 255, 0.3)" } } },
                        }}
                    >
                        {this.state.historyMonth ?? "This month"} hourly tariff records
                    </Separator>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                            <NContainer title="Peak tariff record" isLoading={this.state.isLoadingHistory}>
                                <div className="number">
                                    {this.state.history?.pt?.value}
                                    <span>&nbsp;MWh</span>
                                    <br />
                                    <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.65em" }}>{this.state.history?.pt?.date}</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                            <NContainer title="Big tariff record" isLoading={this.state.isLoadingHistory}>
                                <div className="number">
                                    {this.state.history?.bt?.value}
                                    <span>&nbsp;MWh</span>
                                    <br />
                                    <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.65em" }}>{this.state.history?.bt?.date}</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                            <NContainer title="Small tariff record" isLoading={this.state.isLoadingHistory}>
                                <div className="number">
                                    {this.state.history?.st?.value}
                                    <span>&nbsp;MWh</span>
                                    <br />
                                    <span style={{ color: "gray", fontStyle: "oblique", fontSize: "0.65em" }}>{this.state.history?.st?.date}</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
