import { Depths } from "@uifabric/fluent-theme";
import { Separator, Spinner, SpinnerSize, AnimationStyles, ActionButton, FontIcon } from "office-ui-fabric-react";
import React from "react";

import { CardHeader } from "../cardHeader/cardHeader";

/**
 * @export
 * @param {Object} props
 * @param {string} props.loadingSpinnerPosition Spinner position
 * @param {string} props.title Title
 * @param {string} props.headerStyle Custom style for header
 * @param {Object[]} props.rightItems Right items
 * @param {("actionButton" | "customText" | "chooseOption" | "datePicker" | "monthPicker" | "emptySpace")} props.rightItems[].type Right items type
 * @param {string} props.collapsed Is collapsed
 * @param {string} props.style Custom style
 * @param {boolean} props.titleIsBig Title is bigger
 * @param {boolean} props.isLoading Is loading container
 * @return
 */
export function NContainer(props) {
    let spinnerLabelPosition = "bottom";

    switch (props.loadingSpinnerPosition) {
        case "left":
            spinnerLabelPosition = "right";
            break;

        default:
            spinnerLabelPosition = "bottom";
            break;
    }
    return (
        <div
            style={{
                boxShadow: Depths.depth8,
                padding: "10px",
                maxHeight: "400px",
                ...props.style,
            }}
        >
            <CardHeader
                text={props.title}
                textIsBig={props.titleIsBig}
                headerStyle={props.headerStyle}
                rightItems={props.rightItems}
                onCollapseToggle={props.onCollapseToggle}
                collapsed={props.collapsed}
            />

            {!props.collapsed && (
                <div style={{ ...AnimationStyles.fadeIn500 }}>
                    <Separator />

                    <div style={{ display: props.isLoading === true ? "unset" : "none", ...AnimationStyles.fadeIn500 }}>
                        <Spinner label="Waiting for data..." ariaLive="assertive" size={SpinnerSize.large} labelPosition={spinnerLabelPosition} />
                    </div>
                    <div style={{ display: props.isLoading === true ? "none" : "unset", ...AnimationStyles.fadeIn500 }}>
                        {props.error ? (
                            <div style={{ textAlign: "center" }}>
                                <div style={{ color: "orangered", ...props.error.style }}>
                                    <FontIcon iconName="IncidentTriangle" /> &nbsp;
                                    {props.error.content || "Error !"}
                                </div>
                                {(props.error.actions || []).map((action, index) => (
                                    <ActionButton key={index} iconProps={{ iconName: action.icon }} onClick={action.onClick}>
                                        {action.text}
                                    </ActionButton>
                                ))}
                            </div>
                        ) : (
                            props.children
                        )}
                    </div>
                </div>
            )}

            {/* {props.isLoading === true &&
                    <div>
                        <br />
                        <Spinner label="Waiting for data..." ariaLive="assertive" size={SpinnerSize.large} />
                        <br />
                    </div>
                }
                {props.isLoading !== true &&
                    props.children
                } */}
        </div>
    );
}
