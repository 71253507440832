const UserPreferences = {
    data: {},

    /**
     * @description Get value by key
     * @param  {string} key Get value by key
     * @return {(string | number)}
     */
    get: function (key) {
        return this.data[key];
    },

    /**
     * @description Get an object {key:value} where props are keys
     * @param  {string[]} props Get all
     * @return {Object}
     */
    getProps: function (props) {
        if (Array.isArray(props)) {
            return props.reduce((pv, cv) => {
                pv[cv] = this.data[cv];
                return pv;
            }, {});
        } else {
            return {};
        }
    },

    /**
     * @callback callback
     * @param  {any} data
     * @param  {callback} [callback] Function to execute after
     * @return {void}
     */
    init: function (data, callback) {
        this.data = data;

        if (callback) {
            callback(this.data);
        }
    },

    /**
     * @callback callback
     * @param  {string} key Key
     * @param  {(string | number)} value Value to set
     * @param  {callback} callback Function to execute after
     * @return {void}
     */
    set: function (key, value, callback) {
        this.data[key] = value;

        if (callback) {
            callback(this.data);
        }
    },

    /**
     * @callback callback
     * @param  {Object} object Set an object {key:value}
     * @param  {callback} callback  Function to execute after
     * @return {void}
     */
    setProps: function (object, callback) {
        Object.entries(object ?? {}).forEach((entry) => {
            const [key, value] = entry;
            this.data[key] = value;
        });

        if (callback) {
            callback(this.data);
        }
    },
};
export default UserPreferences;
