import React from "react";
import { Label } from "office-ui-fabric-react";
import styles from "./htmlField.module.css";

export function HtmlField(props) {
    return (
        <div>
            <Label disabled={props.disabled}>{props.label || ""}</Label>
            <div
                className={[styles.htmlFieldContent, props.disabled ? styles.disabled : "", props.oneline ? styles.oneline : ""].join(" ")}
                style={props.style}
            >
                {props.children || <>&nbsp;</>}
            </div>
        </div>
    );
}
