import React from "react";
import { Depths } from "@uifabric/fluent-theme";

export function NBar(props) {
    return (
        <div
            style={{
                color: "white",
                boxShadow: Depths.depth4,
                padding: "10px",
                lineHeight: "40px",
                // fontWeight: 'bold',
                backgroundColor: "#1e273b",
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
}
