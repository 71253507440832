import { Text, Link } from "office-ui-fabric-react";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";

export class NotFound extends React.Component {
    controller = new AbortController();

    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Not found", key: "notFound", isCurrentItem: true },
        ]);

        this.state = {};
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
        this.controller.abort();
    }

    render() {
        return (
            <>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer style={{ height: "unset", color: "slategray", textAlign: "center" }} title="Ooops...">
                                <Text variant="xLargePlus" block>
                                    Ooops!
                                </Text>

                                <Text variant="xLarge" block>
                                    404 - PAGE NOT FOUND
                                </Text>

                                <Text variant="large" block>
                                    The page you are looking for might have been removed or renamed.
                                </Text>

                                <br />
                                <Text variant="mediumPlus" style={{ fontStyle: "oblique" }} block>
                                    If you think you should have permission to access a desired page, try to <b>log out</b> and <b>log in</b> again.
                                </Text>
                                <Text variant="mediumPlus" style={{ fontStyle: "oblique" }} block>
                                    If you have problems, contact us: <Link href="mailto: support@ngen.si">support@ngen.si</Link>
                                </Text>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
