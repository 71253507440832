import { Depths } from '@uifabric/fluent-theme';
import { FontIcon, ContextualMenuItemType, CommandButton } from 'office-ui-fabric-react';
import Boost from 'highcharts/modules/boost';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';

import { addDataPointsToGraphSeries, setInitialDataToGraphSeries, getBrandedColor, restAPIget, restAPIpost, getDefaultChartPlotline, generateUUID, hasReadAccess } from '../../shared/utils';
import { BatPowerMeter } from '../../components/batteryPowermeter/batPowermeter';
import { Battery } from '../../components/battery/battery';
import { HouseEnergyScheme } from '../../components/energySchemes/houseEnergyScheme';
import { IAccept } from '../../components/iAccept/iaccept';
import { NBar } from '../../components/nBar/nBar';
import { NContainer } from '../../components/nContainer/nContainer';
import EventEmitter from '../../shared/EventEmitter';
import Notify from '../../shared/notify';
import SharedData from '../../shared/sharedData';

Boost(Highcharts);

export class SuhaDashboard extends React.Component {
    suhaStatuses = [
        { code: 35, description: 'Fault', color: getBrandedColor("red") },
        { code: 303, description: 'Off', color: getBrandedColor("red") },
        { code: 307, description: 'Ok', color: getBrandedColor("green") },
        { code: 455, description: 'Warning', color: getBrandedColor("red") }
    ];

    constructor(props) {
        super(props);

        this.WS_CHANNEL = SharedData.deepGet("wsChannels", "assetMeasurement", "channelId");
        this.PAGE_ID = generateUUID();

        this.SHOW_ACTIVATION_PLAN = hasReadAccess("asset3ActivationPlan");

        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked")
                }
            },
            { text: 'Suha dashboard', key: 'suhaDashboard', isCurrentItem: true }
        ]);

        this.abortController = new AbortController();

        this.mostRecentMeasureObj = null; //USED FOR SVGS 

        this.iAcceptReference = React.createRef();

        //#region powermeterchart relates attributes
        this.batPowerMeter = React.createRef();

        this.PowerChartRef = React.createRef();
        this.powerChartCanAppendData = false;
        this.powerChartMinExtremeTS = 0;
        this.powerChartShowIntervalOfMins = 15;
        this.powerchartIsZoomed = false;

        this.powerChartOptions = {
            chart: {
                zoomType: 'x',
                type: "line",
                height: '355px',
                events: {
                    selection: function (event) {
                        this.powerchartIsZoomed = true;
                    }.bind(this)
                }
            },
            boost: {
                useGPUTranslations: false
            },
            time: {
                timezone: 'Europe/Ljubljana'
            },
            title: {
                text: undefined
            },

            credits: {
                enabled: false
            },

            legend: {
                itemStyle: {
                    color: 'white'
                },
                itemHoverStyle: {
                    color: '#5dbb46'
                }
            },

            tooltip: {
                crosshairs: [
                    true,
                    false
                ],
                shared: true,
                valueDecimals: 2,
                backgroundColor: 'rgba(27, 35, 55, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: 'white'
                    }
                },
                events: {
                    setExtremes: function (e) {
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            this.powerchartIsZoomed = false;
                        }
                    }.bind(this)
                }
            },
            yAxis: [
                {
                    // id: 'power',
                    title: {
                        text: "Power (KW)",
                        style: {
                            color: 'white'
                        }
                    },
                    gridLineColor: 'darkgray',
                    gridLineWidth: 0.5,
                    labels: {
                        style: {
                            color: 'white'
                        }
                    },

                    startOnTick: false,
                    endOnTick: false
                }
            ],
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                },
                area: {
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    boostThreshold: 5000,
                    states: {
                        hover:
                        {
                            enabled: false
                        }
                    }
                }
            },
            series: [
                {
                    id: 'power',
                    name: 'Power',
                    type: 'line',
                    color: '#5dbb46',
                    lineWidth: 2,
                    zIndex: 4,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                },
                {
                    id: 'setpoint',
                    name: 'Setpoint',
                    type: 'line',
                    color: 'red',
                    lineWidth: 1,
                    zIndex: 3,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                },
                {
                    id: 'baseline',
                    name: 'Baseline',
                    type: 'line',
                    color: 'rgb(124,181,236)',
                    lineWidth: 1,
                    zIndex: 2,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                },
                {
                    id: 'pgrid',
                    name: 'Grid power',
                    type: 'line',
                    color: getBrandedColor('orange'),
                    lineWidth: 2,
                    zIndex: 4,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                },
                {
                    id: 'maxDischargePower',
                    name: 'Max discharge power',
                    type: 'area',
                    color: '#3477aa',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0.95
                        },
                        stops: [
                            [0, 'rgba(0, 89, 255, 0.2)'],
                            [1, 'rgba(0, 89, 255, 0.05)']
                        ]
                    },
                    lineWidth: 1,
                    zIndex: 3,
                    fillOpacity: 0.95,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                },
                {
                    id: 'maxChargePower',
                    name: 'Max charge power',
                    type: 'area',
                    color: '#cc3e44',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0.95,
                            x2: 0,
                            y2: 0
                        },
                        stops: [
                            [0, 'rgba(255, 0, 0, 0.2)'],
                            [1, 'rgba(255, 0, 0, 0.05)']
                        ]
                    },
                    lineWidth: 1,
                    zIndex: 3,
                    fillOpacity: 0.95,
                    tooltip: {
                        valueSuffix: 'KW'
                    }
                }
            ],
        };
        //#endregion

        //#region socmeterchart relates attributes
        this.batSocMeter = React.createRef();

        this.SocChartRef = React.createRef();
        this.socChartCanAppendData = false;
        this.socChartMinExtremeTS = 0;
        this.socChartShowIntervalOfMins = 15;
        this.socchartIsZoomed = false;

        this.socChartOptions = {
            chart: {
                zoomType: 'x',
                type: "line",
                height: '355px',
                events: {
                    selection: function (event) {
                        this.socchartIsZoomed = true;
                    }.bind(this)
                }
            },
            boost: {
                useGPUTranslations: false
            },
            time: {
                timezone: 'Europe/Ljubljana'
            },
            title: {
                text: undefined
            },
            credits: {
                enabled: false
            },
            legend: {
                itemStyle: {
                    color: 'white'
                },
                itemHoverStyle: {
                    color: '#5dbb46'
                }
            },
            tooltip: {
                crosshairs: [
                    true,
                    false
                ],
                shared: true,
                valueDecimals: 2,
                backgroundColor: 'rgba(27, 35, 55, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: 'white'
                    }
                },
                events: {
                    setExtremes: function (e) {
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            this.socchartIsZoomed = false;
                        }
                    }.bind(this)
                }
            },
            yAxis: [
                {
                    id: 'socAxis',
                    min: 0,
                    max: 100,
                    title: {
                        text: "SoC (%)",
                        style: {
                            color: 'white'
                        }
                    },
                    gridLineColor: 'darkgray',
                    gridLineWidth: 0.5,
                    labels: {
                        style: {
                            color: 'white'
                        }
                    },

                    startOnTick: false,
                    endOnTick: false
                },
                {
                    id: 'energyAxis',
                    startOnTick: false,
                    endOnTick: false,
                    name: 'energy',
                    opposite: true,
                    title: {
                        text: "Energy (KWh)",
                        style: {
                            color: 'white'
                        }
                    },
                    gridLineColor: 'darkgray',
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: 'white'
                        }
                    }
                },
            ],
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                },
                area: {
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    boostThreshold: 5000,
                    states: {
                        hover:
                        {
                            enabled: false
                        }
                    }
                }
            },
            series: [
                {
                    id: 'soc',
                    name: 'SoC',
                    color: '#5fba46',
                    type: 'area',
                    lineWidth: 1,
                    yAxis: 'socAxis',
                    tooltip: {
                        valueSuffix: '%'
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0.95
                        },
                        stops: [
                            [0, 'rgba(102, 255, 0, 0.2)'],
                            [1, 'rgba(102, 255, 0, 0.05)']
                        ]
                    },
                    fillOpacity: 0.95
                },
                {
                    id: 'toChargeEnergy',
                    name: 'To charge energy',
                    color: 'red',
                    lineWidth: 2,
                    yAxis: 'energyAxis',
                    tooltip: {
                        valueSuffix: 'KWh'
                    }
                },
                {
                    id: 'toDischargeEnergy',
                    name: 'To discharge energy',
                    color: 'dodgerblue',
                    lineWidth: 2,
                    yAxis: 'energyAxis',
                    tooltip: {
                        valueSuffix: 'KWh'
                    }
                }
            ],
        };
        //#endregion

        //#region powerscheme energy relates attributes
        this.powerScheme = React.createRef();

        this.energyChartRef = React.createRef();
        //#endregion

        this.state = {
            powerMeterIsLoading: true,
            powerChartIsLoading: true,
            powerChartGraphType: '15M',

            socMeterIsLoading: true,
            socChartIsLoading: true,
            socChartGraphType: '15M',

            powerSchemeIsLoading: true,

            assetStatuses: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getInitialData();

        EventEmitter.subscribe('APPLICATION_TIME', (value) => {
            this.drawCurrentTimeOnGraphs(value * 1000);
        }, 'suhaDashbardTime');
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();
        EventEmitter.unsubscribe('APPLICATION_TIME', 'suhaDashbardTime');
        EventEmitter.unsubscribe(`${this.WS_CHANNEL}/unset`, this.PAGE_ID);
        EventEmitter.dispatch('unsubscribeFrom', [this.WS_CHANNEL]);
    }

    getInitialData() {
        restAPIget('/measurements/raw/3/15', this.abortController.signal).then(result => {
            if (result.isOk) {
                let isUnderControl = false;
                if (result.data.length > 0) {
                    this.mostRecentMeasureObj = result.data[result.data.length - 1];
                    isUnderControl = this.mostRecentMeasureObj.statusafrr;

                    if (this.batPowerMeter && this.batPowerMeter.current) {
                        if (!isNaN(this.mostRecentMeasureObj.an))
                            this.batPowerMeter.current.setValue('minPowerScale', (this.mostRecentMeasureObj.an).toFixed(1));

                        if (!isNaN(this.mostRecentMeasureObj.ap))
                            this.batPowerMeter.current.setValue('maxPowerScale', (this.mostRecentMeasureObj.ap).toFixed(1));

                        if (!isNaN(this.mostRecentMeasureObj.p))
                            this.batPowerMeter.current.setValue('absPower', (this.mostRecentMeasureObj.p));

                        let fixFactor = -1; // because the percent have to be negative to make the powermeter work nice     
                        if (this.mostRecentMeasureObj.p <= 0) {
                            if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.an))
                                this.batPowerMeter.current.setValue('relPower', this.mostRecentMeasureObj.p / this.mostRecentMeasureObj.an * 100 * fixFactor);
                        }
                        else {
                            if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.ap))
                                this.batPowerMeter.current.setValue('relPower', this.mostRecentMeasureObj.p / this.mostRecentMeasureObj.ap * 100);
                        }
                    }

                    if (this.batSocMeter && this.batSocMeter.current) {
                        this.batSocMeter.current.setValue('minSocLimit', 10);
                        this.batSocMeter.current.setValue('maxSocLimit', 90);

                        if (!isNaN(this.mostRecentMeasureObj.ae)) {
                            this.batSocMeter.current.setValue('dischargeEnergy', this.mostRecentMeasureObj.ae);
                            this.batSocMeter.current.setValue('remainingEnergy', this.mostRecentMeasureObj.ae);
                        }

                        if (!isNaN(this.mostRecentMeasureObj.te)) {
                            this.batSocMeter.current.setValue('fullEnergy', this.mostRecentMeasureObj.te);
                            this.batSocMeter.current.setValue('fullEnergy', this.mostRecentMeasureObj.te);
                        }
                        if (!isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                            this.batSocMeter.current.setValue('soc', this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.te * 100);
                            this.batSocMeter.current.setValue('chargeEnergy', (this.mostRecentMeasureObj.te - this.mostRecentMeasureObj.ae));
                        }

                        if (this.mostRecentMeasureObj.p < 0 && !isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                            let value = Math.abs((this.mostRecentMeasureObj.te - this.mostRecentMeasureObj.ae) / this.mostRecentMeasureObj.p);
                            value = value * 60 * 60;
                            this.batSocMeter.current.setValue('estChargeTime', value);
                        }
                        else if (this.mostRecentMeasureObj.p > 0 && !isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                            let value = this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.p;
                            value = value * 60 * 60;
                            this.batSocMeter.current.setValue('estDischargeTime', value);
                        }
                        else {
                            this.batSocMeter.current.setValue('hideEstTime');
                        }
                    }

                    if (this.powerScheme && this.powerScheme.current) {
                        if (!isNaN(this.mostRecentMeasureObj.p))
                            this.powerScheme.current.setValue('powerValue', this.mostRecentMeasureObj.p);

                        if (!isNaN(this.mostRecentMeasureObj.pgrid))
                            this.powerScheme.current.setValue('gridValue', this.mostRecentMeasureObj.pgrid);

                        if (!isNaN(this.mostRecentMeasureObj.ae) && !isNaN(this.mostRecentMeasureObj.te))
                            this.powerScheme.current.setValue('socValue', (this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.te * 100));
                    }

                    if (this.PowerChartRef.current && this.PowerChartRef.current.chart) {
                        setInitialDataToGraphSeries(
                            result.data.map(
                                d => {
                                    return {
                                        timestamp: Number(d.timestamp),
                                        power: Number(d.p),
                                        pgrid: Number(d.pgrid),
                                        setpoint: Number(d.s),
                                        maxChargePower: Number(d.an),
                                        maxDischargePower: Number(d.ap),
                                        baseline: Number(d.b)
                                    };
                                }
                            ),
                            ['power', 'pgrid', 'setpoint', 'maxChargePower', 'maxDischargePower', 'baseline'],
                            this.PowerChartRef.current.chart
                        );
                    }

                    if (this.SocChartRef.current && this.SocChartRef.current.chart) {
                        setInitialDataToGraphSeries(
                            result.data.map(
                                d => {
                                    return {
                                        timestamp: Number(d.timestamp),
                                        soc: Number(d.soc),
                                        toChargeEnergy: Number(d.te - d.ae),
                                        toDischargeEnergy: Number(d.ae),
                                    };
                                }
                            ),
                            ['soc', 'toChargeEnergy', 'toDischargeEnergy'],
                            this.SocChartRef.current.chart
                        );
                    }
                }

                this.powerChartCanAppendData = true;
                this.socChartCanAppendData = true;

                this.setState({
                    underControl: isUnderControl,

                    powerMeterIsLoading: false,
                    powerChartIsLoading: false,

                    socMeterIsLoading: false,
                    socChartIsLoading: false,

                    powerSchemeIsLoading: false,

                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        // deviceActivated: {
                        //     code: 'A',
                        //     source: 'NGEN',
                        //     color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                        //     description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        // },
                        suhaStatus: {
                            code: 'S',
                            source: 'SUHA',
                            color: this.suhaStatuses.find(s => s.code === this.mostRecentMeasureObj.status)['color'],
                            description: 'Status: ' + this.suhaStatuses.find(s => s.code === this.mostRecentMeasureObj.status)['description']
                        }
                    }
                }, () => {
                    this.props.setPath(null, this.state.assetStatuses);
                    this.subsbribeToWebsocket();
                });
            }
            else if (result.error.code !== 20) {
                Notify.error({ message: result.error.message,  src: this.PAGE_ID });
            }


        });
    }
    subsbribeToWebsocket() {

        EventEmitter.dispatch('subscribeTo', [this.WS_CHANNEL]);
        EventEmitter.subscribe(`${this.WS_CHANNEL}/unset`, (newValue) => {
            if (this._isMounted && newValue.data.length > 0) {
                this.mostRecentMeasureObj = newValue.data[0];

                if (this.batPowerMeter && this.batPowerMeter.current) {
                    if (!isNaN(this.mostRecentMeasureObj.an))
                        this.batPowerMeter.current.setValue('minPowerScale', (this.mostRecentMeasureObj.an).toFixed(1));

                    if (!isNaN(this.mostRecentMeasureObj.ap))
                        this.batPowerMeter.current.setValue('maxPowerScale', (this.mostRecentMeasureObj.ap).toFixed(1));

                    if (!isNaN(this.mostRecentMeasureObj.p))
                        this.batPowerMeter.current.setValue('absPower', (this.mostRecentMeasureObj.p));

                    let fixFactor = -1; // because the percent have to be negative to make the powermeter work nice     
                    if (this.mostRecentMeasureObj.p <= 0) {
                        if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.an))
                            this.batPowerMeter.current.setValue('relPower', this.mostRecentMeasureObj.p / this.mostRecentMeasureObj.an * 100 * fixFactor);
                    }
                    else {
                        if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.ap))
                            this.batPowerMeter.current.setValue('relPower', this.mostRecentMeasureObj.p / this.mostRecentMeasureObj.ap * 100);
                    }
                }

                if (this.PowerChartRef.current && this.PowerChartRef.current.chart && this.powerChartCanAppendData) {
                    addDataPointsToGraphSeries(
                        //DATA IS DESC SORTED, BUT WE WANT TO MAINTAIN ASC SORTING FOR HIGHCHARTS (because reasons)
                        newValue.data.reverse().map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    power: Number(d.p),
                                    pgrid: Number(d.pgrid),
                                    setpoint: Number(d.s),
                                    maxChargePower: Number(d.an),
                                    maxDischargePower: Number(d.ap),
                                    baseline: Number(d.b)
                                };
                            }
                        ),
                        ['power', 'pgrid', 'setpoint', 'maxChargePower', 'maxDischargePower', 'baseline'],
                        this.PowerChartRef.current.chart,
                        this.powerChartMinExtremeTS
                    );
                }

                if (this.batSocMeter && this.batSocMeter.current) {
                    this.batSocMeter.current.setValue('minSocLimit', 10);
                    this.batSocMeter.current.setValue('maxSocLimit', 90);

                    if (!isNaN(this.mostRecentMeasureObj.ae)) {
                        this.batSocMeter.current.setValue('dischargeEnergy', this.mostRecentMeasureObj.ae);
                        this.batSocMeter.current.setValue('remainingEnergy', this.mostRecentMeasureObj.ae);
                    }

                    if (!isNaN(this.mostRecentMeasureObj.te)) {
                        this.batSocMeter.current.setValue('fullEnergy', this.mostRecentMeasureObj.te);
                        this.batSocMeter.current.setValue('fullEnergy', this.mostRecentMeasureObj.te);
                    }
                    if (!isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                        this.batSocMeter.current.setValue('soc', this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.te * 100);
                        this.batSocMeter.current.setValue('chargeEnergy', (this.mostRecentMeasureObj.te - this.mostRecentMeasureObj.ae));
                    }

                    if (this.mostRecentMeasureObj.p < 0 && !isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                        let value = Math.abs((this.mostRecentMeasureObj.te - this.mostRecentMeasureObj.ae) / this.mostRecentMeasureObj.p);
                        value = value * 60 * 60;
                        this.batSocMeter.current.setValue('estChargeTime', value);
                    }
                    else if (this.mostRecentMeasureObj.p > 0 && !isNaN(this.mostRecentMeasureObj.te) && !isNaN(this.mostRecentMeasureObj.ae)) {
                        let value = this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.p;
                        value = value * 60 * 60;
                        this.batSocMeter.current.setValue('estDischargeTime', value);
                    }
                    else {
                        this.batSocMeter.current.setValue('hideEstTime');
                    }
                }

                if (this.SocChartRef.current && this.SocChartRef.current.chart && this.socChartCanAppendData) {
                    addDataPointsToGraphSeries(
                        //DATA IS DESC SORTED, BUT WE WANT TO MAINTAIN ASC SORTING FOR HIGHCHARTS (because reasons)
                        newValue.data.reverse().map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    soc: Number(d.soc),
                                    toChargeEnergy: Number(d.te - d.ae),
                                    toDischargeEnergy: Number(d.ae),
                                };
                            }
                        ),
                        ['soc', 'toChargeEnergy', 'toDischargeEnergy'],
                        this.SocChartRef.current.chart,
                        this.socChartMinExtremeTS
                    );
                }

                if (this.powerScheme && this.powerScheme.current) {
                    if (!isNaN(this.mostRecentMeasureObj.p))
                        this.powerScheme.current.setValue('powerValue', this.mostRecentMeasureObj.p);

                    if (!isNaN(this.mostRecentMeasureObj.pgrid))
                        this.powerScheme.current.setValue('gridValue', this.mostRecentMeasureObj.pgrid);

                    if (!isNaN(this.mostRecentMeasureObj.ae) && !isNaN(this.mostRecentMeasureObj.te))
                        this.powerScheme.current.setValue('socValue', (this.mostRecentMeasureObj.ae / this.mostRecentMeasureObj.te * 100));
                }

                this.setState({
                    underControl: this.mostRecentMeasureObj.statusafrr,
                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        // deviceActivated: {
                        //     code: 'A',
                        //     source: 'NGEN',
                        //     color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                        //     description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        // },
                        suhaStatus: {
                            code: 'S',
                            source: 'SUHA',
                            color: this.suhaStatuses.find(s => s.code === this.mostRecentMeasureObj.status)['color'],
                            description: 'Status: ' + this.suhaStatuses.find(s => s.code === this.mostRecentMeasureObj.status)['description']
                        }
                    }
                }, () => {
                    this.props.setPath(null, this.state.assetStatuses)
                });
            }
        }, this.PAGE_ID);
    }

    reinitPowerChartData() {
        this.powerChartCanAppendData = false;
        this.setState({
            powerChartIsLoading: true
        });

        this.PowerChartRef.current.chart.xAxis[0].setExtremes(undefined, undefined);

        restAPIget(`/measurements/raw/3/${this.powerChartShowIntervalOfMins}`, this.abortController.signal).then(result => {
            if (result.isOk) {
                if (result.data.length > 0) {
                    setInitialDataToGraphSeries(
                        result.data.map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    power: Number(d.p),
                                    pgrid: Number(d.pgrid),
                                    setpoint: Number(d.s),
                                    maxChargePower: Number(d.an),
                                    maxDischargePower: Number(d.ap),
                                    baseline: Number(d.b)
                                };
                            }
                        ),
                        ['power', 'pgrid', 'setpoint', 'maxChargePower', 'maxDischargePower', 'baseline'],
                        this.PowerChartRef.current.chart
                    );
                }
            }

            this.powerChartCanAppendData = this.powerChartShowIntervalOfMins <= 60;
            this.setState({
                powerChartIsLoading: false
            });
        });
    }

    reinitSocChartData() {
        this.socChartCanAppendData = false;
        this.setState({
            socChartIsLoading: true
        });

        this.SocChartRef.current.chart.xAxis[0].setExtremes(undefined, undefined);

        restAPIget(`/measurements/raw/3/${this.socChartShowIntervalOfMins}`, this.abortController.signal).then(result => {
            if (result.isOk) {
                if (result.data.length > 0) {
                    setInitialDataToGraphSeries(
                        result.data.map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    soc: Number(d.soc),
                                    toChargeEnergy: Number(d.te - d.ae),
                                    toDischargeEnergy: Number(d.ae),
                                };
                            }
                        ),
                        ['soc', 'toChargeEnergy', 'toDischargeEnergy'],
                        this.SocChartRef.current.chart
                    );
                }
            }

            this.socChartCanAppendData = this.socChartShowIntervalOfMins <= 60;
            this.setState({
                socChartIsLoading: false
            });
        });
    }

    toggleAssetAvailability(additionalValue) {
        restAPIpost(
            '/asset/3/status',
            { pw: "GESLO123", activated: !this.state.underControl }
        ).then(() => {
            Notify.info({ message: "Action sent, please wait few seconds to see if it works, the data is shown with delay!" });
        });
    }

    drawCurrentTimeOnGraphs(Now) {
        if (this._isMounted && this.PowerChartRef.current && this.PowerChartRef.current.chart) {
            this.PowerChartRef.current.chart.xAxis[0].removePlotLine('timeNow');

            if (this.powerChartShowIntervalOfMins <= 60) {
                this.powerChartMinExtremeTS = Now - this.powerChartShowIntervalOfMins * 60/*s*/ * 1000/*ms*/;

                if (!this.powerchartIsZoomed) {
                    this.PowerChartRef.current.chart.xAxis[0].setExtremes(undefined, Now + 5000);
                }

                this.PowerChartRef.current.chart.xAxis[0].addPlotLine(
                    getDefaultChartPlotline({
                        id: 'timeNow',
                        label: 'Now',
                        value: Now,
                    })
                );
            }
        }

        if (this._isMounted && this.SocChartRef.current && this.SocChartRef.current.chart) {
            this.SocChartRef.current.chart.xAxis[0].removePlotLine('timeNow');

            if (this.socChartShowIntervalOfMins <= 60) {
                this.socChartMinExtremeTS = Now - this.socChartShowIntervalOfMins * 60/*s*/ * 1000/*ms*/;

                if (!this.socchartIsZoomed) {
                    this.SocChartRef.current.chart.xAxis[0].setExtremes(undefined, Now + 5000);
                }

                this.SocChartRef.current.chart.xAxis[0].addPlotLine(
                    getDefaultChartPlotline({
                        id: 'timeNow',
                        label: 'Now',
                        value: Now,
                    })
                );
            }
        }
    }

    render() {
        let assetStatusesUI = Object.keys(this.state.assetStatuses).map((statusKey, statusIndex, statusArray) =>
            (
                <span key={statusKey}>
                    <span
                        style={{
                            backgroundColor: this.state.assetStatuses[statusKey].color,
                            padding: '3px 6px',
                            boxShadow: Depths.depth4,
                            cursor: 'default',
                            whiteSpace: 'nowrap'
                        }}
                    >{this.state.assetStatuses[statusKey].description} (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {this.state.assetStatuses[statusKey].source})</span>

                    {statusIndex !== statusArray.length - 1 && <>&nbsp;</>}
                </span>
            )
        );

        return (
            <>
                <IAccept ref={this.iAcceptReference} strictMode="true" onContinue={this.toggleAssetAvailability.bind(this)}></IAccept>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NBar>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ padding: '0 8px' }}>
                                        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Suha dashboard</span>
                                    </div>

                                    <div className="ms-Grid-col ms-xxl6 ms-hiddenXlDown" style={{ textAlign: "center", padding: '0' }}>
                                        {assetStatusesUI}
                                    </div>

                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ textAlign: "right", padding: '0 12px' }}>
                                        {this.SHOW_ACTIVATION_PLAN &&
                                            <CommandButton iconProps={{ iconName: 'SetAction' }} text="Asset actions" menuProps={{
                                                items: [
                                                    // {
                                                    //     key: 'enableDisable',
                                                    //     text: (<><span>{this.state.underControl ? "Disable AFRR" : "Enable AFRR"} </span> &nbsp;<span>(<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> NGEN)</span></>),
                                                    //     iconProps: { iconName: this.state.underControl === 'true' ? 'Blocked' : 'ReturnToSession' },
                                                    //     disabled: this.state.underControl === null,
                                                    //     onClick: () => {
                                                    //         this.iAcceptReference.current._showDialog();
                                                    //     }
                                                    // },

                                                    {
                                                        key: 'GoToActivationPlan',
                                                        text: (<><span>Activation plan</span></>),
                                                        iconProps: { iconName: 'ChartYAngle' },
                                                        onClick: () => {
                                                            this.props.history.push('/suha_activationplan');
                                                        }
                                                    }
                                                ]
                                            }} />
                                        }
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-hiddenXxlUp" style={{ textAlign: "left", padding: '0 8px' }}>
                                        {assetStatusesUI}
                                    </div>
                                </div>
                            </NBar>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl5 ms-xxl4 ms-xxxl3">
                            <NContainer
                                title="Power meter"
                                isLoading={this.state.powerMeterIsLoading}
                                style={{
                                    height: '400px'
                                }}
                            >
                                <BatPowerMeter
                                    measureUnit="KW"
                                    id="SuhaDasboardPowerMeter"
                                    ref={this.batPowerMeter}
                                />
                            </NContainer>
                        </div>
                        <div className={'ms-Grid-col ms-sm12 ' + (this.state.powerChartExpanded ? '' : ' ms-xl7 ms-xxl8 ms-xxxl9')}>
                            <NContainer
                                title="Power chart"
                                isLoading={this.state.powerChartIsLoading}
                                style={{
                                    height: this.state.powerChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'graphIsLive',
                                        type: 'customText',
                                        style: { color: ['NOW', '15M', '30M', '60M'].includes(this.state.powerChartGraphType) ? getBrandedColor('green') : getBrandedColor('red') },
                                        text: <><FontIcon iconName={['NOW', '15M', '30M', '60M'].includes(this.state.powerChartGraphType) ? 'Streaming' : 'StreamingOff'} style={{ fontSize: '1.3em', lineHeight: '1em', verticalAlign: 'middle', marginTop: '-6px' }} /></>
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        type: 'chooseOption',
                                        icon: "DateTime2",
                                        key: 'ChooseViewType',
                                        selectedOption: this.state.powerChartGraphType,
                                        options: [{
                                            key: 'NOW',
                                            text: 'Now',
                                            iconProps: {
                                                iconName: 'ReminderTime'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 2;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: 'NOW' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: 'TODAY',
                                            text: 'Today',
                                            iconProps: {
                                                iconName: 'GotoToday'
                                            },
                                            onClick: () => {
                                                let startOfDay = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').startOf('day');
                                                this.powerChartMinExtremeTS = 0;
                                                this.powerChartShowIntervalOfMins = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').diff(startOfDay, 'minutes');
                                                this.setState({ powerChartGraphType: 'TODAY' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: 'divider_1',
                                            itemType: ContextualMenuItemType.Divider
                                        },
                                        {
                                            key: '15M',
                                            text: 'Last 15 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 15;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '15M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '30M',
                                            text: 'Last 30 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {

                                                this.powerChartShowIntervalOfMins = 30;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '30M' });
                                                this.reinitPowerChartData();

                                            }
                                        },
                                        {
                                            key: '60M',
                                            text: 'Last hour',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 60;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '60M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '180M',
                                            text: 'Last 3 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 180;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '180M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '360M',
                                            text: 'Last 6 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 360;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '360M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '720M',
                                            text: 'Last 12 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 720;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '720M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        {
                                            key: '1440M',
                                            text: 'Last 24 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.powerChartShowIntervalOfMins = 1440;
                                                this.powerChartMinExtremeTS = 0;
                                                this.setState({ powerChartGraphType: '1440M' });
                                                this.reinitPowerChartData();
                                            }
                                        },
                                        ]
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        icon: this.state.powerChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.powerChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    powerChartExpanded: !(prevstate.powerChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.powerChartExpanded ? 655 : 355;
                                                this.PowerChartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('powerChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="powerChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.PowerChartRef}
                                        options={this.powerChartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl5 ms-xxl4 ms-xxxl3">
                            <NContainer
                                title="Battery capacity/SoC"
                                isLoading={this.state.socMeterIsLoading}
                                style={{
                                    height: '400px'
                                }}
                            >
                                <Battery measureUnit="KWh" id="suhaBattery" ref={this.batSocMeter}></Battery>
                            </NContainer>
                        </div>

                        <div className={'ms-Grid-col ms-sm12 ' + (this.state.socChartExpanded ? '' : ' ms-xl7 ms-xxl8 ms-xxxl9')}>
                            <NContainer
                                title="Battery SoC chart"
                                isLoading={this.state.socChartIsLoading}
                                style={{
                                    height: this.state.socChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'graphIsLive',
                                        type: 'customText',
                                        style: { color: ['NOW', '15M', '30M', '60M'].includes(this.state.socChartGraphType) ? getBrandedColor('green') : getBrandedColor('red') },
                                        textd: <><FontIcon iconName={['NOW', '15M', '30M', '60M'].includes(this.state.socChartGraphType) ? 'Streaming' : 'StreamingOff'} style={{ fontSize: '1.3em', lineHeight: '1em', verticalAlign: 'middle', marginTop: '-6px' }} /></>
                                    },
                                    {
                                        type: 'emptySpace'
                                    },

                                    {
                                        type: 'chooseOption',
                                        icon: "DateTime2",
                                        key: 'ChooseViewType',
                                        selectedOption: this.state.socChartGraphType,
                                        options: [{
                                            key: 'NOW',
                                            text: 'Now',
                                            iconProps: {
                                                iconName: 'ReminderTime'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 2;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: 'NOW' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: 'TODAY',
                                            text: 'Today',
                                            iconProps: {
                                                iconName: 'GotoToday'
                                            },
                                            onClick: () => {
                                                let startOfDay = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').startOf('day');
                                                this.socChartMinExtremeTS = 0;
                                                this.socChartShowIntervalOfMins = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').diff(startOfDay, 'minutes');
                                                this.setState({ socChartGraphType: 'TODAY' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: 'divider_1',
                                            itemType: ContextualMenuItemType.Divider
                                        },
                                        {
                                            key: '15M',
                                            text: 'Last 15 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 15;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '15M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: '30M',
                                            text: 'Last 30 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {

                                                this.socChartShowIntervalOfMins = 30;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '30M' });
                                                this.reinitSocChartData();

                                            }
                                        },
                                        {
                                            key: '60M',
                                            text: 'Last hour',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 60;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '60M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: '180M',
                                            text: 'Last 3 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 180;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '180M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: '360M',
                                            text: 'Last 6 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 360;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '360M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: '720M',
                                            text: 'Last 12 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 720;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '720M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        {
                                            key: '1440M',
                                            text: 'Last 24 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.socChartShowIntervalOfMins = 1440;
                                                this.socChartMinExtremeTS = 0;
                                                this.setState({ socChartGraphType: '1440M' });
                                                this.reinitSocChartData();
                                            }
                                        },
                                        ]
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        icon: this.state.socChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.socChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    socChartExpanded: !(prevstate.socChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.socChartExpanded ? 655 : 355;
                                                this.SocChartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('socChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="socChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.SocChartRef}
                                        options={this.socChartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl5 ms-xxl4 ms-xxxl3">
                            <NContainer
                                title="Energy scheme"
                                isLoading={this.state.powerSchemeIsLoading}
                                style={{
                                    height: '400px'
                                }}
                            >
                                <HouseEnergyScheme measureUnit="KW" id="suhaBatteryEnergySchem" ref={this.powerScheme}></HouseEnergyScheme>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-xl7 ms-xxl8 ms-xxxl9">
                            <NContainer
                                title="Energy chart"
                                style={{
                                    height: this.state.energyChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        disabled: true,
                                        icon: this.state.energyChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.energyChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    energyChartExpanded: !(prevstate.energyChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.energyChartExpanded ? 655 : 355;
                                                this.energyChartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('energyChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="energyChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.energyChartRef}
                                        options={this.state.EnergyChartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>
                </div >
            </>
        );
    }

}