import { Route, Switch, Redirect } from "react-router-dom";
import { SpinnerSize, Spinner, AnimationStyles } from "office-ui-fabric-react";
import React, { lazy, Suspense } from "react";

import { About } from "../../pages/about/about";
import { ActivationPlan } from "../../pages/activationPlan/activationPlan";
import { ActivationPlans } from "../../pages/activationPlan/activationPlans";
import { AssetHistory } from "../../pages/history/assetHistory";
import { generateUUID, PermissionModes, PermissionMode } from "../../shared/utils";
import { NContainer } from "../nContainer/nContainer";
import { NotAuthorised } from "../../pages/notAuthorised/notAuthorised";
import { NotFound } from "../../pages/notFound/notFound";
import { Reload } from "../../pages/reload/reload";
import { SBSDriveByDay } from "../../pages/sbs/driveByDay";
import { SBSDriveLive } from "../../pages/sbs/driveLive";
import { SBSDriveLiveQuarter } from "../../pages/sbs/driveLiveQuarter";
import { SBSHistoryDeviation } from "../../pages/sbs/historyDeviation";
import { SBSHistoryTape } from "../../pages/sbs/historyTape";
import { SBSHome } from "../../pages/sbs/home";
import { SBSPeakBy10Sec } from "../../pages/sbs/peakBy10Sec";
import { SBSScheduleUpload } from "../../pages/sbs/scheduleUpload";
import { ServerTime } from "../../pages/serverTime/serverTime";
import { SuhaDashboard } from "../../pages/dashboards/suhaDashboard";
import { TalumElectrolysis } from "../../pages/dashboards/talumElectrolysis";
import { TalumPower } from "../../pages/dashboards/talumPower";
import { WarningsAndErrors } from "../../pages/warningsAndErrors/warningsAndErrors";
import SharedData from "../../shared/sharedData";

const TetolDash1 = lazy(() => import("../../pages/dashboards/tetolDash1"));
const TetolDash2 = lazy(() => import("../../pages/dashboards/tetolDash2"));
const IskraDash = lazy(() => import("../../pages/dashboards/iskraDashboard"));

function PrivateRoute(props) {
    let authorized = false;
    let readable = PermissionMode === PermissionModes.optimistic;
    let writeable = PermissionMode === PermissionModes.optimistic;

    if (props.allowedWorkspaces && (props.allowedWorkspaces.includes(props.currentWorkspace) || props.allowedWorkspaces.includes("*"))) {
        authorized = true;

        if (props.permissionName) {
            let permissions = SharedData.deepGet("apiPermissions", props.permissionName, "permission") || "";

            // if its backend defined, override default values
            if (permissions) {
                readable = permissions.includes("r");
                writeable = permissions.includes("w");
            }
        }

        if (props.allowedWorkspaces.includes("*")) {
            readable = true;
        }
    }

    return authorized && readable ? (
        <Route
            key={generateUUID()}
            exact
            path={props.path}
            render={(routeProps) => {
                if (props.params) {
                    routeProps.match.params = props.params;
                }
                return (
                    <props.component
                        isWriteable={writeable}
                        isReadable={readable}
                        {...routeProps}
                        {...{ workspace: props.currentWorkspace }}
                        setPath={props.setPath}
                    />
                );
            }}
        />
    ) : (
        <Route key={generateUUID()} render={() => <NotAuthorised isReadable={readable} isAuthorized={authorized} setPath={props.setPath} />} />
    );
}

function LoadingRouteComponent(props) {
    props.setPath([
        { text: "App", key: "app" },
        { text: "Loading route", key: "loadingRoute", isCurrentItem: true },
    ]);
    return (
        <div className="ms-Grid" dir="ltr" style={{ ...AnimationStyles.fadeIn500 }}>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                    <NContainer title="Loading route ...">
                        <Spinner label="Loading route ..." size={SpinnerSize.large} labelPosition="bottom" />
                    </NContainer>
                </div>
            </div>
        </div>
    );
}

function generateRoutes(workspace, options) {
    const { routingProps } = options;
    switch (workspace) {
        case "root":
            return [
                <Route
                    key={generateUUID()}
                    exact
                    path="/time"
                    render={(props) => <ServerTime {...props} setPath={routingProps.setTopBarNavigation}></ServerTime>}
                />,
                <Route key={generateUUID()} exact path="/reload" render={(props) => <Reload {...props} setPath={routingProps.setTopBarNavigation}></Reload>} />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="*"
                    currentWorkspace={routingProps.workspace}
                    path="/about"
                    component={About}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        case "eg":
            return [
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="eg"
                    currentWorkspace={routingProps.workspace}
                    path="/suha_dashboard"
                    permissionName="asset3Measurements"
                    component={SuhaDashboard}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="eg"
                    currentWorkspace={routingProps.workspace}
                    permissionName="asset3ActivationPlan"
                    path="/suha_activationplan"
                    component={ActivationPlans}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="eg"
                    currentWorkspace={routingProps.workspace}
                    path="/suha_activationplan/:id"
                    permissionName="asset3ActivationPlan"
                    component={ActivationPlan}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="eg"
                    currentWorkspace={routingProps.workspace}
                    permissionName="asset3Measurements"
                    params={{ id: 3 }}
                    path="/assets/3/history"
                    component={AssetHistory}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        case "talum":
            return [
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="talum"
                    currentWorkspace={routingProps.workspace}
                    permissionName="asset2Measurements"
                    path="/talum_dashboard2"
                    component={TalumElectrolysis}
                    setPath={routingProps.setTopBarNavigation}
                />,

                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="talum"
                    currentWorkspace={routingProps.workspace}
                    permissionName="asset2Measurements"
                    path="/talum_dashboard"
                    component={TalumPower}
                    setPath={routingProps.setTopBarNavigation}
                />,

                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="talum"
                    currentWorkspace={routingProps.workspace}
                    permissionName="asset2Measurements"
                    params={{ id: 2 }}
                    path="/assets/2/history"
                    component={AssetHistory}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        case "tetol":
            return [
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="tetol"
                    permissionName="asset4Measurements"
                    currentWorkspace={routingProps.workspace}
                    path="/tetol_dashboard2"
                    component={TetolDash2}
                    setPath={routingProps.setTopBarNavigation}
                />,

                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="tetol"
                    permissionName="asset4Measurements"
                    currentWorkspace={routingProps.workspace}
                    path="/tetol_dashboard"
                    component={TetolDash1}
                    setPath={routingProps.setTopBarNavigation}
                />,

                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="tetol"
                    permissionName="asset4Measurements"
                    currentWorkspace={routingProps.workspace}
                    params={{ id: 4 }}
                    path="/assets/4/history"
                    component={AssetHistory}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        case "iskra":
            return [
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    permissionName="asset6Measurements"
                    allowedWorkspaces="iskra"
                    currentWorkspace={routingProps.workspace}
                    path="/iskra_dashboard"
                    component={IskraDash}
                    setPath={routingProps.setTopBarNavigation}
                />,

                <PrivateRoute
                    key={generateUUID()}
                    exact
                    permissionName="asset6Measurements"
                    allowedWorkspaces="iskra"
                    currentWorkspace={routingProps.workspace}
                    params={{ id: 6 }}
                    path="/assets/6/history"
                    component={AssetHistory}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        case "acroni":
            return [
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/home"
                    permissionName="sbs"
                    component={SBSHome}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/schedul_upload"
                    component={SBSScheduleUpload}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/drive/live"
                    component={SBSDriveLive}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/drive/live_quarter"
                    component={SBSDriveLiveQuarter}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/tape"
                    component={SBSHistoryTape}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/drive/byday"
                    component={SBSDriveByDay}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/deviation"
                    component={SBSHistoryDeviation}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/peak_by_10sec"
                    component={SBSPeakBy10Sec}
                    setPath={routingProps.setTopBarNavigation}
                />,
                <PrivateRoute
                    key={generateUUID()}
                    exact
                    allowedWorkspaces="acroni"
                    permissionName="sbs"
                    currentWorkspace={routingProps.workspace}
                    path="/sbs/warningsAndErrors"
                    component={WarningsAndErrors}
                    setPath={routingProps.setTopBarNavigation}
                />,
            ];

        default:
            return null;
    }
}

export function AppRouting(routingProps) {
    return (
        <Suspense fallback={<LoadingRouteComponent setPath={routingProps.setTopBarNavigation} />}>
            <Switch>
                <Redirect exact from="/" to="/about" />

                {generateRoutes("root", { routingProps })}
                {generateRoutes(routingProps.workspace, { routingProps })}

                <Route render={(props) => <NotFound {...props} setPath={routingProps.setTopBarNavigation}></NotFound>} />
            </Switch>
        </Suspense>
    );
}
