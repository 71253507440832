import React from 'react';

import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, TextField } from 'office-ui-fabric-react';


export class IAccept extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkText: '',
            isMatched: undefined,
            hidden: true
        }
    }


    _showDialog() {
        this.setState({ hidden: false, checkText: '', isMatched: undefined });
    }

    _closeDialog(iAmSure) {
        this.setState({ hidden: true });

        if (iAmSure) {
            this.props.onContinue();
        }
    }

    render() {

        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this._closeDialog.bind(this, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Are you sure?',
                    subText: this.props.strictMode ? "If you are sure, plese enter the word 'YES' in the field below and click on continue." : "If you are sure, plese enter the word 'yes' or 'Y' or 'YE' or 'YES' in the field below and click on continue."
                }}
                modalProps={{
                    isBlocking: true,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false,
                    styles: { main: { maxWidth: 450 } }
                }}
            >
                <TextField
                    label="Your statement"
                    type="text"
                    value={this.state.checkText}
                    onChange={evt => {
                        let isMatched = false;
                        if (this.props.strictMode) {
                            isMatched = (evt.target.value) === 'YES';
                        }
                        else {
                            isMatched = (evt.target.value).toUpperCase() === 'YES' || (evt.target.value) === 'Y' || (evt.target.value) === 'YE' || (evt.target.value) === 'YES';
                        }

                        this.setState({
                            checkText: evt.target.value,
                            isMatched: isMatched
                        })
                    }}
                    onKeyDown={evt => {
                        if (evt.key === 'Enter') {
                            if (this.state.isMatched) {
                                this._closeDialog(true);
                            }
                        }
                    }}
                />
                <DialogFooter>
                    <PrimaryButton disabled={!this.state.isMatched} onClick={this._closeDialog.bind(this, true)} text="Continue" />
                    <DefaultButton onClick={this._closeDialog.bind(this, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }
}