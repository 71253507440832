import React from 'react';
import ReactSVG from 'react-svg';

import houseenrgySchemeSrc from './houseEnergyScheme.svg';

export class HouseEnergyScheme extends React.Component {
    wholeElement = null;
    socElement = null;
    powerElement = null;
    gridElement = null;

    shouldComponentUpdate() {
        return false;
    }

    initElements() {
        if (!this.props.id) {
            console.error("Undefined 'id' property!");
            return;
        }

        this.wholeElement = document.getElementById(this.props.id);
        this.socElement = this.wholeElement.getElementsByClassName('socValue')[0];
        this.powerElement = this.wholeElement.getElementsByClassName('powerValue')[0];
        this.gridElement = this.wholeElement.getElementsByClassName('gridValue')[0];
        this.batSocElement = this.wholeElement.getElementsByClassName('batSoc')[0];
    }

    setValue(type, value) {
        if (!this.wholeElement) {
            // console.warn("Element not yet loaded");
            return;
        }

        switch (type) {

            case 'socValue':
                this.socElement.textContent = Math.round(value) + '%';

                let percent = parseInt(value.toFixed());
                if (percent > 100) {
                    percent = 100;
                }
                if (percent < 0) {
                    percent = 0;
                }

                percent += 100;
                this.batSocElement.style.strokeDashoffset = percent + 'px';

                break;

            case 'powerValue':
                this.powerElement.textContent = value.toFixed(2) + (this.props.measureUnit || 'MW');
                break;

            case 'gridValue':
                this.gridElement.textContent = value.toFixed(2) + (this.props.measureUnit || 'MW');
                break;

            default:
                console.error("Invalid type: " + type);
                break;
        }

    }

    render() {
        return (
            <div id={this.props.id}>
                <ReactSVG
                    beforeInjection={svg => {
                        svg.setAttribute('style', 'display: block;position: absolute;height: 75%;width: 85%;top: 80px; left: 10%;')
                    }}
                    src={houseenrgySchemeSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }} />
            </div>
        );
    }
}