import React from "react";
import { ActivityItem, Icon } from "office-ui-fabric-react";
import EventEmitter from "../../shared/EventEmitter";
import moment from "moment-timezone";
import { NContainer } from "../../components/nContainer/nContainer";
import SharedData from "../../shared/sharedData";

export class WarningsAndErrors extends React.Component {
    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Warnings and errors", key: "warningsAndErrors", isCurrentItem: true },
        ]);
        this.state = {
            isLoading: true,
            errors: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getErros();
        EventEmitter.subscribe("APPLICATION_WARNINGS", this.getErros.bind(this), "WARNINGSpAGE");
    }

    componentWillUnmount() {
        this._isMounted = false;
        EventEmitter.unsubscribe("APPLICATION_WARNINGS", "WARNINGSpAGE");
    }

    getErros() {
        let dataObj = SharedData.get("warningsAndErrors");
        if (dataObj) {
            let warnings = [];

            if (dataObj) {
                warnings = Object.entries(dataObj.data).reduce((returnArray, [key, value]) => {
                    let newArray = [];

                    if (key === "errors") {
                        value.forEach((errorMessage) => {
                            newArray.push({
                                key: Math.random(),
                                activityDescription: [
                                    <span key="severity" style={{ color: "orangered" }}>
                                        ERROR
                                    </span>,
                                    // <span key="separator" style={{ color: "orangered" }}>
                                    //     :
                                    // </span>,
                                    // <span key="code" style={{ color: "orangered" }}>
                                    //     ERROR
                                    // </span>,
                                ],
                                comments: [
                                    <span key="message" style={{ color: "orangered" }}>
                                        {errorMessage}
                                    </span>,
                                ],
                                activityIcon: <Icon style={{ color: "orangered" }} iconName="Error" />,
                                timeStamp: <span>{moment.tz(dataObj.ts * 1000, "Europe/Ljubljana").format("DD.MM.YYYY HH:mm:ss")}</span>,
                            });
                        });
                    } else {
                        newArray.push({
                            key: Math.random(),
                            activityDescription: [
                                <span key="severity" style={{ color: "orange" }}>
                                    WARNING
                                </span>,
                                // <span key="separator" style={{ color: "orangered" }}>
                                //     :
                                // </span>,
                                // <span key="code" style={{ color: "orangered" }}>
                                //     ERROR
                                // </span>,
                            ],
                            comments: [
                                <span key="message" style={{ color: "orange" }}>
                                    Measurements for "{key}" between "Enerdata" and "PLC" differ by {value} KWh!
                                </span>,
                            ],
                            activityIcon: <Icon style={{ color: "orange" }} iconName="Warning" />,
                            timeStamp: <span>{moment.tz(dataObj.ts * 1000, "Europe/Ljubljana").format("DD.MM.YYYY HH:mm:ss")}</span>,
                        });
                    }

                    return [...returnArray, ...newArray];
                }, []);
            }

            if (warnings.length > 0) {
                this.setState({
                    isLoading: false,
                    errors: warnings,
                });
            } else {
                this.setState({
                    isLoading: false,
                    errors: [
                        {
                            key: "no data",
                            activityDescription: [
                                <span key="title" style={{ color: "#5fba46", fontWeight: "bold" }}>
                                    There are no warnings and errors!
                                </span>,
                            ],
                            activityIcon: <Icon iconName={"Like"} style={{ color: "#5fba46" }} />,
                            timeStamp: <span>{moment.tz(dataObj.ts * 1000, "Europe/Ljubljana").format("DD.MM.YYYY HH:mm:ss")}</span>,
                        },
                    ],
                });
            }
        }
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer title="List of warnings and errors" isLoading={this.state.isLoading}>
                            <div style={{ overflowY: "scroll", maxHeight: "unset" }}>
                                {this.state.errors &&
                                    this.state.errors.map((item) => <ActivityItem {...item} key={item.key} style={{ marginBottom: "5px" }} />)}
                            </div>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
