import React from 'react';
import ReactSVG from 'react-svg';

import otherenrgySchemeSrc from './otherEnergyScheme.svg';



export class OtherEnergyScheme extends React.Component {
    wholeElement = null;
    basePowerElement = null;
    currentPowerElement = null;
    currentPAFRRElement = null;

    shouldComponentUpdate() {
        return false;
    }

    initElements() {
        if (!this.props.id){
            console.error("Undefined 'id' property!");
            return;
        }

        this.wholeElement = document.getElementById(this.props.id);
        this.basePowerElement = this.wholeElement.getElementsByClassName('basePowerValue')[0];
        this.currentPowerElement = this.wholeElement.getElementsByClassName('currentPowerValue')[0];
        this.currentPAFRRElement = this.wholeElement.getElementsByClassName('currentPowerPAFRRValue')[0];
    }

    setValue(type, value) {

        if (!this.wholeElement) {
            // console.warn("Element not yet loaded");
            return;
        }

        switch (type) {

            case 'basepower':
                this.basePowerElement.textContent = (value / 1000).toFixed(3) + 'MW';
                break;

            case 'currentpower':
                this.currentPowerElement.textContent = (value / 1000).toFixed(3) + 'MW';
                break;

            case 'pafrr':
                this.currentPAFRRElement.textContent = (value / 1000).toFixed(3) + 'MW';
                break;

            default:
                console.error("Invalid type: " + type);
                break;
        }

    }

    render() {
        return (
            <div id={this.props.id}>
                {/* <img src={enrgySchemeSrc} ></img> */}
                <ReactSVG
                    beforeInjection={svg => {
                        svg.setAttribute('style', 'display: block;position: absolute;height: 75%;width: 80%;top: 80px; left: 10%;')
                    }}
                    src={otherenrgySchemeSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }} />
            </div>
        );
    }


}
