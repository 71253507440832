import { Depths } from "@uifabric/fluent-theme";
import { Nav } from "office-ui-fabric-react/lib/Nav";
import { withRouter } from "react-router-dom";
import React from "react";

import { AboutUser } from "./aboutUser";
import { getCdnImage, getSubdomain, hasReadAccess } from "../../shared/utils";

export function showMenu() {
    document.getElementById("nav-menu").classList.add("isShown");
    document.getElementById("nav-background").classList.add("isShown");

    document.getElementById("nav-content").classList.remove("menuHidden");
    document.getElementById("nav-menu").classList.remove("menuHidden");

    document.getElementById("nav-hide").classList.remove("isHidden");
    document.getElementById("nav-show").classList.remove("isShown");

    window.dispatchEvent(new Event("resize"));
}

export function hideMenu() {
    document.getElementById("nav-menu").classList.remove("isShown");
    document.getElementById("nav-background").classList.remove("isShown");
    document.getElementById("nav-content").classList.add("menuHidden");
    document.getElementById("nav-menu").classList.add("menuHidden");
    document.getElementById("nav-hide").classList.add("isHidden");
    document.getElementById("nav-show").classList.add("isShown");

    window.dispatchEvent(new Event("resize"));
}

function hideMobileMenu() {
    document.getElementById("nav-menu").classList.remove("isShown");
    document.getElementById("nav-background").classList.remove("isShown");
}

function generateMenu(workspace, history, expandedKeys) {
    let theMenu = [
        {
            name: "NCC",
            links: [
                {
                    name: "About",
                    url: "/about",
                    key: "/about",
                    icon: "HomeGroup",
                },
            ],
        },
    ];
    switch (workspace.toUpperCase()) {
        case "EG":
            theMenu.push({
                name: "SUHA storage #1",
                links: [
                    {
                        name: "Dashboard",
                        url: "/suha_dashboard",
                        key: "/suha_dashboard",
                        icon: "StackedLineChart",
                        permission: "asset3Measurements",
                    },
                    {
                        name: "Activation plan",
                        url: "/suha_activationplan",
                        key: "/suha_activationplan",
                        icon: "TimelineProgress",
                        permission: "asset3ActivationPlan",
                    },
                    {
                        name: "History",
                        url: "/assets/3/history",
                        key: "/assets/3/history",
                        icon: "OfflineStorage",
                        permission: "asset3Measurements",
                    },
                ].filter((l) => hasReadAccess(l.permission)),
            });
            break;

        case "TALUM":
            theMenu.push({
                name: "TALUM",
                links: [
                    {
                        name: "Dashboard #1",
                        url: "/talum_dashboard",
                        key: "/talum_dashboard",
                        icon: "StackedLineChart",
                    },
                    {
                        name: "Dashboard #2",
                        url: "/talum_dashboard2",
                        key: "/talum_dashboard2",
                        icon: "StackedLineChart",
                    },
                    {
                        name: "History",
                        url: "/assets/2/history",
                        key: "/assets/2/history",
                        icon: "OfflineStorage",
                    },
                ],
            });
            break;

        case "ISKRA":
            theMenu.push({
                name: "ISKRA",
                links: [
                    {
                        name: "Dashboard #1",
                        url: "/iskra_dashboard",
                        key: "/iskra_dashboard",
                        icon: "StackedLineChart",
                    },
                    {
                        name: "History",
                        url: "/assets/6/history",
                        key: "/assets/6/history",
                        icon: "OfflineStorage",
                    },
                ],
            });
            break;

        case "TETOL":
            theMenu.push({
                name: "TETOL",
                links: [
                    {
                        name: "Dashboard #1",
                        url: "/tetol_dashboard",
                        key: "/tetol_dashboard",
                        icon: "StackedLineChart",
                    },
                    {
                        name: "Dashboard #2",
                        url: "/tetol_dashboard2",
                        key: "/tetol_dashboard2",
                        icon: "StackedLineChart",
                    },
                    {
                        name: "History",
                        url: "/assets/4/history",
                        key: "/assets/4/history",
                        icon: "OfflineStorage",
                    },
                ],
            });
            break;

        case "ACRONI":
            if (hasReadAccess("sbs")) {
                theMenu.push({
                    name: "SIJ GROUP",
                    links: [
                        {
                            name: "Home",
                            url: "/sbs/home",
                            key: "/sbs/home",
                            icon: "Home",
                        },
                        {
                            name: "Upload schedule",
                            url: "/sbs/schedul_upload",
                            key: "/sbs/schedul_upload",
                            icon: "Upload",
                        },
                        {
                            name: "Warnings and errors",
                            url: "/sbs/warningsAndErrors",
                            key: "/sbs/warningsAndErrors",
                            icon: "Warning",
                        },
                    ],
                });

                theMenu.push({
                    name: "SIJ GROUP SBS",
                    links: [
                        {
                            name: "Drive (LIVE)",
                            url: "/sbs/drive/live",
                            key: "/sbs/drive/live",
                            icon: "MapDirections",
                        },
                        {
                            name: "Drive (LIVE quarter)",
                            url: "/sbs/drive/live_quarter",
                            key: "/sbs/drive/live_quarter",
                            icon: "MapDirections",
                        },
                        {
                            name: "Drive (by day)",
                            url: "/sbs/drive/byday",
                            key: "/sbs/drive/byday",
                            icon: "BIDashboard",
                        },
                        {
                            name: "TAPE",
                            url: "/sbs/tape",
                            key: "/sbs/tape",
                            icon: "ChartYAngle",
                        },
                        // {
                        //     name: "Deviation",
                        //     url: "/sbs/deviation",
                        //     key: "/sbs/deviation",
                        //     icon: "ChartYAngle",
                        // },
                    ],
                });

                theMenu.push({
                    name: "ACRONI PEAK",
                    links: [
                        {
                            name: "Peak (LIVE)",
                            url: "/sbs/peak_by_10sec",
                            key: "/sbs/peak_by_10sec",
                            icon: "ChartYAngle",
                        },
                    ],
                });
            }

            break;
        default:
            //do nothing;
            break;
    }

    return theMenu;
}

export function SideMenu(props) {
    let expandedKeys = [];
    let logoSrc = null;
    try {
        logoSrc = getCdnImage(`./partnerLogo/${getSubdomain()}.png`);
    } catch (error) {
        logoSrc = getCdnImage("./partnerLogo/ngen.png");
    }

    const FabricNav = withRouter(({ history }) => {
        return (
            <div
                id="nav-menu"
                className="nav-menu"
                style={{
                    boxShadow: Depths.depth8,
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                        height: "53px",
                        lineHeight: "53px",
                    }}
                >
                    <img
                        alt="NGEN logo"
                        src={logoSrc}
                        style={{ maxWidth: "calc(100% - 30px)", margin: "6px", maxHeight: "100%", verticalAlign: "middle" }}
                    ></img>
                </div>
                <AboutUser username={props.username} logOut={props.logOut} />

                <Nav
                    selectedKey={history.location.pathname}
                    className="noSelect"
                    styles={{
                        chevronButton: {
                            left: "unset",
                            right: "4px",
                            backgroundColor: "unset",

                            // borderTop: "1px solid #ffffff12",
                            // borderBottom: "unset",
                            borderImageSource: "linear-gradient(to right, #ffffff12, transparent)",
                            borderImageSlice: "4%",

                            letterSpacing: "3px",
                            marginLeft: "3px",
                            fontWeight: 300,
                            fontSize: "14px",
                        },
                        groupContent: {
                            marginBottom: "20px",
                            // borderBottom: "1px solid #ffffff12",
                        },
                        group: {
                            // borderTop: "1px solid #ffffff12",
                            // borderImageSource: "linear-gradient(to right, #ffffff12, transparent)",
                            // borderImageSlice: "1%",
                        },
                    }}
                    // onRenderGroupHeader={(group, defaultRender) => {
                    //     // group.collapseByDefault

                    //     return defaultRender(group);
                    //     // return (
                    //     //     <p
                    //     //         style={{
                    //     //             color: "#ffffff",
                    //     //             borderBottom: "1px solid #ffffff12",
                    //     //             textAlign: "left",
                    //     //             padding: "0px 10px 6px",
                    //     //             marginBottom: "0px",
                    //     //             // lineHeight: '30px',
                    //     //             // fontWeight: 'bold',
                    //     //             letterSpacing: "3px",
                    //     //             fontWeight: 300,
                    //     //             // fontSize: '1.2em'
                    //     //         }}
                    //     //     >
                    //     //         {group.name}
                    //     //     </p>
                    //     // );
                    // }}
                    onLinkClick={(event, element) => {
                        event.preventDefault();
                        history.push(element.url);
                        hideMobileMenu();
                    }}
                    onLinkExpandClick={(event, element) => {
                        if (expandedKeys.includes(element.key)) {
                            expandedKeys = expandedKeys.filter((k) => k !== element.key);
                        } else {
                            expandedKeys.push(element.key);
                        }
                    }}
                    groups={generateMenu(props.workspace, history, expandedKeys)}
                />
            </div>
        );
    });

    return <FabricNav />;
}
