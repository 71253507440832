import React from 'react';

import { NContainer } from '../../components/nContainer/nContainer';
import { NFilePicker } from '../../components/nFilePicker/nFilePicker';
import { restAPIpostForm } from '../../shared/utils';

export class SBSScheduleUpload extends React.Component {
    constructor(props) {
        super(props);

        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked");
                }
            },
            { text: 'Upload schedule', key: 'uploadSchedule', isCurrentItem: true }
        ]);

        this.dropzoneRef = React.createRef();

        this.state = {

        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    uploadFile() {
        let formData = new FormData();
        formData.append('file', this.state.files[0]);

        console.log(formData);

        restAPIpostForm('/schedule', formData, { route: '' }).then(result => {
            console.log(result);
        })
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title="Upload schedule"
                            rightItems={[
                                {
                                    type: 'actionButton',
                                    key: 'uploadButton',
                                    text: 'Upload',
                                    icon: 'Upload',
                                    disabled: !this.state.files,
                                    onClick: this.uploadFile.bind(this)
                                }
                            ]}
                        >
                            <NFilePicker
                                accept=".xml"
                                text="Drag and drop your schedule .xml file, or click to select"
                                onContinue={(files) => { this.setState({ files: files }) }}
                            />
                        </NContainer>
                    </div>
                </div>
            </div>
        )
    }
}