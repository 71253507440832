import { BrowserRouter } from "react-router-dom";
import * as moment from "moment";
import React from "react";
import ReconnectingWebSocket from "reconnectingwebsocket";

import { AppContext } from "./shared/contexts";
import { AppRouting } from "./components/routing/appRouting";
import { InitialLoading } from "./pages/initialLoading/initialLoading";
import { Login } from "./pages/login/login";
import { restAPIget, getWsProtocol } from "./shared/utils";
import { SideMenu, hideMenu } from "./components/sideMenu/sideMenu";
import { TopBar } from "./components/topbar/topbar";
import EventEmitter from "./shared/EventEmitter";
import SharedData from "./shared/sharedData";

import "./App.css";

window.moment = moment; // required for highcharts graphs



export class App extends React.Component {
    constructor(props) {
        super(props);

        this.app = this.context;

        this.topBarRef = React.createRef();

        this.subscribedTo = ["SYSTEM"];
        // this.subscribedTo = [];
        this.subscribersAdded = false;

        this.state = {
            workspace: undefined,
            isLogged: false,
            isInitialLoaded: false,
            tryToAutoLogin: true,
        };

        this.isProduction = process.env.REACT_APP_IS_PRODUCTION === "true" ? true : false;
        this.isBeta = process.env.REACT_APP_IS_BETA === "true" ? true : false;
    }

    setTopBarNavigation = (newBreads, newStatuses) => {
        setTimeout(() => {
            if (this.topBarRef.current) {
                this.topBarRef.current.setNavigation(newBreads, newStatuses);
            }
        });
    };

    initWebsocket() {
         this.WebSocky = new ReconnectingWebSocket(getWsProtocol() + process.env.REACT_APP_WS_ENDPOINT);

       // this.WebSocky = new ReconnectingWebSocket("wss://moj-api.ngen.si/ws/");

        this.WebSocky.onopen = (status) => {
            if (this.WebSocky.readyState === 1 && this.subscribedTo.length > 0) {
                this.WebSocky.send(
                    JSON.stringify({
                        // type: 'action',
                        // name: 'cleanSubscribeTo',
                        // value: this.subscribedTo

                        command: "subscribe",
                        channel: this.subscribedTo,
                    })
                );

                this.setState({
                    wsConnected: true,
                    wsConnectTime: Date.now(),
                });
            }
            if (!this.subscribersAdded) {
                EventEmitter.subscribe(
                    "subscribeTo",
                    (channels) => {
                        if (this.WebSocky.readyState === 1) {
                            this.WebSocky.send(
                                JSON.stringify({
                                    // type: 'action',
                                    // name: 'subscribeTo',
                                    // value: channels
                                    command: "subscribe",
                                    channel: channels,
                                })
                            );
                        }
                        channels.forEach((channel) => {
                            if (!this.subscribedTo.some((s) => s === channel)) {
                                this.subscribedTo.push(channel);
                            }
                        });
                    },
                    undefined,
                    undefined
                );

                EventEmitter.subscribe(
                    "unsubscribeFrom",
                    (channels) => {
                        if (this.WebSocky.readyState === 1) {
                            this.WebSocky.send(
                                JSON.stringify({
                                    // type: 'action',
                                    // name: 'unsubscribeFrom',
                                    // value: channels
                                    command: "unsubscribe",
                                    channel: channels,
                                })
                            );
                        }
                        this.subscribedTo = this.subscribedTo.filter((s) => !channels.includes(s));
                    },
                    undefined,
                    undefined
                );
            }

            this.subscribersAdded = true;
        };

        this.WebSocky.onmessage = (msg) => {
            let obj = JSON.parse(msg.data);
            // if (obj.type === 'property') {
            //     EventEmitter.dispatch(obj.channel + '/' + obj.name, obj.value);
            // }
            if (obj.property) {
                EventEmitter.dispatch(obj.channel + "/" + obj.property, obj.data);
            }
        };

        this.WebSocky.onerror = (msg) => {
            this.setState({
                wsConnected: false,
                wsDisconnectTime: Date.now(),
            });
        };

        this.WebSocky.onclose = () => {
            // currently this is used for all values data are defined as property, the most famous use case:
            // when the WS connection fails, we want to set the user count to empty, and other count of errors and warnings as well

            // EventEmitter.dispatchTypes('property', '');

            this.setState({
                wsConnected: false,
                wsDisconnectTime: Date.now(),
            });
        };
    }

    // componentDidMount() {
    //     // const lastToken = localStorage.getItem('ncc_token');

    //     // if (lastToken && lastToken.startsWith('ncc_token_186516487241_')) {
    //     //     this.setState({
    //     //         isLogged: true,
    //     //         workspace: lastToken.split('_').slice(-1)[0]
    //     //     })
    //     // }
    // }

    timedOutVerify() {
        setTimeout(() => {
            this.verifyIfImLoggedIn();
        }, 2 * 60 * 1000);
    }

    verifyIfImLoggedIn() {
        restAPIget('/whoami', undefined, { route: '' }).then(result => {
            if (result.isOk && result.data.status === 'success') {
                this.checkForErrors();
                this.timedOutVerify();
            }
            else {
                SharedData.delete("whoami");
                this.setState({
                    isLogged: false,
                    workspace: undefined,
                    username: undefined,
                    tryToAutoLogin: false,
                });
            }
        });
    }

    checkForErrors() {
        if (this.state.workspace !== "acroni") {
            return;
        }

        restAPIget("/measurements/errorcheck", undefined, { route: "" }).then((result) => {
            if (result.isOk) {
                let obj = { ts: SharedData.get("timestamp"), data: result.data };
                SharedData.set("warningsAndErrors", obj);
                EventEmitter.dispatch("APPLICATION_WARNINGS", obj);
            }
        });
    }

    render() {
        return (
            <>
                {!this.state.isLogged && (
                    <Login
                        autoLogin={this.state.tryToAutoLogin}
                        onContinue={(whoamiResult) => {
                            SharedData.set("whoami", whoamiResult);
                            SharedData.set(
                                "wsChannels",
                                Object.keys(whoamiResult.availableWSChannels).reduce((pv, wsChannelKey) => {
                                    let tempSelectedChannel = whoamiResult.availableWSChannels[wsChannelKey];
                                    pv[tempSelectedChannel.channelName] = {
                                        channelId: wsChannelKey,
                                        ...tempSelectedChannel,
                                    };
                                    return pv;
                                }, {})
                            );

                            SharedData.set("apiPermissions",
                                whoamiResult.availableInternalAPIs.reduce((prevValue, currValue) => {
                                    prevValue[currValue.permissionName] = currValue;
                                    return prevValue;
                                }, {})
                            )

                            this.setState({ isLogged: true, workspace: whoamiResult.company.workspace, username: whoamiResult.user.name });

                            this.initWebsocket();
                            this.timedOutVerify();
                            this.checkForErrors();
                        }}
                    />
                )}

                {this.state.isLogged && !this.state.isInitialLoaded && (
                    <InitialLoading
                        onContinue={function () {
                            this.setState({
                                isInitialLoaded: true,
                            });
                        }.bind(this)}
                    />
                )}

                {this.state.isLogged && this.state.isInitialLoaded && (
                    <BrowserRouter>
                        <SideMenu
                            workspace={this.state.workspace}
                            username={this.state.username}
                            logOut={() => {
                                restAPIget("/logout", undefined, { route: "" }).then((result) => {
                                    SharedData.delete("whoami");
                                    this.setState({
                                        isLogged: false,
                                        workspace: undefined,
                                        username: undefined,
                                        tryToAutoLogin: false,
                                    });
                                });
                            }}
                        />

                        <div id="nav-background" className="nav-background" onClick={hideMenu}></div>
                        <div id="nav-content" className="nav-content">
                            <AppContext.Provider
                                value={{
                                    wsConnected: this.state.wsConnected,
                                    wsConnectTime: this.state.wsConnectTime,
                                    wsDisconnectTime: this.state.wsDisconnectTime,
                                }}
                            >
                                <TopBar ref={this.topBarRef}></TopBar>
                                <AppRouting
                                    workspace={this.state.workspace}
                                    setTopBarNavigation={this.setTopBarNavigation}
                                />

                            </AppContext.Provider>
                        </div>

                        {!this.isProduction && (
                            <div
                                style={{
                                    color: "orangered",
                                    // opacity: 0.5,
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    position: "fixed",
                                    // width: '50hw',
                                    // height: '200px',
                                    margin: 0,
                                    zIndex: 1000002,
                                    right: 0,
                                    bottom: "1%",
                                    pointerEvents: "none",
                                }}
                                className="opacity_blink"
                            >
                                <p style={{ background: "linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7))", margin: 0, padding: "0 10px" }}>
                                    DEVELOPMENT EDITION
                                </p>
                            </div>
                        )}

                        {this.isProduction && this.isBeta && (
                            <div
                                style={{
                                    color: "orangered",
                                    // opacity: 0.5,
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    position: "fixed",
                                    // width: '50hw',
                                    // height: '200px',
                                    margin: 0,
                                    zIndex: 1000002,
                                    right: 0,
                                    bottom: "1%",
                                    pointerEvents: "none",
                                }}
                                className="opacity_blink"
                            >
                                <p style={{ background: "linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7))", margin: 0, padding: "0 10px" }}>
                                    BETA EDITION
                                </p>
                            </div>
                        )}
                    </BrowserRouter>
                )}
            </>
        );
    }
}
