import moment from "moment-timezone";

import SharedData from "./sharedData";

export function getCurrentSloDateTime() {
    return moment().tz("Europe/Ljubljana");
}

export function getCurrentSloDateTimeFormatted(format) {
    return moment()
        .tz("Europe/Ljubljana")
        .format(format ? format : "DD.MM.YYYY HH:mm:ss");
}

export function getUtcFormattedDate(date, hour, min) {
    if (date && hour && min) {
        return moment
            .tz(date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2), "Europe/Ljubljana")
            .set({ hour: hour, minute: min, second: 0, millisecond: 0 })
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
    } else return undefined;
}

export function getUtcFormattedDateTime(date, time) {
    try {
        let result = moment.tz(date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2), "Europe/Ljubljana");
        if (time) {
            let timeParts = time.split(":");
            result.set({ hour: timeParts[0], minute: timeParts[1], second: timeParts[2], millisecond: 0 });
        } else {
            result.set({
                hour: ("0" + date.getHours()).slice(-2),
                minute: ("0" + date.getMinutes()).slice(-2),
                second: ("0" + date.getSeconds()).slice(-2),
                millisecond: 0,
            });
        }
        return result.utc().format("YYYY-MM-DD HH:mm:ss");
    } catch (error) {
        throw new Error("Failed to parse date and time!");
    }
}

export function getUtcMoment(date, time) {
    try {
        let result = moment.tz(date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2), "Europe/Ljubljana");
        if (time) {
            let timeParts = time.split(":");
            result.set({ hour: timeParts[0], minute: timeParts[1], second: timeParts[2], millisecond: 0 });
        } else {
            result.set({
                hour: ("0" + date.getHours()).slice(-2),
                minute: ("0" + date.getMinutes()).slice(-2),
                second: ("0" + date.getSeconds()).slice(-2),
                millisecond: 0,
            });
        }
        return result.utc();
    } catch (error) {
        console.log(date, time);
        throw new Error("Failed to parse date and time!");
    }
}

export function getFormattedDateTime(value, format) {
    if (value) {
        return moment(value)
            .tz("Europe/Ljubljana")
            .format(format ? format : "DD.MM.YYYY HH:mm:ss");
    } else {
        return undefined;
    }
}

export function getLocalDateTime(value) {
    if (!value) return undefined;
    else {
        let result = moment(value).tz("Europe/Ljubljana").toDate();
        return result;
    }
}

export async function restAPIget(path, signal, options) {
    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    let query =
        options && options.params
            ? Object.keys(options.params)
                .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(options.params[k]))
                .join("&")
            : undefined;

    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path + (query ? "?" + query : ""), {
        method: "GET",
        signal: signal,
        credentials: "include",
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("HTTP status " + res.status);
            }
            return res.json();
        })
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

/**
 * @deprecated Use restAPIpostAsJson
 */
export async function restAPIpost(path, body, forceRewrite, options) {
    const searchParams = Object.keys(body)
        .filter((key) => {
            return body[key] !== undefined && body[key] !== null;
        })
        .map((key) => {
            let rewriteDateKeys = ["ramp_up_start", "ramp_up_end", "ramp_down_start", "ramp_down_end", "created_at", "updated_at"];

            let x = body[key];

            if (forceRewrite && rewriteDateKeys.includes(key)) {
                x = x.replace("T", " ").replace("+00:00", "");
            }

            return encodeURIComponent(key) + "=" + encodeURIComponent(x);
        })
        .join("&");

    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            // 'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: searchParams,
        // body: body
        credentials: "include",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

export async function restAPIpostAsJson(path, body, options) {
    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        credentials: "include",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

export async function restAPIputAsJson(path, body, options) {
    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        credentials: "include",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

/**
 * @param  {String} path eq: /documents
 * @param  {FormData} formData A FormData object with keys and values
 * @param  {[Object]} options A object with possible options: [route]
 */
export async function restAPIpostForm(path, formData, options) {
    console.log(options);
    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "POST",
        mode: "cors",
        headers: {
            // 'Accept': 'multipart/form-data',
            // 'Content-Type': 'multipart/form-data'
        },
        body: formData,
        credentials: "include",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

export async function restAPIput(path, body, forceRewrite, options) {
    const searchParams = Object.keys(body)
        .filter((key) => {
            return body[key] !== undefined && body[key] !== null;
        })
        .map((key) => {
            let rewriteDateKeys = ["ramp_up_start", "ramp_up_end", "ramp_down_start", "ramp_down_end", "created_at", "updated_at"];

            let x = body[key];

            if (forceRewrite && rewriteDateKeys.includes(key)) {
                x = x.replace("T", " ").replace("+00:00", "");
            }

            return encodeURIComponent(key) + "=" + encodeURIComponent(x);
        })
        .join("&");

    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "PUT",
        mode: "cors",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            // 'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: searchParams,
        credentials: "include",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

export async function restAPIdelete(path, body, options) {
    let params = null;

    if (body) {
        params = Object.keys(body)
            .filter((key) => {
                return body[key] !== undefined && body[key] !== null;
            })
            .map((key) => {
                let x = body[key];
                return encodeURIComponent(key) + "=" + encodeURIComponent(x);
            })
            .join("&");
    }

    let route = options && options.hasOwnProperty("route") ? options.route : "/api";
    return await fetch(getRestProtocol() + process.env.REACT_APP_HTTP_ENDPOINT + route + path, {
        method: "DELETE",
        //  mode: 'cors',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            // 'Content-Type': 'text/plain;charset=UTF-8'
        },
        credentials: "include",
        body: params,
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return { data: result, isOk: true };
            },
            (error) => {
                return { error: error, isOk: false };
            }
        );
}

export function getHours() {
    return [
        { key: "00", text: "00" },
        { key: "01", text: "01" },
        { key: "02", text: "02" },
        { key: "03", text: "03" },
        { key: "04", text: "04" },
        { key: "05", text: "05" },
        { key: "06", text: "06" },
        { key: "07", text: "07" },
        { key: "08", text: "08" },
        { key: "09", text: "09" },
        { key: "10", text: "10" },
        { key: "11", text: "11" },
        { key: "12", text: "12" },
        { key: "13", text: "13" },
        { key: "14", text: "14" },
        { key: "15", text: "15" },
        { key: "16", text: "16" },
        { key: "17", text: "17" },
        { key: "18", text: "18" },
        { key: "19", text: "19" },
        { key: "20", text: "20" },
        { key: "21", text: "21" },
        { key: "22", text: "22" },
        { key: "23", text: "23" },
    ];
}

export function getMinutes() {
    return [
        { key: "00", text: "00" },
        { key: "01", text: "01" },
        { key: "02", text: "02" },
        { key: "03", text: "03" },
        { key: "04", text: "04" },
        { key: "05", text: "05" },
        { key: "06", text: "06" },
        { key: "07", text: "07" },
        { key: "08", text: "08" },
        { key: "09", text: "09" },
        { key: "10", text: "10" },
        { key: "11", text: "11" },
        { key: "12", text: "12" },
        { key: "13", text: "13" },
        { key: "14", text: "14" },
        { key: "15", text: "15" },
        { key: "16", text: "16" },
        { key: "17", text: "17" },
        { key: "18", text: "18" },
        { key: "19", text: "19" },
        { key: "20", text: "20" },
        { key: "21", text: "21" },
        { key: "22", text: "22" },
        { key: "23", text: "23" },
        { key: "24", text: "24" },
        { key: "25", text: "25" },
        { key: "26", text: "26" },
        { key: "27", text: "27" },
        { key: "28", text: "28" },
        { key: "29", text: "29" },
        { key: "30", text: "30" },
        { key: "31", text: "31" },
        { key: "32", text: "32" },
        { key: "33", text: "33" },
        { key: "34", text: "34" },
        { key: "35", text: "35" },
        { key: "36", text: "36" },
        { key: "37", text: "37" },
        { key: "38", text: "38" },
        { key: "39", text: "39" },
        { key: "40", text: "40" },
        { key: "41", text: "41" },
        { key: "42", text: "42" },
        { key: "43", text: "43" },
        { key: "44", text: "44" },
        { key: "45", text: "45" },
        { key: "46", text: "46" },
        { key: "47", text: "47" },
        { key: "48", text: "48" },
        { key: "49", text: "49" },
        { key: "50", text: "50" },
        { key: "51", text: "51" },
        { key: "52", text: "52" },
        { key: "53", text: "53" },
        { key: "54", text: "54" },
        { key: "55", text: "55" },
        { key: "56", text: "56" },
        { key: "57", text: "57" },
        { key: "58", text: "58" },
        { key: "59", text: "59" },
    ];
}

export function getDayPickerSettings() {
    return {
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

        shortDays: ["S", "M", "T", "W", "T", "F", "S"],

        goToToday: "Go to today",
        prevMonthAriaLabel: "Go to previous month",
        nextMonthAriaLabel: "Go to next month",
        prevYearAriaLabel: "Go to previous year",
        nextYearAriaLabel: "Go to next year",
        closeButtonAriaLabel: "Close date picker",
    };
}

export function _datepicker_formatDate(date) {
    // return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    return moment(date).format("DD.MM.YYYY");
}

export function _datepicker_formatMonth(date) {
    // return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    return moment(date).format("MMM. YYYY");
}

export function _datepicker_parseDateFromString(stateValue, newValue) {
    const date = stateValue || new Date();
    const values = (newValue || "").trim().split(".");
    const day = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
    const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
    let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();

    return new Date(year, month, day);
}

export function _getInDepthValue(obj, key, defaultValue) {
    return key.split(".").reduce(function (o, x) {
        return typeof o == "undefined" || o === null ? defaultValue || o : o[x];
    }, obj);
}

export function getRandomColor(h_range = [0, 360], s_range = [50, 100], l_range = [50, 70], a_range = [1, 1]) {
    const getRandomNumber = function (low, high) {
        var r = Math.floor(Math.random() * (high - low + 1)) + low;
        return r;
    };

    const hue = getRandomNumber(h_range[0], h_range[1]);
    const saturation = getRandomNumber(s_range[0], s_range[1]);
    const lightness = getRandomNumber(l_range[0], l_range[1]);
    const alpha = getRandomNumber(a_range[0] * 100, a_range[1] * 100) / 100;

    return `hsl(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
}

export function convertSecondsToString(noOfSeconds, forceShow = false) {
    var days = Math.floor(noOfSeconds / (3600 * 24));
    var hours = Math.floor((noOfSeconds - days * 24 * 60 * 60) / 3600);
    var minutes = Math.floor((noOfSeconds - days * 24 * 3600 - hours * 3600) / 60);
    var seconds = noOfSeconds - days * 24 * 3600 - hours * 3600 - minutes * 60;

    let result = "";
    if (days || forceShow) {
        result += days + "d ";

        if (days >= 1000) {
            return result.trim();
        }
    }
    if (hours || forceShow) {
        result += hours + "h ";

        if (days >= 10) {
            return result.trim();
        }
    }
    if (minutes || forceShow) {
        result += minutes + "m ";
        if (days >= 1) {
            return result.trim();
        }
    }
    if (seconds || forceShow) {
        result += Math.ceil(seconds) + "s ";
    }
    return result.trim();
}

export function convertStringToSeconds(theStringPeriod) {
    let seconds = 0;
    let something = theStringPeriod.split(" ");
    something.forEach((period) => {
        let numericValue = 0;
        let prefix = "";

        if (period.length > 1) {
            prefix = period[period.length - 1].toLowerCase();
            numericValue = parseInt(period.substring(0, period.length - 1));
        } else {
            return 0;
        }

        switch (prefix) {
            case "s":
                seconds += numericValue;
                break;
            case "m":
                seconds += Math.round(numericValue * 60);
                break;
            case "h":
                seconds += Math.round(numericValue * 60 * 60);
                break;
            case "d":
                seconds += Math.round(numericValue * 60 * 60 * 24);
                break;
            default:
                // console.log("neki fjest narobe")
                break;
        }
    });
    return seconds;
}

export function setInitialDataToGraphSeries(data, series, chart) {
    series.forEach((serieName) => {
        let graphSerie = chart.get(serieName);
        if (graphSerie) {
            graphSerie.setData(
                data.map((d) => [d.timestamp, d[serieName]]),
                false,
                false,
                false
            );
        }
    });

    chart.redraw(true);
}

export function addDataPointsToGraphSeries(data, series, chart, deleteDataSeriesBeyondThisPoint) {
    data.forEach((val) => {
        series.forEach((serie, index) => {
            let graphSerie = chart.get(serie);
            if (graphSerie) {
                if (graphSerie.xData.length === 0 || graphSerie.xData[graphSerie.xData.length - 1] < val.timestamp) {
                    while (deleteDataSeriesBeyondThisPoint && graphSerie.xData.length > 2 && graphSerie.xData[2] < deleteDataSeriesBeyondThisPoint) {
                        graphSerie.removePoint(0, false, false);
                    }
                }
                graphSerie.addPoint([val.timestamp, val[serie]], false /*redraw*/, false /*shift*/, false /*animation*/);
            }
        });
    });
}

// export function setDataPointsToGraphSeries(data, series, chart, xAxisProperty = 'timestamp') {
export function setDataPointsToGraphSeries(options) {
    const { data, series, chart, xAxisProperty = "timestamp", deleteDataBeyondTS, animate = false } = options;

    data.forEach((val) => {
        series.forEach((serie, index) => {
            let graphSerie = chart.get(serie);

            //#region DELETE OLD DATA
            if (deleteDataBeyondTS && graphSerie.xData.length >= 2) {
                while (graphSerie.xData.length > 2 && graphSerie.xData[2] < deleteDataBeyondTS) {
                    graphSerie.removePoint(0, false, false);
                }
            }
            //#endregion

            //#region DELETE IF EXISTS AND ADD
            let indexOfExistingProperty = graphSerie.xData.indexOf(val[xAxisProperty], -0);
            if (indexOfExistingProperty >= 0) {
                graphSerie.removePoint(indexOfExistingProperty, false /*redraw*/, false /*animation*/);
            }
            graphSerie.addPoint([val[xAxisProperty], val[serie]], false /*redraw*/, false /*shift*/, false /*animation*/);
            ////#endregion
        });
    });

    chart.redraw(animate);
}

export function deleteGraphSeries(options) {
    if (!options.chart) {
        console.error("Undefined chart!");
    }

    options.chart.series.forEach((serie) => {
        serie.setData([]);
    });

    if (options.redraw) {
        options.chart.redraw();
    }
}

/**
 * @param {("blue" | "washedBlue" | "green" | "washedViolet" | "red" | "washedRed" | "orange" | "yellow")} colorName
 * @returns {String} HEX color eq: "#ff1299"
 */
export function getBrandedColor(colorName) {
    switch (colorName) {
        case "blue":
            return "#3477aa";
        case "washedBlue":
            return "#2c5a7c";
        case "green":
            return "#53994f";
        case "washedViolet":
            return "#6f4cad";
        case "teal":
            return "#009688";
        case "red":
            return "#cc3e44";
        case "washedRed":
            return "#b05357";
        case "orange":
            return "#bb7e0e";
        case "yellow":
            return "#afaa13";
        default:
            throw new Error("Unknown brand name: " + colorName);
    }
}
/**
 * @param  {Object} options yAxis and series
 *
 * @returns {Object} Highcharts chart options
 */
export function getDefaultChartOptions(options) {
    return {
        exporting: {
            filename: options.export && options.export.filename ? options.export.filename : "",
            csv: {
                itemDelimiter: ';',
                decimalPoint: ','
            }
        },
        chart: {
            type: "line",
            zoomType: options.chart.hasOwnProperty("zoomType") ? options.chart.zoomType : "x",
            height: options.chart.height,
            events: {
                selection: function () {
                    if (options.chart.onSelection) {
                        options.chart.onSelection();
                    }
                },
            },
        },
        time: {
            timezone: "Europe/Ljubljana",
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        legend: {
            itemStyle: {
                color: "white",
            },
            itemHoverStyle: {
                color: "#5dbb46",
            },
        },
        tooltip: {
            backgroundColor: "rgba(27, 35, 55, 0.85)",
            style: {
                color: "#F0F0F0",
            },
            crosshairs: options.chart.tooltipShared ? [true, false] : [],
            shared: options.chart.tooltipShared,
            valueDecimals: 2,
        },
        plotOptions: {
            series: {
                boostThreshold: 5000,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                stacking: options.chart.stacking,
            },
            //     line: {
            //         marker: {
            //             enabled: false,
            //         },
            //     },
            //     area: {
            //         marker: {
            //             enabled: false,
            //         },
            //     },
            //     series: {
            //         boostThreshold: 5000,
            //         states: {
            //             hover:
            //             {
            //                 enabled: false
            //             }
            //         }
            //     }
        },
        xAxis: {
            lineColor: "rgb(169, 169, 169)",
            lineWidth: 0.5,
            type: options.xAxis && options.xAxis.hasOwnProperty("type") ? options.xAxis.type : "datetime",
            labels: {
                style: {
                    color: "white",
                },
            },
            title: {
                text: options.xAxis && options.xAxis.hasOwnProperty("name") ? options.xAxis.name : undefined,
                style: {
                    color: "white",
                },
            },
            minTickInterval: (options.xAxis || {}).minTickInterval,
            tickAmount: (options.xAxis || {}).tickAmount,
            events: {
                setExtremes: function (e) {
                    if (options.xAxis && options.xAxis.onSetExtremes) {
                        options.xAxis.onSetExtremes(e.min, e.max);
                    }
                },
            },
        },
        yAxis: options.yAxis.map((yaxe) => ({
            alignTicks: yaxe.alignTicks,
            endOnTick: yaxe.endOnTick || false,
            gridLineColor: yaxe.gridLineColor || "darkgray",
            gridLineWidth: yaxe.gridLineWidth || 0.5,
            id: yaxe.id,
            labels: {
                style: {
                    color: "white",
                },
            },
            max: yaxe.max,
            min: yaxe.min,
            minPadding: 0.1,
            softMax: yaxe.softMax,
            softMin: yaxe.softMin,
            startOnTick: yaxe.startOnTick || false,
            title: {
                style: {
                    color: "white",
                },
                text: yaxe.name,
            },
            opposite: yaxe.opposite,
        })),
        series: options.series.map((serie) => ({
            borderColor: serie.borderColor,
            color: serie.color,
            connectNulls: serie.connectNulls || false,
            fillColor: serie.fillColor,
            fillOpacity: serie.fillOpacity,
            groupPadding: 0,
            id: serie.id,
            lineWidth: serie.lineWidth || 1,
            name: serie.name,
            negativeColor: serie.negativeColor,
            pointPadding: 0,
            pointPlacement: serie.pointPlacement,
            stack: serie.stack,
            step: serie.step,
            tooltip: {
                valueDecimals: serie.hasOwnProperty("valueDecimals") ? serie.valueDecimals : 2,
                valueSuffix: serie.valueSuffix,
            },
            type: serie.type,
            visible: serie.visible,
            yAxis: serie.yAxis,
            zIndex: serie.zIndex,
        })),
    };
}

/**
 * @param {Object} options Plotline options for highcharts plotline
 * @param {string} options.id Plotline ID
 * @param {string} options.color Plotline color (default: lime)
 * @param {('Dash' | 'DashDot' | 'Dot' | 'LongDash' | 'LongDashDot' | 'LongDashDotDot' | 'ShortDash' | 'ShortDashDot' | 'ShortDashDotDot' | 'ShortDot' | 'Solid')} options.dashStyle Plotline dashStyle (default: ShortDot)
 * @param {BigInteger} options.value Plotline value
 * @param {Number} options.width Plotline width (default: 0.5)
 * @param {BigInteger} options.zIndex Plotline zIndex (default: 99)
 * @param {string} options.label Plotline label text
 * @param {("middle" | "top" | "bottom")} options.labelVerticalAlign Plotline label vertical align
 * @param {string} options.labelColor Plotline label color (inherits plotline color or defaults: lime)
 * @param {Number} options.labelY Plotline label Y margin in px
 * @param {Number} options.labelX Plotline label X margin in px
 */
export function getDefaultChartPlotline(options) {
    return {
        id: options.id,
        color: options.color || "lime",
        dashStyle: options.dashStyle || "Solid",
        value: options.value,
        width: options.width || 1,
        zIndex: options.zIndex || 99,
        label: {
            text: options.label,
            verticalAlign: options.labelVerticalAlign,
            style: {
                color: options.labelColor || options.color || "lime",
            },
            y: options.labelY,
            x: options.labelX,
        },
    };
}

/**
 * @param {Object} options Plotband options for highcharts plotband
 * @param {string} options.id Plotband ID
 * @param {string} options.color Plotband color (default: transparent gray)
 * @param {BigInteger} options.from Plotband from
 * @param {BigInteger} options.to Plotband to
 * @param {BigInteger} options.zIndex Plotline zIndex (default: 90)
 * @param {string} options.label Plotline label text
 * @param {("middle" | "top" | "bottom")} options.labelVerticalAlign Plotline label vertical align
 * @param {string} options.labelColor Plotline label color (inherits plotline color or defaults: gray)
 * @param {Number} options.labelY Plotline label Y margin in px
 * @param {Number} options.labelX Plotline label X margin in px
 */
export function getDefaultChartPlotband(options) {
    return {
        id: options.id,
        color: options.color || "rgba(170, 170, 170, 0.1)",
        from: options.from,
        to: options.to,
        zIndex: options.zIndex || 99,
        label: {
            text: options.label,
            verticalAlign: options.labelVerticalAlign,
            style: {
                color: options.labelColor || options.color || "gray",
            },
            y: options.labelY,
            x: options.labelX,
        },
    };
}

export function getRestProtocol() {
    let protocol = window.location.protocol;

    if (protocol === "https:") {
        return "https:";
    } else {
        return "http:";
    }
}

export function getWsProtocol() {
    let protocol = window.location.protocol;

    if (protocol === "https:") {
        return "wss:";
    } else {
        return "ws:";
    }
}

export function getSubdomain() {
    let host = window.location.hostname;
    let hostSplit = host.split(".");

    if (hostSplit.length >= 3) {
        return hostSplit[0];
    } else {
        if (host === "localhost") {
            return host;
        }
        else {
            console.warn("Function 'getSubdomain' is not including a case for a path like (" + host + "). Please contact software maintainer!");
            return undefined;
        }

    }
}

export const getCdnImage = require.context("../../public/cdn/images", true);

export function deepGet() {
    let recursiveMethod = function (obj, properties) {
        // If we have reached an undefined/null property
        // then stop executing and return undefined.
        if (obj === undefined || obj === null) {
            return;
        }

        // If the path array has no more elements, we've reached
        // the intended property and return its value.
        if (properties.length === 0) {
            return obj;
        }

        // Prepare our found property and path array for recursion
        var foundSoFar = obj[properties[0]];
        var remainingProperties = properties.slice(1);

        return recursiveMethod(foundSoFar, remainingProperties);
    };

    return recursiveMethod(arguments[0], [...arguments].slice(1, arguments.length));
}

export function generateUUID(mustNotInclude = []) {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }

    let uuid = undefined;
    do {
        uuid = _p8() + _p8(true) + _p8(true) + _p8();
    } while (mustNotInclude.includes(uuid));

    return uuid;
}

export function textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
    if (!str) {
        return "";
    } else if (str.length > maxLength) {
        switch (side) {
            case "start":
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}

export function downloadContentAs(filename, content) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const PermissionModes = Object.freeze({
    optimistic: "OPTIMISTIC",
    pessimistic: "PESSIMISTIC",
});

export const PermissionMode = PermissionModes.pessimistic;


export function getPermission(permissionName) {
    let perm = SharedData.deepGet("apiPermissions", permissionName);
    if (!perm) {
        //async post that this permission is missing
        return undefined;
    }
    return perm.permission;
}

export function hasWriteAccess(permissionName) {
    let perm = getPermission(permissionName);
    if (perm) {
        return perm.includes("w");
    }
    else {
        return PermissionMode === PermissionModes.optimistic;
    }
}

export function hasReadAccess(permissionName) {
    let perm = getPermission(permissionName);
    if (perm) {
        return perm.includes("r");
    }
    else {
        return PermissionMode === PermissionModes.optimistic;
    }
}

/**
 * @param {Object} options Force reload page options
 * @param {string} options.currentPath Redirect back to current path
 * @param {Object} options.history React browser history object, usually found as: this.props.history
 * @param {BigInteger} [options.timeout=1000]  Redirect timeout in milliseconds (default: 1000)
 */
export function forceReloadPage(options) {
    const {
        currentPath,
        history,
        timeout = 1000
    } = options;

    history.replace(`/reload`);
    setTimeout(() => {
        history.replace(currentPath);
    }, timeout);
}