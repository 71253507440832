import React from "react";
import ReactSVG from "react-svg";

import batPowerMeterSvgSrc from "./batPowermeter.svg";

export class BatPowerMeter extends React.Component {
    wholeElement = null;
    scaleqMin4Element = null;
    scaleqMin3Element = null;
    scaleqMin2Element = null;
    scaleqMin1Element = null;
    scaleqMax4Element = null;
    scaleqMax3Element = null;
    scaleqMax2Element = null;
    scaleqMax1Element = null;
    relPowerElement = null;
    absPowerElement = null;
    negPathElement = null;
    pozPathElement = null;
    arrowLeftElement = null;
    arrowRightElement = null;

    lastDirection = 0;

    digits = 1;

    shouldComponentUpdate() {
        return false;
    }

    initElements() {
        if (!this.props.id) {
            console.error("Undefined 'id' property!");
            return;
        }

        if (this.wholeElement) {
            return;
        }

        if (this.props.hasOwnProperty("digits")) {
            this.digits = this.props.digits;
        }

        this.wholeElement = document.getElementById(this.props.id);

        this.scaleqMin4Element = this.wholeElement.getElementsByClassName("scaleq-4")[0];
        this.scaleqMin3Element = this.wholeElement.getElementsByClassName("scaleq-3")[0];
        this.scaleqMin2Element = this.wholeElement.getElementsByClassName("scaleq-2")[0];
        this.scaleqMin1Element = this.wholeElement.getElementsByClassName("scaleq-1")[0];

        this.scaleqMax4Element = this.wholeElement.getElementsByClassName("scaleq4")[0];
        this.scaleqMax3Element = this.wholeElement.getElementsByClassName("scaleq3")[0];
        this.scaleqMax2Element = this.wholeElement.getElementsByClassName("scaleq2")[0];
        this.scaleqMax1Element = this.wholeElement.getElementsByClassName("scaleq1")[0];

        this.relPowerElement = this.wholeElement.getElementsByClassName("relPower")[0];
        this.absPowerElement = this.wholeElement.getElementsByClassName("absPower")[0];

        this.negPathElement = this.wholeElement.getElementsByClassName("neg")[0].getElementsByTagName("path")[0];
        this.pozPathElement = this.wholeElement.getElementsByClassName("poz")[0].getElementsByTagName("path")[0];

        this.arrowLeftElement = this.wholeElement.getElementsByClassName("arrowLeftContainer")[0];
        this.arrowRightElement = this.wholeElement.getElementsByClassName("arrowRightContainer")[0];
    }

    setValue(type, value) {
        if (!this.wholeElement) {
            // console.warn("Element not yet loaded");
            return;
        }

        switch (type) {
            case "relPower":
                let percent = value;

                if (percent === Infinity) {
                    percent = 0;
                } else if (percent > 100) {
                    percent = 100;
                } else if (percent < -100) {
                    percent = -100;
                }

                let newValue = percent * 0.9;

                this.relPowerElement.textContent = Math.abs(value).toFixed(1) + "%";

                if (percent > 0) {
                    this.negPathElement.style.strokeDashoffset = "90px";
                    this.pozPathElement.style.strokeDashoffset = 90 - newValue + "px";
                } else if (percent < 0) {
                    this.pozPathElement.style.strokeDashoffset = "90px";
                    this.negPathElement.style.strokeDashoffset = 90 + newValue + "px";
                } else {
                    this.pozPathElement.style.strokeDashoffset = "90px";
                    this.negPathElement.style.strokeDashoffset = "90px";
                }

                break;

            case "absPower":
                this.absPowerElement.textContent = value.toFixed(2) + (this.props.measureUnit || "MW");

                if (value < 0 && this.lastDirection !== -1) {
                    this.arrowRightElement.style.display = "none";
                    this.arrowLeftElement.style.display = "block";
                    this.lastDirection = -1;
                } else if (value > 0 && this.lastDirection !== 1) {
                    this.arrowLeftElement.style.display = "none";
                    this.arrowRightElement.style.display = "block";
                    this.lastDirection = 1;
                } else if (value === 0 && this.lastDirection !== 0) {
                    this.arrowRightElement.style.display = "none";
                    this.arrowLeftElement.style.display = "none";
                    this.lastDirection = 0;
                }

                break;

            case "minPowerScale":
                this.scaleqMin4Element.textContent = (value * 1).toFixed(this.digits);
                this.scaleqMin3Element.textContent = (value * 0.75).toFixed(this.digits);
                this.scaleqMin2Element.textContent = (value * 0.5).toFixed(this.digits);
                this.scaleqMin1Element.textContent = (value * 0.25).toFixed(this.digits);
                break;

            case "maxPowerScale":
                this.scaleqMax4Element.textContent = (value * 1).toFixed(this.digits);
                this.scaleqMax3Element.textContent = (value * 0.75).toFixed(this.digits);
                this.scaleqMax2Element.textContent = (value * 0.5).toFixed(this.digits);
                this.scaleqMax1Element.textContent = (value * 0.25).toFixed(this.digits);
                break;

            default:
                console.error("Invalid type!");
                break;
        }
    }

    render() {
        return (
            <div id={this.props.id}>
                <ReactSVG
                    style={{ textAlign: "center" }}
                    beforeInjection={(svg) => {
                        svg.setAttribute("style", "display: block;position: absolute;height: 70%;width: 84%;top: 100px; left: 8%;");
                    }}
                    src={batPowerMeterSvgSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }}
                />
            </div>
        );
    }
}
