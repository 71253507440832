import Export from "highcharts-export-csv";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";
import { restAPIget, getDefaultChartOptions, setInitialDataToGraphSeries, getBrandedColor } from "../../shared/utils";
import SharedData from "../../shared/sharedData";

Export(Highcharts);

export class SBSHistoryDeviation extends React.Component {
    abortController = new AbortController();
    chartOptions = getDefaultChartOptions({
        chart: {
            height: "555px",
            stacking: "normal",
        },
        export: {
            filename: "SIJ GROUP deviation",
            csv: {
                itemDelimiter: ';',
                decimalPoint: ','
            }
        },
        yAxis: [
            {
                id: "deviationAxe",
                name: "Energy (MWh)",
                stacking: "normal",
            },
        ],
        series: [
            {
                id: "posDeviation",
                name: "Positive deviation",
                type: "column",
                color: getBrandedColor("blue"),
                borderColor: "rgba(0, 0, 255, 0.1)",
                valueSuffix: "MWh",
                pointPlacement: "between",
                stack: "deviation",
            },
            {
                id: "negDeviation",
                name: "Negative deviation",
                type: "column",
                color: getBrandedColor("red"),
                borderColor: "rgba(255, 0, 0, 0.1)",
                valueSuffix: "MWh",
                pointPlacement: "between",
                stack: "deviation",
            },
        ],
    });

    constructor(props) {
        super(props);

        this.chartRef = React.createRef();

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "SIJ Group deviation", key: "sijgroupDeviation", isCurrentItem: true },
        ]);

        this.state = {
            graphIsLoading: true,
            datePickerDate: new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
            datePickerDateEnd: new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this._isMounted = false;
    }

    loadData() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        let fromDate = this.state.datePickerDate;
        let toDate = this.state.datePickerDateEnd;

        let tsOnQuery = moment.unix(SharedData.get("timestamp")).startOf("hour").unix();

        this.setState({ graphIsLoading: true }, () => {
            let roundInterval = 60 /*s*/ * 60; /*mins*/

            let dayFrom = moment.tz(
                {
                    year: fromDate.getFullYear(),
                    month: fromDate.getMonth(),
                    date: fromDate.getDate(),
                },
                "Europe/Ljubljana"
            );

            let dayTo = toDate
                ? moment.tz(
                    {
                        year: toDate.getFullYear(),
                        month: toDate.getMonth(),
                        date: toDate.getDate(),
                    },
                    "Europe/Ljubljana"
                )
                : dayFrom.clone();

            restAPIget(
                `/measurements/energyandpower/interval/grouped/all/${dayFrom.clone().startOf("day").unix()}/${dayTo
                    .clone()
                    .endOf("day")
                    .unix()}/${roundInterval}`,
                this.abortController.signal,
                { route: "" }
            ).then((result) => {
                if (result.isOk && this._isMounted) {
                    let chartData = Object.values(result.data).map((d) => {
                        if (d.intervalStart >= tsOnQuery) {
                            return {
                                timestamp: Number(d.intervalStart) * 1000,
                            };
                        } else {
                            return {
                                timestamp: Number(d.intervalStart) * 1000,
                                posDeviation: Number(d.deviation) >= 0 ? Number(d.deviation) / 1000 : undefined,
                                negDeviation: Number(d.deviation) < 0 ? Number(d.deviation) / 1000 : undefined,
                            };
                        }
                    });

                    // //push last item duplication in order to make the graph look beatiful
                    // if (chartData.length) {
                    //     chartData.push({
                    //         timestamp: chartData[chartData.length - 1].timestamp + (roundInterval * 1000),
                    //         tape: chartData[chartData.length - 1].deviation
                    //     })
                    // }

                    setInitialDataToGraphSeries(chartData, ["posDeviation", "negDeviation"], this.chartRef.current.chart);
                    this.setState({ graphIsLoading: false });
                }
            });
        });
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title="Deviation chart"
                            isLoading={this.state.graphIsLoading}
                            style={{ maxHeight: "600px" }}
                            rightItems={[
                                {
                                    key: "ExportToCSV",
                                    type: "actionButton",
                                    text: "CSV",
                                    icon: "DownloadDocument",
                                    onClick: function () {
                                        this.chartRef.current.chart.downloadCSV();
                                    }.bind(this),
                                },
                                { type: "emptySpace" },

                                // { type: "emptySpace" },
                                {
                                    key: "datePickerEnd",
                                    type: "datePicker",
                                    value: this.state.datePickerDateEnd,
                                    minDate: new Date(2020, 3, 17),
                                    onClick: function (dateEnd) {
                                        this.setState(
                                            {
                                                datePickerDateEnd: dateEnd,
                                                loadingGraph: true,
                                            },
                                            () => {
                                                this.chartRef.current.chart.series.forEach((serie) => {
                                                    serie.setData([]);
                                                });
                                                this.loadData();
                                            }
                                        );
                                    }.bind(this),
                                },
                                {
                                    key: "datePicker",
                                    type: "datePicker",
                                    style: { paddingRight: "10px" },
                                    value: this.state.datePickerDate,
                                    minDate: new Date(2020, 3, 17),
                                    onClick: function (newDate) {
                                        this.setState(
                                            {
                                                datePickerDate: newDate,
                                                loadingGraph: true,
                                            },
                                            () => {
                                                this.chartRef.current.chart.series.forEach((serie) => {
                                                    serie.setData([]);
                                                });
                                                this.loadData();
                                            }
                                        );
                                    }.bind(this),
                                },
                            ]}
                        >
                            <div id="chart">
                                <HighchartsReact highcharts={Highcharts} ref={this.chartRef} options={this.chartOptions} />
                            </div>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
