import { ActionButton } from "office-ui-fabric-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

import { ActivationPlanCopyDialog } from "./activationPlanCopyDialog";
import { ActivationPlanPointDialog } from "./activationPlanPointDialog";
import { NContainer } from "../../components/nContainer/nContainer";
import { restAPIget, restAPIpost, restAPIput, restAPIdelete, getBrandedColor, getDefaultChartPlotline, getDefaultChartPlotband } from "../../shared/utils";
import EventEmitter from "../../shared/EventEmitter";
import SharedData from "../../shared/sharedData";

export class ActivationPlan extends React.Component {
    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Activation plan", key: "activationPlan", isCurrentItem: true },
        ]);

        this.isWriteable = this.props.isWriteable;
        this.isReadable = this.props.isReadable;

        this.FROZEN_TIME_AHEAD = 5 * 60 * 1000;

        this.abortController = new AbortController();

        this.selectedBaselineId = this.props.match.params.id;
        this.selectedBaselineObj = null;

        this.chartComp = React.createRef();
        this.basepointDialogRef = React.createRef();
        this.copyBaselineDialogRef = React.createRef();
        this.chartOptions = {
            chart: {
                zoomType: "x",
                type: "line",
                height: "555px",
                events: {
                    selection: function (event) {
                        this.graphIsZoomed = true;
                    }.bind(this),
                    click: function (event) {
                        // do NOT show dialog for new point if it was clicked on reset button - hackish way - no library solution
                        if (event.target.className.baseVal === "highcharts-button-box" || event.target.textContent) {
                            return;
                        }

                        let timestamp = Math.round(event.xAxis[0].value);
                        if (timestamp > this.state.currentTimestamp * 1000 + this.FROZEN_TIME_AHEAD) {
                            let power = Number(event.yAxis[0].value.toFixed(3));
                            this.basepointDialogRef.current.newPoint(timestamp, power);
                        }
                    }.bind(this),
                },
            },
            // boost: {
            //     useGPUTranslations: true
            // },
            time: {
                timezone: "Europe/Ljubljana",
            },
            title: {
                text: undefined,
            },

            credits: {
                enabled: false,
            },

            legend: {
                itemStyle: {
                    color: "white",
                },
                itemHoverStyle: {
                    color: "#5dbb46",
                },
            },

            tooltip: {
                backgroundColor: "rgba(27, 35, 55, 0.85)",
                style: {
                    color: "#F0F0F0",
                },
            },

            xAxis: {
                lineColor: "rgb(169, 169, 169)",
                lineWidth: 0.5,
                type: "datetime",
                labels: {
                    style: {
                        color: "white",
                    },
                },

                events: {
                    setExtremes: function (e) {
                        // console.log(e.min, e.max);
                        if (typeof e.min == "undefined" && typeof e.max == "undefined") {
                            // this.setMaxExtremeToNow = true;
                            this.chartComp.current.chart.xAxis[0].update({ min: this.from, max: this.to });
                        }
                        // this.setState({
                        //     chartSelectedFrom: e.min,
                        //     chartSelectedTo: e.max
                        // });
                    }.bind(this),
                },
            },
            yAxis: [
                {
                    id: "power",
                    title: {
                        text: "Power (MW)",
                        style: {
                            color: "white",
                        },
                    },

                    gridLineColor: "darkgray",
                    gridLineWidth: 0.5,
                    labels: {
                        style: {
                            color: "white",
                        },
                    },

                    startOnTick: false,
                    endOnTick: false,
                    minPadding: 0.1,
                },
            ],
            plotOptions: {
                line: {
                    marker: {
                        lineWidth: 1,
                        radius: 4,
                        symbol: "circle",
                        enabledThreshold: 0,
                    },
                },
                series: {
                    lineWidth: 4,
                    boostThreshold: 1000000000,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function (sth) {
                                let readyOnly = sth.point.x < this.state.currentTimestamp * 1000 + this.FROZEN_TIME_AHEAD;
                                this.basepointDialogRef.current.editPoint(sth.point.x, sth.point.y, readyOnly);
                            }.bind(this),
                        },
                    },
                },
            },
            series: [
                {
                    name: "Past baseline",
                    id: "pastBaseline",
                    color: "slategray",
                    zIndex: 4,
                    lineWidth: 4,
                    tooltip: {
                        valueSuffix: "MW",
                    },
                    step: "left",
                    findNearestPointBy: "y",
                    zoneAxis: "x",
                },
                {
                    name: "Future Baseline",
                    id: "futureBaseline",
                    step: "left",
                    findNearestPointBy: "y",
                    color: getBrandedColor("orange"),
                    zIndex: 4,
                    lineWidth: 4,
                    tooltip: {
                        valueSuffix: "MW",
                    },
                },
            ],
        };

        this.state = {
            baselinePoints: [],
            isLoading: true,
            currentTimestamp: SharedData.get("timestamp"),
        };
    }

    componentDidMount() {
        this._isMounted = true;

        EventEmitter.subscribe(
            "APPLICATION_TIME",
            (value) => {
                this.drawCurrentTimeOnGraph(value);
                this.setState({
                    currentTimestamp: value,
                });
            },
            "activationPlanTime"
        );

        this.getData();
    }

    getData() {
        this.setState(
            {
                isLoading: true,
                baselinePoints: [],
            },
            () => {
                restAPIget("/activationplan/" + this.selectedBaselineId, this.abortController.signal).then((result) => {
                    if (result.isOk) {
                        if (this.plotBandsToRemoveOnGetData && this.plotBandsToRemoveOnGetData.length > 0) {
                            this.plotBandsToRemoveOnGetData.forEach((pb) => {
                                this.chartComp.current.chart.xAxis[0].removePlotBand(pb);
                            });
                            this.plotBandsToRemoveOnGetData = [];
                        }

                        this.selectedBaselineObj = result.data;
                        this.from = result.data.ts_from * 1000;
                        this.to = result.data.ts_to * 1000;

                        let theBasePoints = result.data.activation_points
                            .map((p) => ({
                                timestamp: p.timestamp * 1000,
                                power: p.power,
                                baseline: p.power,
                                planId: p.activation_plan_id,
                                assetId: p.asset_id,
                            }))
                            .sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));

                        let chartData = [...theBasePoints];
                        chartData.push(Object.assign({}, theBasePoints[theBasePoints.length - 1], { timestamp: this.to + 60 * 1000 }));

                        let indexOftimestampBigger = chartData.findIndex((b) => b.timestamp >= this.state.currentTimestamp * 1000);
                        this.setInitialDataToGraphSeries(
                            chartData.slice(0, indexOftimestampBigger >= 0 ? indexOftimestampBigger + 1 : chartData.length).map((b) => ({
                                timestamp: b.timestamp,
                                pastBaseline: b.power,
                            })),
                            ["pastBaseline"]
                        );
                        this.setInitialDataToGraphSeries(
                            chartData.slice(indexOftimestampBigger, chartData.length).map((b) => ({
                                timestamp: b.timestamp,
                                futureBaseline: b.power,
                            })),
                            ["futureBaseline"]
                        );

                        let plotBands = [];
                        chartData.forEach((point, index) => {
                            if (plotBands.length > 0) {
                                let lastPlotBand = plotBands[plotBands.length - 1];

                                if (point.power > 0 && lastPlotBand.power > 0) {
                                    lastPlotBand.energy += lastPlotBand.power * (Math.min(point.timestamp, this.to) - lastPlotBand.fake_from); // should be kw * milliseconds
                                    lastPlotBand.fake_from = point.timestamp;
                                    lastPlotBand.power = point.power;
                                } else if (point.power < 0 && lastPlotBand.power < 0) {
                                    lastPlotBand.energy += lastPlotBand.power * (Math.min(point.timestamp, this.to) - lastPlotBand.fake_from); // should be kw * milliseconds
                                    lastPlotBand.fake_from = point.timestamp;
                                    lastPlotBand.power = point.power;
                                } else {
                                    lastPlotBand.energy += lastPlotBand.power * (Math.min(point.timestamp, this.to) - lastPlotBand.fake_from); // should be kw * milliseconds
                                    lastPlotBand.to = Math.min(point.timestamp, this.to);

                                    plotBands.push({
                                        id: point.timestamp,
                                        color: point.power < 0 ? "rgb(58, 42, 60)" : "rgb(36, 47, 75)",
                                        dashStyle: "ShortDot",
                                        from: point.timestamp,
                                        fake_from: point.timestamp,
                                        power: point.power,
                                        energy: 0,
                                    });
                                }

                                if (index === chartData.length - 1) {
                                    lastPlotBand.energy += lastPlotBand.power * (Math.min(point.timestamp, this.to) - lastPlotBand.from); // should be kw * milliseconds
                                    lastPlotBand.to = Math.min(point.timestamp, this.to);
                                }
                            } else {
                                if (point.power !== 0) {
                                    plotBands.push({
                                        id: point.timestamp,
                                        color: point.power < 0 ? "rgb(58, 42, 60)" : "rgb(36, 47, 75)",
                                        dashStyle: "ShortDot",
                                        from: point.timestamp,
                                        fake_from: point.timestamp,
                                        power: point.power,
                                        energy: 0,
                                    });
                                }
                            }
                        });

                        this.plotBandsToRemoveOnGetData = [];
                        plotBands.forEach((pb) => {
                            if (pb.energy !== 0) {
                                pb.label = {
                                    text: (pb.energy / 60 / 60).toFixed(2) + "KWh",
                                    verticalAlign: "middle",
                                    style: {
                                        color: "white",
                                    },
                                };
                                this.chartComp.current.chart.xAxis[0].addPlotBand(pb);
                                this.plotBandsToRemoveOnGetData.push(pb.id);
                            }
                        });

                        this.chartComp.current.chart.xAxis[0].update({ min: this.from, max: this.to });

                        this.setState({
                            baselinePoints: theBasePoints,
                            isLoading: false,
                        });
                    }
                });
            }
        );

        // this.chartComp.current.chart.get('baseline').update({
        //     zones: [{
        //         value: TimeStampNow + 6 * 60 * 1000,
        //         color: 'slategray'
        //     }]
        // });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();

        EventEmitter.unsubscribe("APPLICATION_TIME", "activationPlanTime");
    }

    drawCurrentTimeOnGraph(timestamp) {
        if (this._isMounted && this.chartComp.current && this.chartComp.current.chart) {
            this.setState({
                currentTimestamp: timestamp * 1000,
            });

            this.chartComp.current.chart.xAxis[0].removePlotLine("timeNow");
            this.chartComp.current.chart.xAxis[0].removePlotBand("timeBlocked");

            this.chartComp.current.chart.xAxis[0].addPlotLine(
                getDefaultChartPlotline({
                    id: "timeNow",
                    label: "Now",
                    value: timestamp * 1000,
                })
            );

            this.chartComp.current.chart.xAxis[0].addPlotBand(
                getDefaultChartPlotband({
                    id: "timeBlocked",
                    color: "rgba(170, 170, 170, 0.1)",
                    from: timestamp * 1000,
                    to: timestamp * 1000 + this.FROZEN_TIME_AHEAD,
                })
            );

            let futureSerie = this.chartComp.current.chart.get("futureBaseline");
            let pastSerie = this.chartComp.current.chart.get("pastBaseline");

            let firstIndexOverTime = futureSerie.xData.findIndex((ts) => {
                return ts >= timestamp * 1000;
            });

            if (firstIndexOverTime >= 0) {
                for (let index = 0; index < firstIndexOverTime; index++) {
                    let value = [futureSerie.xData[index], futureSerie.yData[index]];
                    futureSerie.removePoint(0, true, true);
                    pastSerie.addPoint(value, true, false, true);
                }

                //THIS IF WAS ADDED AS A HACK TO WORK IT OUT, it might be wrong although
                if (firstIndexOverTime > 0) {
                    let value = [futureSerie.xData[firstIndexOverTime - 1], futureSerie.yData[firstIndexOverTime - 1]];
                    pastSerie.addPoint(value, true, false, true);
                }
            }
        }
    }

    setInitialDataToGraphSeries(data, series) {
        if (!this._isMounted) return;

        series.forEach((ser) => {
            let graphSerie = this.chartComp.current.chart.get(ser);

            graphSerie.setData(
                data.map((d) => {
                    return [d.timestamp, d[ser]];
                })
            );
        });
    }

    getActivationPlanId() {
        return this.props.match.params.id;
    }

    updatePoint(timestamp, power) {
        this.setState({ isLoading: true }, () => {
            restAPIput(`/activationplan/${this.getActivationPlanId()}/activationpoint`, {
                timestamp,
                power,
            }).then(() => {
                // window.location.reload();
                this.getData();
            });
        });
    }

    addNewPoint(timestamp, power) {
        this.setState({ isLoading: true }, () => {
            restAPIpost(`/activationplan/${this.getActivationPlanId()}/activationpoint`, {
                timestamp,
                power,
            }).then(() => {
                // window.location.reload();
                this.getData();
            });
        });
    }

    deletePoint(timestamp) {
        this.setState({ isLoading: true }, () => {
            restAPIdelete(`/activationplan/${this.getActivationPlanId()}/activationpoint`, {
                timestamp,
            }).then(() => {
                // window.location.reload();
                this.getData();
            });
        });
    }

    render() {
        return (
            <>
                <ActivationPlanCopyDialog ref={this.copyBaselineDialogRef} />
                <ActivationPlanPointDialog
                    ref={this.basepointDialogRef}
                    onContinue={(newState) => {
                        switch (newState.mode) {
                            case "edit":
                                this.updatePoint(newState.timestamp / 1000, newState.power);
                                break;
                            case "add":
                                this.addNewPoint(newState.timestamp / 1000, newState.power);
                                break;
                            case "delete":
                                this.deletePoint(newState.timestamp / 1000);
                                break;
                            default:
                                throw new Error("Unknown action!");
                        }
                    }}
                />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title="Baseline chart"
                                isLoading={this.state.isLoading}
                                style={{ maxHeight: "600px" }}
                                rightItems={[
                                    {
                                        hidden: !this.isWriteable,
                                        type: "actionButton",
                                        text: "Copy to ...",
                                        key: "copyTo",
                                        icon: "Copy",
                                        onClick: function () {
                                            this.copyBaselineDialogRef.current.showDialog(this.selectedBaselineId, this.selectedBaselineObj.ts_from * 1000);
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <div id="baselinchart">
                                    <HighchartsReact highcharts={Highcharts} ref={this.chartComp} options={this.chartOptions} />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title="Baseline points"
                                isLoading={this.state.isLoading}
                                style={{ maxHeight: "auto" }}
                                rightItems={[
                                    {
                                        hidden: !this.isWriteable,
                                        type: "actionButton",
                                        text: "Add point",
                                        key: "addPoint",
                                        icon: "Add",
                                        onClick: function () {
                                            this.basepointDialogRef.current.newPoint(
                                                moment(this.selectedBaselineObj.ts_from * 1000)
                                                    .tz("Europe/Ljubljana")
                                                    .unix() * 1000,
                                                0
                                            );
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <table className="CustomTable">
                                    <thead>
                                        <tr>
                                            <th>Order</th>
                                            <th>Timestamp</th>
                                            <th>Power</th>
                                            {this.isWriteable && <th>Actions</th>}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.baselinePoints.map((bPoint, mapIndex) => (
                                            <tr key={bPoint.arid + "" + bPoint.timestamp} style={{ lineHeight: "32px" }}>
                                                <td>{mapIndex + 1}</td>
                                                <td>{moment(bPoint.timestamp).tz("Europe/Ljubljana").format("DD.MM.YYYY HH:mm:ss")}</td>
                                                <td>{bPoint.power} MW</td>
                                                {this.isWriteable && (
                                                    <td>
                                                        <ActionButton
                                                            iconProps={{ iconName: "OpenInNewWindow" }}
                                                            disabled={bPoint.timestamp < this.state.currentTimestamp * 1000 + this.FROZEN_TIME_AHEAD}
                                                            onClick={() => {
                                                                let readyOnly = bPoint.timestamp < this.state.currentTimestamp * 1000 + this.FROZEN_TIME_AHEAD;
                                                                this.basepointDialogRef.current.editPoint(bPoint.timestamp, bPoint.power, readyOnly);
                                                            }}
                                                        >
                                                            Edit
                                                        </ActionButton>
                                                        <ActionButton
                                                            disabled={bPoint.timestamp < this.state.currentTimestamp * 1000 + this.FROZEN_TIME_AHEAD}
                                                            iconProps={{ iconName: "Delete" }}
                                                            onClick={() => {
                                                                this.deletePoint(bPoint.timestamp / 1000);
                                                            }}
                                                        >
                                                            Delete
                                                        </ActionButton>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
