import React from "react";
import ReactSVG from "react-svg";

import svgSrc from "./battery.svg";

export class Battery extends React.Component {
    wholeElement = null;
    socElement = null;
    maxSocLimitsElement = null;
    maxSocLimitElement = null;
    minSocLimitsElement = null;
    minSocLimitElement = null;
    batteryPercentElement = null;
    fullEnergyElement = null;
    chargeEnergyElement = null;
    dischargeEnergyElement = null;
    remainingEnergyElement = null;

    estTimeText = null;
    estTimeValue = null;

    digits = 3;

    shouldComponentUpdate() {
        return false;
    }

    initElements() {
        if (!this.props.id) {
            console.error("Undefined 'id' property!");
            return;
        }

        if (this.wholeElement) {
            return;
        }

        if (this.props.hasOwnProperty("digits")) {
            this.digits = this.props.digits;
        }

        this.wholeElement = document.getElementById(this.props.id);

        this.maxSocLimitsElement = this.wholeElement.getElementsByClassName("maxSocLimits")[0];
        this.minSocLimitsElement = this.wholeElement.getElementsByClassName("minSocLimits")[0];
        this.maxSocLimitElement = this.wholeElement.getElementsByClassName("maxSocLimit")[0];
        this.minSocLimitElement = this.wholeElement.getElementsByClassName("minSocLimit")[0];
        this.batteryPercentElement = this.wholeElement.getElementsByClassName("batteryPercent")[0];
        this.socElement = this.wholeElement.getElementsByClassName("soc")[0];
        this.remainingEnergyElement = this.wholeElement.getElementsByClassName("remainingCapacityText")[0];
        this.fullEnergyElement = this.wholeElement.getElementsByClassName("fullEnergyText")[0];
        this.chargeEnergyElement = this.wholeElement.getElementsByClassName("chargeEnergyText")[0];
        this.dischargeEnergyElement = this.wholeElement.getElementsByClassName("dischargeEnergyText")[0];

        this.estTimeText = this.wholeElement.getElementsByClassName("EstimatedTimeText")[0];
        this.estTimeValue = this.wholeElement.getElementsByClassName("EstimatedTimeValue")[0];
    }

    convertSecondsToString(noOfSeconds, forceShow = false) {
        var days = Math.floor(noOfSeconds / (3600 * 24));
        var hours = Math.floor((noOfSeconds - days * 24 * 60 * 60) / 3600);
        var minutes = Math.floor((noOfSeconds - days * 24 * 3600 - hours * 3600) / 60);
        var seconds = noOfSeconds - days * 24 * 3600 - hours * 3600 - minutes * 60;

        let result = "";
        if (days || forceShow) {
            result += days + "d ";

            if (days >= 1000) {
                return result.trim();
            }
        }
        if (hours || forceShow) {
            result += hours + "h ";

            if (days >= 10) {
                return result.trim();
            }
        }
        if (minutes || forceShow) {
            result += minutes + "m ";
            if (days >= 1) {
                return result.trim();
            }
        }
        if (seconds || forceShow) {
            result += Math.ceil(seconds) + "s ";
        }
        return result.trim();
    }

    setValue(type, value) {
        if (!this.wholeElement) {
            // console.warn("Element not yet loaded");
            return;
        }

        switch (type) {
            case "estChargeTime":
                this.estTimeText.textContent = "Est. charge time";
                this.estTimeValue.textContent = this.convertSecondsToString(value);
                break;

            case "estDischargeTime":
                this.estTimeText.textContent = "Est. discharge time";
                this.estTimeValue.textContent = this.convertSecondsToString(value);
                break;

            case "hideEstTime":
                this.estTimeText.textContent = "";
                this.estTimeValue.textContent = "";
                break;

            case "remainingEnergy":
                this.remainingEnergyElement.textContent = value.toFixed(this.digits) + (this.props.measureUnit || "MWh");
                break;

            case "chargeEnergy":
                this.chargeEnergyElement.textContent = value.toFixed(this.digits) + (this.props.measureUnit || "MWh");
                break;

            case "dischargeEnergy":
                this.dischargeEnergyElement.textContent = value.toFixed(this.digits) + (this.props.measureUnit || "MWh");
                break;

            case "fullEnergy":
                this.fullEnergyElement.textContent = value.toFixed(this.digits) + (this.props.measureUnit || "MWh");
                break;

            case "maxSocLimit":
                this.maxSocLimitsElement.style.transform = "translateY(" + (100 - value) + "px)";
                this.maxSocLimitElement.textContent = value + "%";
                break;

            case "minSocLimit":
                this.minSocLimitsElement.style.transform = "translateY(" + value * -1 + "px)";
                this.minSocLimitElement.textContent = value + "%";
                break;

            case "soc":
                let percent = parseInt(value.toFixed());
                if (percent > 100) {
                    percent = 100;
                }
                if (percent < 0) {
                    percent = 0;
                }

                let percentText = "";
                if (percent < 10) {
                    percentText = "&#160;&#160;&#160;" + percent + " %";
                } else if (percent === 100) {
                    percentText = "&#160;" + percent + "%";
                } else {
                    percentText = "&#160;&#160;" + percent + "%";
                }

                this.batteryPercentElement.innerHTML = percentText;

                percent += 100;
                this.socElement.style.strokeDashoffset = percent + "px";
                break;

            default:
                console.error("Invalid type!");
                break;
        }
    }

    render() {
        return (
            <div id={this.props.id}>
                <ReactSVG
                    style={{ textAlign: "center" }}
                    beforeInjection={(svg) => {
                        svg.setAttribute("style", "display: block;position: absolute;height: 80%;width: 80%;top: 80px; left: 10%;");
                    }}
                    src={svgSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }}
                />
            </div>
        );
    }
}
