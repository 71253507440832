import { ActionButton } from "office-ui-fabric-react";
import moment from "moment";
import React from "react";

import { ActivationPlanCopyDialog } from "./activationPlanCopyDialog";
import { IAccept } from "../../components/iAccept/iaccept";
import { NContainer } from "../../components/nContainer/nContainer";
import { NewActivationPlanDialog } from "./newActivationPlanDialog";
import { restAPIget, restAPIdelete } from "../../shared/utils";
import EventEmitter from "../../shared/EventEmitter";
import SharedData from "../../shared/sharedData";

export class ActivationPlans extends React.Component {
    constructor(props) {
        super(props);
        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "Activation plans", key: "activationPlans", isCurrentItem: true },
        ]);

        this.activationPlanCopyDialogRef = React.createRef();
        this.newActivationPlanDialogRef = React.createRef();
        this.iacceptDialogRef = React.createRef();
        this.abortController = new AbortController();

        this.isWriteable = this.props.isWriteable;
        this.isReadable = this.props.isReadable;

        this.state = {
            baselines: [],
            isLoading: true,

            currentTimestamp: SharedData.get("timestamp"),
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getData();

        EventEmitter.subscribe(
            "APPLICATION_TIME",
            (value) => {
                this.setState({ currentTimestamp: value });
            },
            "activationPlansTime"
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();

        EventEmitter.unsubscribe("APPLICATION_TIME", "activationPlansTime");
    }

    getData() {
        this.setState(
            {
                isLoading: true,
                baselines: [],
            },
            () => {
                restAPIget("/activationplan", this.abortController.signal).then((result) => {
                    if (result.isOk) {
                        this.setState({
                            baselines: result.data,
                            isLoading: false,
                        });
                    }
                });
            }
        );
    }

    render() {
        return (
            <>
                <ActivationPlanCopyDialog
                    ref={this.activationPlanCopyDialogRef}
                    onContinue={() => {
                        this.getData();
                    }}
                />

                <NewActivationPlanDialog
                    ref={this.newActivationPlanDialogRef}
                    onContinue={() => {
                        this.getData();
                    }}
                />

                <IAccept
                    ref={this.iacceptDialogRef}
                    onContinue={() => {
                        this.setState({ isLoading: true });
                        restAPIdelete("/activationplan/" + this.selectedActivationPlanId).then((result) => {
                            this.getData();
                        });
                    }}
                />

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title="Activation plans by day"
                                isLoading={this.state.isLoading}
                                style={{ maxHeight: "auto" }}
                                rightItems={[
                                    {
                                        hidden: !this.isWriteable,
                                        type: "actionButton",
                                        text: "Add new plan",
                                        key: "addNewDay",
                                        icon: "Add",
                                        onClick: function () {
                                            this.newActivationPlanDialogRef.current.showDialog();
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <table className="CustomTable">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>No. of points</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.baselines.map((activationPlan, mapIndex) => (
                                            <tr key={activationPlan.id} style={{ lineHeight: "32px" }}>
                                                <td>{activationPlan.id}</td>
                                                <td>
                                                    {moment(activationPlan.ts_from * 1000)
                                                        .tz("Europe/Ljubljana")
                                                        .format("DD.MM.YYYY HH:mm:ss")}
                                                </td>
                                                <td>
                                                    {moment(activationPlan.ts_to * 1000)
                                                        .tz("Europe/Ljubljana")
                                                        .format("DD.MM.YYYY HH:mm:ss")}
                                                </td>
                                                <td>{activationPlan.activation_points.length}</td>
                                                <td>
                                                    <ActionButton
                                                        iconProps={{ iconName: "OpenInNewWindow" }}
                                                        onClick={() => {
                                                            this.props.history.push("/suha_activationplan/" + activationPlan.id);
                                                        }}
                                                    >
                                                        View
                                                    </ActionButton>
                                                    {this.isWriteable && (
                                                        <>
                                                            <ActionButton
                                                                iconProps={{ iconName: "Copy" }}
                                                                onClick={() => {
                                                                    this.activationPlanCopyDialogRef.current.showDialog(
                                                                        activationPlan.id,
                                                                        Math.max(
                                                                            moment
                                                                                .tz(SharedData.get("timestamp") * 1000, "Europe/Ljubljana")
                                                                                .add(1, "day")
                                                                                .unix() * 1000,
                                                                            activationPlan.ts_from * 1000 + 24 * 60 * 60 * 1000
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                Copy
                                                            </ActionButton>
                                                            <ActionButton
                                                                iconProps={{ iconName: "Delete" }}
                                                                disabled={activationPlan.ts_from <= this.state.currentTimestamp}
                                                                onClick={() => {
                                                                    this.selectedActivationPlanId = activationPlan.id;
                                                                    this.iacceptDialogRef.current._showDialog();
                                                                }}
                                                            >
                                                                Delete
                                                            </ActionButton>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
