import { DatePicker, DialogFooter, DayOfWeek, Dialog, DialogType, TextField, ActionButton } from 'office-ui-fabric-react';
import moment from 'moment-timezone';
import React from 'react';

import { getDayPickerSettings, _datepicker_formatDate, _datepicker_parseDateFromString } from '../../shared/utils';

export class ActivationPlanPointDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            power: '',
            mode: 'unknown',
            hidden: true,
            readOnly: false,
            title: 'Baseline point'
        }
    }


    newPoint(timestamp, power) {
        let date = moment(timestamp).tz('Europe/Ljubljana');
        this.setState({
            date: new Date(date.year(), date.month(), date.date()),
            time: date.format('HH:mm:ss'),
            power: power,
            mode: 'add',
            timestamp: timestamp,
            hidden: false,
            readOnly: false,
            title: 'Add new baseline point'
        });
    }

    editPoint(timestamp, power, isReadMode) {
        let date = moment(timestamp).tz('Europe/Ljubljana');
        this.setState({
            date: new Date(date.year(), date.month(), date.date()),
            time: date.format('HH:mm:ss'),
            power: power,
            mode: 'edit',
            timestamp: timestamp,
            hidden: false,
            readOnly: isReadMode,
            title: isReadMode ? 'View baseline point' : 'Edit baseline point'
        });
    }

    closeDialog(saveChanges, forceDelete) {
        this.setState({ hidden: true });

        if (saveChanges) {

            let newObject = { ...this.state };

            if (newObject.mode === 'add' && newObject.date && newObject.time) {
                let splitedTime = newObject.time.split(':');
                let newDatetime = moment.tz({
                    year: newObject.date.getFullYear(),
                    month: newObject.date.getMonth(),
                    day: newObject.date.getDate(),
                    hour: splitedTime[0],
                    minute: splitedTime[1],
                    second: splitedTime[2]
                }, 'Europe/Ljubljana');

                newObject['timestamp'] = newDatetime.unix() * 1000; // to have everywhere the value in milliseconds
            }
            else if (forceDelete) {
                newObject['mode'] = 'delete';
            }
            this.props.onContinue(newObject);
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this.closeDialog.bind(this, false, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: this.state.title
                    // subText: "Carefully enter the details and continue..."
                }}
                // minWidth='450px'
                modalProps={{
                    isBlocking: false,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false
                }}
            >

                <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0 }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <DatePicker
                                // disabled={this.state.readOnly || this.state.mode === 'edit'}
                                disabled
                                onSelectDate={evt => {
                                    if (!evt) {
                                        return;
                                    }
                                    this.setState({ date: evt });
                                }}
                                isRequired={true}
                                value={this.state.date}
                                label="Baseline point date"
                                showMonthPickerAsOverlay={true}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDayPickerSettings()}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                allowTextInput={false}
                                formatDate={(someValue) => {
                                    return _datepicker_formatDate(someValue);
                                }}
                                parseDateFromString={(someValue) => {
                                    return _datepicker_parseDateFromString(this.state.date, someValue);
                                }}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <TextField
                                required
                                autoFocus
                                disabled={this.state.readOnly || this.state.mode === 'edit'}
                                label="Baseline point time"
                                type="time"
                                step="1"
                                onChange={
                                    evt => {
                                        this.setState({
                                            time: evt.target.value,
                                        }
                                        );
                                    }
                                }
                                value={this.state.time}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <TextField
                                required
                                disabled={this.state.readOnly}
                                label="Baseline point power"
                                type="number"
                                step="0.01"
                                suffix="MW"
                                onChange={evt => {
                                    this.setState({ power: evt.target.value })
                                }}
                                value={this.state.power}
                            />
                        </div>
                    </div>
                </div>

                <DialogFooter>

                    {!this.state.readOnly && this.state.mode === "edit" && (
                        <ActionButton iconProps={{ iconName: 'Trash' }} onClick={this.closeDialog.bind(this, true, true)} text="Delete" />
                    )}

                    {!this.state.readOnly && (
                        <ActionButton
                            iconProps={{ iconName: 'Save' }}
                            onClick={this.closeDialog.bind(this, true, false)}
                            text="Save"
                            disabled={
                                !this.state.power ||
                                !this.state.date ||
                                !this.state.time
                            }
                        />
                    )}


                    <ActionButton iconProps={{ iconName: 'Cancel' }} onClick={this.closeDialog.bind(this, false, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }


}