import React from "react";
import ReactSVG from "react-svg";

import svgSrc from "./towerToTowerPowermeter.svg";

export class TowerToTowerPowermeter extends React.Component {
    wholeElement = null;
    scaleqMin4Element = null;
    scaleqMin3Element = null;
    scaleqMin2Element = null;
    scaleqMin1Element = null;
    scaleqMax4Element = null;
    scaleqMax3Element = null;
    scaleqMax2Element = null;
    scaleqMax1Element = null;
    scaleq0Element = null;
    relPowerElement = null;
    absPowerElement = null;
    negPathElement = null;
    pozPathElement = null;

    initElements() {
        if (!this.props.id) {
            console.error("Undefined 'id' property!");
            return;
        }
        this.wholeElement = document.getElementById(this.props.id);
        this.scaleqMin4Element = this.wholeElement.getElementsByClassName("scaleq-4")[0];
        this.scaleqMin3Element = this.wholeElement.getElementsByClassName("scaleq-3")[0];
        this.scaleqMin2Element = this.wholeElement.getElementsByClassName("scaleq-2")[0];
        this.scaleqMin1Element = this.wholeElement.getElementsByClassName("scaleq-1")[0];
        this.scaleqMax4Element = this.wholeElement.getElementsByClassName("scaleq4")[0];
        this.scaleqMax3Element = this.wholeElement.getElementsByClassName("scaleq3")[0];
        this.scaleqMax2Element = this.wholeElement.getElementsByClassName("scaleq2")[0];
        this.scaleqMax1Element = this.wholeElement.getElementsByClassName("scaleq1")[0];
        this.scaleq0Element = this.wholeElement.getElementsByClassName("scaleq0")[0];
        this.relPowerElement = this.wholeElement.getElementsByClassName("relpower")[0];
        this.absPowerElement = this.wholeElement.getElementsByClassName("abspower")[0];
        this.negPathElement = this.wholeElement.getElementsByClassName("negPath")[0].getElementsByTagName("path")[0];
        this.pozPathElement = this.wholeElement.getElementsByClassName("pozPath")[0].getElementsByTagName("path")[0];
    }

    shouldComponentUpdate() {
        return false;
    }

    setValue(type, value) {
        if (!this.wholeElement) {
            return;
        }

        switch (type) {
            case "relPower":
                let percent = value;
                if (percent > 100) {
                    percent = 100;
                }
                if (percent < -100) {
                    percent = -100;
                }

                let newValue = percent * 0.9;

                this.relPowerElement.textContent = Math.abs(value).toFixed(1) + "%";
                if (percent > 0 && isFinite(value)) {
                    this.negPathElement.style.strokeDashoffset = "90px";
                    this.pozPathElement.style.strokeDashoffset = 90 - newValue + "px";
                } else if (percent < 0 && isFinite(value)) {
                    this.pozPathElement.style.strokeDashoffset = "90px";
                    this.negPathElement.style.strokeDashoffset = 90 + newValue + "px";
                } else {
                    this.pozPathElement.style.strokeDashoffset = "90px";
                    this.negPathElement.style.strokeDashoffset = "90px";
                }

                break;

            case "absPower":
                this.absPowerElement.textContent = value.toFixed(2) + "MW";
                break;

            case "minPowerScale":
                this.scaleqMin4Element.textContent = value;
                this.scaleqMin3Element.textContent = (value * 0.75).toFixed(1);
                this.scaleqMin2Element.textContent = (value * 0.5).toFixed(1);
                this.scaleqMin1Element.textContent = (value * 0.25).toFixed(1);
                break;

            case "maxPowerScale":
                this.scaleqMax4Element.textContent = value;
                this.scaleqMax3Element.textContent = (value * 0.75).toFixed(1);
                this.scaleqMax2Element.textContent = (value * 0.5).toFixed(1);
                this.scaleqMax1Element.textContent = (value * 0.25).toFixed(1);
                break;

            default:
                console.error("Invalid type!");
                break;
        }
    }

    render() {
        return (
            <div id={this.props.id}>
                <ReactSVG
                    style={{ textAlign: "center" }}
                    beforeInjection={(svg) => {
                        svg.setAttribute("style", "display: block;position: absolute;height: 70%;width: 84%;top: 100px; left: 8%;");
                    }}
                    src={svgSrc}
                    renumerateIRIElements={false}
                    afterInjection={() => {
                        this.initElements();
                        if (this.props.onInit) {
                            this.props.onInit();
                        }
                    }}
                />
            </div>
        );
    }
}
