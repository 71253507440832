import Export from "highcharts-export-csv";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

import { DateFromToPickerDialog } from "../../components/dateFromToPickerDialog/dateFromToPickerDialog";
import { NContainer } from "../../components/nContainer/nContainer";
import { restAPIget, getDefaultChartOptions, setInitialDataToGraphSeries, getBrandedColor, deleteGraphSeries } from "../../shared/utils";
import SharedData from "../../shared/sharedData";
import { CommandButton } from "office-ui-fabric-react";

import momentRounder from "../../shared/momentRounder";
momentRounder(moment);

Export(Highcharts);

export class SBSHistoryTape extends React.Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.datePickerDialogRef = React.createRef();

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "SIJ Group TAPE", key: "sijgroupTape", isCurrentItem: true },
        ]);

        this.workgroups = [
            {
                key: "acroni",
                text: "ACRONI",
                iconProps: { iconName: "Group" },
                onClick: this.setGroup.bind(this),
            },
            {
                key: "ravne",
                text: "RAVNE",
                iconProps: { iconName: "Group" },
                onClick: this.setGroup.bind(this),
            },
            {
                key: "all",
                text: "SIJ GROUP",
                iconProps: { iconName: "HomeGroup" },
                onClick: this.setGroup.bind(this),
            },
        ];

        this.state = {
            graphIsLoading: true,
            momentFrom: moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").startOf("month"),
            momentTo: moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").endOf("month"),
            dataViewType: "CURRENT_MONTH",
            selectedWorkgroup: {
                key: "all",
                text: "SIJ GROUP",
            },
        };

        this.abortController = new AbortController();
        this.chartOptions = getDefaultChartOptions({
            chart: {
                height: "555px",
                stacking: "normal",
            },
            export: {
                filename: "SIJ Group TAPE",
                csv: {
                    itemDelimiter: ';',
                    decimalPoint: ','
                }
            },
            yAxis: [
                {
                    id: "tapeAxe",
                    name: "TAPE (%)",
                    min: 0,
                    softMax: 100,
                },
            ],
            series: [
                {
                    id: "tape",
                    name: "TAPE",
                    type: "area",
                    step: "left",
                    color: getBrandedColor("blue"),
                    borderColor: "rgba(0,0,255, 0.1)",
                    valueSuffix: "%",
                    valueDecimals: 0,
                    pointPlacement: "between",
                    lineWidth: 2,
                    fillOpacity: 0.95,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0.95,
                        },
                        stops: [
                            [0, "rgba(0, 89, 255, 0.1)"],
                            [1, "rgba(0, 89, 255, 0.0)"],
                        ],
                    },
                },
            ],
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this._isMounted = false;
    }

    setGroup(event, val) {
        this.setState(
            {
                selectedWorkgroup: {
                    key: val.key,
                    text: val.text,
                },
                graphIsLoading: true,
            },
            () => {
                deleteGraphSeries({ chart: this.chartRef.current.chart });
                this.loadData();
            }
        );
    }

    loadData() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        this.setState({ graphIsLoading: true }, () => {
            const endOfInterval = this.state.momentTo.unix();
            const startOfHour = moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").floor(15, "minutes").unix();

            restAPIget(
                `/tape/cumulative/${this.state.selectedWorkgroup.key}/${this.state.momentFrom.unix()}/${Math.min(endOfInterval, startOfHour)}/900`,
                this.abortController.signal,
                {
                    route: "",
                }
            ).then((result) => {
                if (result.isOk && this._isMounted && result.data) {
                    setInitialDataToGraphSeries(
                        result.data.map((d) => {
                            return {
                                timestamp: d.timestamp * 1000,
                                tape: Math.abs(d.tapeCumulative),
                            };
                        }),
                        ["tape"],
                        this.chartRef.current.chart
                    );

                    this.setState({ graphIsLoading: false, averageTape: Math.abs(result.data[result.data.length - 1].tapeCumulative) });
                }
            });
        });
    }

    render() {
        return (
            <>
                <DateFromToPickerDialog
                    ref={this.datePickerDialogRef}
                    onContinue={(dialogState) => {
                        this.setState(
                            {
                                dataViewType: "CUSTOM",
                                momentFrom: moment.tz(
                                    {
                                        year: dialogState.dateFrom.getFullYear(),
                                        month: dialogState.dateFrom.getMonth(),
                                        date: dialogState.dateFrom.getDate(),
                                    },
                                    "Europe/Ljubljana"
                                ),
                                momentTo: moment
                                    .tz(
                                        {
                                            year: dialogState.dateTo.getFullYear(),
                                            month: dialogState.dateTo.getMonth(),
                                            date: dialogState.dateTo.getDate(),
                                        },
                                        "Europe/Ljubljana"
                                    )
                                    .endOf("day"),
                            },
                            () => {
                                deleteGraphSeries({ chart: this.chartRef.current.chart });
                                this.loadData();
                            }
                        );
                    }}
                />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title={
                                    <CommandButton
                                        menuProps={{ items: this.workgroups }}
                                        styles={{ label: { fontSize: "16px", fontWeight: "bold" } }}
                                        iconProps={{ iconName: "" }}
                                        title="workspacePicker"
                                        text={this.state.selectedWorkgroup?.text + " average TAPE"}
                                        onChange={(ev, val) => {
                                            console.log(ev, val);
                                        }}
                                    />
                                }
                                isLoading={this.state.graphIsLoading}
                                rightItems={[
                                    {
                                        type: "chooseOption",
                                        key: "ChooseViewType",
                                        selectedOption: this.state.dataViewType,
                                        options: [
                                            {
                                                key: "CURRENT_MONTH",
                                                text: "Current month",
                                                iconProps: {
                                                    iconName: "ReminderTime",
                                                },
                                                onClick: () => {
                                                    this.setState(
                                                        {
                                                            dataViewType: "CURRENT_MONTH",
                                                            momentFrom: moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").startOf("month"),
                                                            momentTo: moment.unix(SharedData.get("timestamp")).tz("Europe/Ljubljana").endOf("month"),
                                                        },
                                                        () => {
                                                            deleteGraphSeries({ chart: this.chartRef.current.chart });
                                                            this.loadData();
                                                        }
                                                    );
                                                },
                                            },
                                            {
                                                key: "PREVIOUS_MONTH",
                                                text: "Previous month",
                                                iconProps: {
                                                    iconName: "ReminderTime",
                                                },
                                                onClick: () => {
                                                    this.setState(
                                                        {
                                                            dataViewType: "PREVIOUS_MONTH",
                                                            momentFrom: moment
                                                                .unix(SharedData.get("timestamp"))
                                                                .tz("Europe/Ljubljana")
                                                                .startOf("month")
                                                                .subtract(1, "month"),
                                                            momentTo: moment
                                                                .unix(SharedData.get("timestamp"))
                                                                .tz("Europe/Ljubljana")
                                                                .startOf("month")
                                                                .subtract(1, "month")
                                                                .endOf("month"),
                                                        },
                                                        () => {
                                                            deleteGraphSeries({ chart: this.chartRef.current.chart });
                                                            this.loadData();
                                                        }
                                                    );
                                                },
                                            },
                                            {
                                                key: "CUSTOM",
                                                text: "Custom period",
                                                iconProps: {
                                                    iconName: "ReminderTime",
                                                },
                                                onClick: () => {
                                                    this.setState({ dataViewType: "CUSTOM" });
                                                    // this.chartRef.current.chart.series.forEach(serie => {
                                                    //     serie.setData([]);
                                                    // });
                                                    // this.loadData();

                                                    this.datePickerDialogRef.current._showDialog(
                                                        new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
                                                        new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
                                                        new Date(2020, 3, 17)
                                                    );
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            >
                                <div
                                    className="number"
                                // style={{ backgroundColor: getBrandedColor("orange") }}
                                >
                                    {this.state.averageTape?.toFixed(2)}
                                    <span>%</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title={this.state.selectedWorkgroup?.text + " TAPE chart"}
                                isLoading={this.state.graphIsLoading}
                                style={{ maxHeight: "600px" }}
                                rightItems={[
                                    {
                                        key: "ExportToCSV",
                                        type: "actionButton",
                                        text: "CSV",
                                        icon: "DownloadDocument",
                                        onClick: function () {
                                            this.chartRef.current.chart.downloadCSV();
                                        }.bind(this),
                                    },
                                ]}
                            >
                                <div id="chart">
                                    <HighchartsReact highcharts={Highcharts} ref={this.chartRef} options={this.chartOptions} />
                                </div>
                            </NContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
