import { Depths } from '@uifabric/fluent-theme';
import { FontIcon, ContextualMenuItemType, CommandButton, TooltipHost } from 'office-ui-fabric-react';
import Boost from 'highcharts/modules/boost';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';

import { addDataPointsToGraphSeries, setInitialDataToGraphSeries, getBrandedColor, restAPIget, restAPIpost, getDefaultChartPlotline, generateUUID, hasReadAccess, hasWriteAccess } from '../../shared/utils';
import { IAccept } from '../../components/iAccept/iaccept';
import { NBar } from '../../components/nBar/nBar';
import { NContainer } from '../../components/nContainer/nContainer';
import { NewRampDialog } from '../../components/newRampDialog/newRampDialog';
import EventEmitter from '../../shared/EventEmitter';
import Notify from '../../shared/notify';
import SharedData from '../../shared/sharedData';

Boost(Highcharts);

export class TalumPower extends React.Component {

    constructor(props) {
        super(props);

        this.WS_CHANNEL = SharedData.deepGet("wsChannels", "assetMeasurement", "channelId");
        this.PAGE_ID = generateUUID();
        this.SHOW_PRIORITIES = hasReadAccess("assetPriorities");
        this.SHOW_ASSET_SETTINGS = hasWriteAccess("assetSettings");

        this.props.setPath([
            {
                text: 'App', key: 'app', onClick: () => {
                    console.log("I was clicked")
                }
            },
            { text: 'Talum dashboard', key: 'talumDashboard', isCurrentItem: true }
        ]);

        this.abortController = new AbortController();

        this.mostRecentMeasureObj = null; //USED FOR SVGS 

        this.iAcceptReference = React.createRef();
        this.newRampDialogRef = React.createRef();

        this.chartRef = React.createRef();
        this.chartCanAppendData = false;
        this.chartMinExtremeTS = 0;
        this.chartShowIntervalOfMins = 15;
        this.chartGraphType = '15M';
        this.chartIsZoomed = false;

        this.chartOptions = {
            chart: {
                zoomType: 'x',
                type: "line",
                height: '355px',
                events: {
                    selection: function (event) {
                        this.chartIsZoomed = true;
                    }.bind(this)
                }
            },
            boost: {
                useGPUTranslations: false
            },
            time: {
                timezone: 'Europe/Ljubljana'
            },
            title: {
                text: undefined
            },

            credits: {
                enabled: false
            },

            legend: {
                itemStyle: {
                    color: 'white'
                },
                itemHoverStyle: {
                    color: '#5dbb46'
                }
            },

            tooltip: {
                crosshairs: [
                    true,
                    false
                ],
                shared: true,
                valueDecimals: 2,
                backgroundColor: 'rgba(27, 35, 55, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: 'white'
                    }
                },
                events: {
                    setExtremes: function (e) {
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            this.chartIsZoomed = false;
                        }
                    }.bind(this)
                }
            },
            yAxis: [
                {
                    // id: 'power',
                    title: {
                        text: "Power (MW)",
                        style: {
                            color: 'white'
                        }
                    },
                    gridLineColor: 'darkgray',
                    gridLineWidth: 0.5,
                    labels: {
                        style: {
                            color: 'white'
                        }
                    },

                    startOnTick: false,
                    endOnTick: false
                }
            ],
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                },
                area: {
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    boostThreshold: 5000,
                    states: {
                        hover:
                        {
                            enabled: false
                        }
                    },
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                }
            },
            series: [
                {
                    id: 'safrr',
                    name: 'AFRR setpoint NGEN',
                    type: 'line',
                    color: '#bb7e0e',
                    lineWidth: 1,
                    zIndex: 4
                }, {
                    id: 'pafrr',
                    name: 'Actual AFRR power',
                    type: 'line',
                    color: '#5dbb46',
                    lineWidth: 2,
                    zIndex: 4,
                },
                {
                    id: 'tp',
                    name: 'Targeted power',
                    type: 'line',
                    color: 'skyblue',
                    lineWidth: 1,
                    zIndex: 3,
                },
                {
                    id: 'sramp',
                    name: 'SRAMP',
                    type: 'line',
                    color: 'orangered',
                    lineWidth: 1,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'sngendyn',
                    name: 'SNGEN DYN',
                    type: 'line',
                    color: 'yellow',
                    lineWidth: 1,
                    tooltip: {
                        valueSuffix: 'MW'
                    }
                },
                {
                    id: 'apafrr',
                    name: 'APAFRR',
                    type: 'line',
                    visible: false,
                    color: 'rgb(241, 92, 128)',
                    lineWidth: 1,
                }, {
                    id: 'anafrr',
                    name: 'ANAFRR',
                    type: 'line',
                    visible: false,
                    color: 'rgb(228, 211, 84)',
                    lineWidth: 1,
                },
                {
                    id: 'an',
                    name: 'Max negative AFRR',
                    type: 'area',
                    color: '#cc3e44',
                    fillOpacity: 0.95,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0.95,
                            x2: 0,
                            y2: 0
                        },
                        stops: [
                            [0, 'rgba(255, 0, 0, 0.2)'],
                            [1, 'rgba(255, 0, 0, 0.05)']
                        ]
                    },
                    lineWidth: 1,
                    zIndex: 3
                }, {
                    id: 'ap',
                    name: 'Max positive AFRR',
                    type: 'area',
                    color: '#3477aa',
                    fillOpacity: 0.95,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0.95
                        },
                        stops: [
                            [0, 'rgba(0, 89, 255, 0.2)'],
                            [1, 'rgba(0, 89, 255, 0.05)']
                        ]
                    },
                    lineWidth: 1,
                    zIndex: 3
                },
                {
                    id: 's',
                    name: 'Setpoint',
                    type: 'line',
                    color: 'green',
                    lineWidth: 1,
                    zIndex: 3
                },
                {
                    id: 'p',
                    name: 'Current power',
                    type: 'line',
                    color: '#7cb5ec',
                    lineWidth: 1,
                    zIndex: 4,
                    visible: false,
                },
                {
                    id: 'b',
                    name: 'Base line',
                    type: 'line',
                    color: 'orange',
                    lineWidth: 1,
                    zIndex: 3,
                    visible: false,
                }, {
                    id: 'b5',
                    name: 'Base line 5',
                    type: 'line',
                    color: 'orangered',
                    lineWidth: 1,
                    zIndex: 3,
                    visible: false,
                }
            ],
        };
        //#endregion

        this.state = {
            chartIsLoading: true,
            chartGraphType: '15M',

            valuesAreLoading: true,

            assetStatuses: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getInitialData();

        EventEmitter.subscribe('APPLICATION_TIME', (value) => {
            this.drawCurrentTimeOnGraphs(value * 1000);
        }, 'talumPowerDashbardTime');
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.abortController.abort();
        Notify.removeNotificationsWithSrc(this.PAGE_ID);
        EventEmitter.unsubscribe('APPLICATION_TIME', 'talumPowerDashbardTime');
        EventEmitter.unsubscribe(`${this.WS_CHANNEL}/unset`, this.PAGE_ID);
        EventEmitter.dispatch('unsubscribeFrom', [this.WS_CHANNEL]);
    }

    getInitialData() {
        restAPIget('/measurements/raw/2/15', this.abortController.signal).then(result => {
            if (result.isOk) {
                if (result.data.length > 0) {
                    this.mostRecentMeasureObj = result.data[result.data.length - 1];

                    if (this.chartRef.current && this.chartRef.current.chart) {
                        setInitialDataToGraphSeries(
                            result.data.map(
                                d => {
                                    return {
                                        timestamp: Number(d.timestamp),
                                        p: Number(d.p) / 1000,
                                        tp: Number(d.tp) / 1000,
                                        pafrr: Number(d.pafrr) / 1000,
                                        safrr: Number(d.safrr) / 1000,
                                        sngendyn: Number(d.sngendyn),
                                        s: Number(d.s) / 1000,
                                        b: Number(d.b) / 1000,
                                        b5: Number(d.b5) / 1000,
                                        ap: Number(d.ap) / 1000,
                                        an: Number(d.an) / 1000,
                                        anafrr: Number(d.anafrr) / 1000,
                                        apafrr: Number(d.apafrr) / 1000,
                                        sramp: Number(d.sramp)
                                    };
                                }
                            ),
                            ['p', 'tp', 'pafrr', 'safrr', 's', 'b', 'b5', 'an', 'ap', 'apafrr', 'anafrr', 'sramp', 'sngendyn'],
                            this.chartRef.current.chart
                        );
                    }
                }

                this.chartCanAppendData = true;

                let newState = {
                    chartIsLoading: false,
                    valuesAreLoading: false,

                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        deviceActivated: {
                            code: 'A',
                            source: 'NGEN',
                            color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        },
                        deviceReady: {
                            code: 'R',
                            source: 'TALUM',
                            color: this.mostRecentMeasureObj.devrdy ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.devrdy ? 'Ready' : 'NOT ready'
                        },
                        ...this.SHOW_PRIORITIES && {
                            priority_apafrr: {
                                code: (<FontIcon iconName="Up" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Positive priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_pos,
                                shortDescription: (<><FontIcon iconName="Add" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_pos}</>),
                            },
                            priority_anafrr: {
                                code: (<FontIcon iconName="Down" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Negative priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_neg,
                                shortDescription: (<><FontIcon iconName="Remove" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_neg}</>),
                            }
                        }

                    }
                };

                if (this.mostRecentMeasureObj.statusafrr !== undefined && this.mostRecentMeasureObj.statusafrr !== null)
                    newState.underControl = this.mostRecentMeasureObj.statusafrr;

                if (!isNaN(this.mostRecentMeasureObj.pafrr))
                    newState.pafrr = (this.mostRecentMeasureObj.pafrr / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.safrr))
                    newState.safrr = (this.mostRecentMeasureObj.safrr / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.sngendyn))
                    newState.sngendyn = (this.mostRecentMeasureObj.sngendyn).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.an))
                    newState.an = (this.mostRecentMeasureObj.an / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.ap))
                    newState.ap = (this.mostRecentMeasureObj.ap / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.b))
                    newState.b = (this.mostRecentMeasureObj.b / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.b) && !isNaN(this.mostRecentMeasureObj.safrr))
                    newState.b_minus_safrr = ((this.mostRecentMeasureObj.b - this.mostRecentMeasureObj.safrr) / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.safrr) && !isNaN(this.mostRecentMeasureObj.pafrr))
                    newState.safrr_minus_pafrr = ((this.mostRecentMeasureObj.safrr - this.mostRecentMeasureObj.pafrr) / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.safrr))
                    newState.p_minus_safrr = ((this.mostRecentMeasureObj.p - this.mostRecentMeasureObj.safrr) / 1000).toFixed(2);


                this.setState(newState, () => {
                    this.props.setPath(null, this.state.assetStatuses);
                    this.subsbribeToWebsocket();
                });
            }
            else if (result.error.code !== 20) {
                Notify.error({ message: result.error.message, src: this.PAGE_ID });
            }
        });
    }

    subsbribeToWebsocket() {
        EventEmitter.dispatch('subscribeTo', [this.WS_CHANNEL]);
        EventEmitter.subscribe(`${this.WS_CHANNEL}/unset`, (newValue) => {
            if (this._isMounted && newValue.data.length > 0) {
                this.mostRecentMeasureObj = newValue.data[0];

                if (this.chartRef.current && this.chartRef.current.chart && this.chartCanAppendData) {
                    addDataPointsToGraphSeries(
                        //DATA IS DESC SORTED, BUT WE WANT TO MAINTAIN ASC SORTING FOR HIGHCHARTS (because reasons)
                        newValue.data.reverse().map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    p: Number(d.p) / 1000,
                                    tp: Number(d.tp) / 1000,
                                    pafrr: Number(d.pafrr) / 1000,
                                    safrr: Number(d.safrr), // because ws sends raw db data we do not divide
                                    sngendyn: Number(d.sngendyn),
                                    s: Number(d.s),// because ws sends raw db data we do not divide
                                    b: Number(d.b) / 1000,
                                    b5: Number(d.b5) / 1000,
                                    ap: Number(d.ap) / 1000,
                                    an: Number(d.an) / 1000,
                                    anafrr: Number(d.anafrr) / 1000,
                                    apafrr: Number(d.apafrr) / 1000,
                                    sramp: Number(d.sramp)
                                };
                            }
                        ),
                        ['p', 'tp', 'pafrr', 'safrr', 's', 'b', 'b5', 'an', 'ap', 'apafrr', 'anafrr', 'sramp', 'sngendyn'],
                        this.chartRef.current.chart,
                        this.chartMinExtremeTS
                    );
                }

                let newState = {
                    assetStatuses: !this.mostRecentMeasureObj ? {} : {
                        deviceActivated: {
                            code: 'A',
                            source: 'NGEN',
                            color: this.mostRecentMeasureObj.statusafrr ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.statusafrr ? 'AFRR activated' : 'AFRR NOT activated'
                        },
                        deviceReady: {
                            code: 'R',
                            source: 'TALUM',
                            color: this.mostRecentMeasureObj.devrdy ? getBrandedColor('green') : getBrandedColor('red'),
                            description: this.mostRecentMeasureObj.devrdy ? 'Ready' : 'NOT ready'
                        },
                        ...this.SHOW_PRIORITIES && {
                            priority_apafrr: {
                                code: (<FontIcon iconName="Up" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Positive priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_pos_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_pos,
                                shortDescription: (<><FontIcon iconName="Add" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_pos}</>),
                            },
                            priority_anafrr: {
                                code: (<FontIcon iconName="Down" style={{ fontSize: '0.8em' }} />),
                                source: 'NGEN',
                                color: this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? getBrandedColor('orange') : getBrandedColor('green'),
                                description: "Negative priority: " + (this.mostRecentMeasureObj.afrr_manualBidding_neg_enabled !== true ? 'manual ' : 'auto ') + this.mostRecentMeasureObj.afrr_priority_neg,
                                shortDescription: (<><FontIcon iconName="Remove" style={{ fontSize: '0.8em' }} />&nbsp;<FontIcon iconName="ScrollUpDown" style={{ fontSize: '0.8em' }} />&nbsp;{this.mostRecentMeasureObj.afrr_priority_neg}</>),
                            }
                        }
                    }
                };

                if (this.mostRecentMeasureObj.statusafrr !== undefined && this.mostRecentMeasureObj.statusafrr !== null)
                    newState.underControl = this.mostRecentMeasureObj.statusafrr;

                if (!isNaN(this.mostRecentMeasureObj.pafrr))
                    newState.pafrr = (this.mostRecentMeasureObj.pafrr / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.safrr))
                    newState.safrr = (this.mostRecentMeasureObj.safrr).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.sngendyn))
                    newState.sngendyn = (this.mostRecentMeasureObj.sngendyn).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.an))
                    newState.an = (this.mostRecentMeasureObj.an / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.ap))
                    newState.ap = (this.mostRecentMeasureObj.ap / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.b))
                    newState.b = (this.mostRecentMeasureObj.b / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.b) && !isNaN(this.mostRecentMeasureObj.safrr))
                    newState.b_minus_safrr = ((this.mostRecentMeasureObj.b - this.mostRecentMeasureObj.safrr * 1000) / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.safrr) && !isNaN(this.mostRecentMeasureObj.pafrr))
                    newState.safrr_minus_pafrr = ((this.mostRecentMeasureObj.safrr * 1000 - this.mostRecentMeasureObj.pafrr) / 1000).toFixed(2);

                if (!isNaN(this.mostRecentMeasureObj.p) && !isNaN(this.mostRecentMeasureObj.safrr))
                    newState.p_minus_safrr = ((this.mostRecentMeasureObj.p - this.mostRecentMeasureObj.safrr * 1000) / 1000).toFixed(2);


                this.setState(newState, () => {
                    this.props.setPath(null, this.state.assetStatuses)
                });
            }
        }, this.PAGE_ID);
    }

    reinitChartData() {
        this.chartCanAppendData = false;
        this.setState({
            chartIsLoading: true
        });

        this.chartRef.current.chart.xAxis[0].setExtremes(undefined, undefined);

        restAPIget(`/measurements/raw/2/${this.chartShowIntervalOfMins}`, this.abortController.signal).then(result => {
            if (result.isOk) {
                if (result.data.length > 0) {
                    setInitialDataToGraphSeries(
                        result.data.map(
                            d => {
                                return {
                                    timestamp: Number(d.timestamp),
                                    p: Number(d.p) / 1000,
                                    tp: Number(d.tp) / 1000,
                                    pafrr: Number(d.pafrr) / 1000,
                                    safrr: Number(d.safrr) / 1000,
                                    sngendyn: Number(d.sngendyn),
                                    s: Number(d.s) / 1000,
                                    b: Number(d.b) / 1000,
                                    b5: Number(d.b5) / 1000,
                                    ap: Number(d.ap) / 1000,
                                    an: Number(d.an) / 1000,
                                    anafrr: Number(d.anafrr) / 1000,
                                    apafrr: Number(d.apafrr) / 1000,
                                    sramp: Number(d.sramp)
                                };
                            }
                        ),
                        ['p', 'tp', 'pafrr', 'safrr', 's', 'b', 'b5', 'an', 'ap', 'apafrr', 'anafrr', 'sramp', 'sngendyn'],
                        this.chartRef.current.chart
                    );
                }
            }

            this.chartCanAppendData = this.chartShowIntervalOfMins <= 60;
            this.setState({
                chartIsLoading: false
            });
        });
    }

    toggleAssetAvailability(additionalValue) {
        restAPIpost(
            '/asset/2/status',
            { pw: "GESLO123", activated: !this.state.underControl }
        ).then(() => {
            Notify.info({ message: "Action sent, please wait few seconds to see if it works, the data is shown with delay!" });
        });
    }

    setNewRamp(value) {
        restAPIpost('/asset/2/ramp', { max_power_ramp_step: value.rampValue }).then(result => {
            if (result.isOk) {
                Notify.success({ message: "New ramp succesfully saved!" });
            }
            else {
                Notify.error({ message: "New ramp failed to save, try again and/or contact administrator!" });
            }
        });
    }

    drawCurrentTimeOnGraphs(Now) {
        if (this._isMounted && this.chartRef.current && this.chartRef.current.chart) {
            this.chartRef.current.chart.xAxis[0].removePlotLine('timeNow');

            if (this.chartShowIntervalOfMins <= 60) {
                this.chartMinExtremeTS = Now - this.chartShowIntervalOfMins * 60/*s*/ * 1000/*ms*/;

                if (!this.chartIsZoomed) {
                    this.chartRef.current.chart.xAxis[0].setExtremes(undefined, Now + 5000);
                }

                this.chartRef.current.chart.xAxis[0].addPlotLine(
                    getDefaultChartPlotline({
                        id: 'timeNow',
                        label: 'Now',
                        value: Now,
                    })
                );
            }
        }
    }

    render() {
        let assetStatusesUI = Object.keys(this.state.assetStatuses).map((statusKey, statusIndex, statusArray) => {
            let theCurrentStatus = this.state.assetStatuses[statusKey];

            if (theCurrentStatus.shortDescription) {
                return (
                    <TooltipHost
                        // content={theCurrentStatus.description}
                        // tooltipProps={() => {
                        //     return <> {theCurrentStatus.shortDescription}  (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source}) </>
                        // }}
                        tooltipProps={{
                            onRenderContent: () => {
                                return <> {theCurrentStatus.description}  (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source}) </>
                            }
                        }}
                        key={statusKey}
                    >
                        <span
                            style={{
                                backgroundColor: theCurrentStatus.color,
                                padding: '3px 6px',
                                boxShadow: Depths.depth4,
                                cursor: 'default',
                                whiteSpace: 'nowrap'
                            }}
                        >{theCurrentStatus.shortDescription}</span>

                        {statusIndex !== statusArray.length - 1 && <>&nbsp;</>}
                    </TooltipHost>
                );
            }
            else {
                return (
                    <span key={statusKey}>
                        <span
                            style={{
                                backgroundColor: theCurrentStatus.color,
                                padding: '3px 6px',
                                boxShadow: Depths.depth4,
                                cursor: 'default',
                                whiteSpace: 'nowrap'
                            }}
                        >{theCurrentStatus.description} (<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> {theCurrentStatus.source})</span>

                        {statusIndex !== statusArray.length - 1 && <>&nbsp;</>}
                    </span>
                );
            }
        });

        return (
            <>
                <IAccept ref={this.iAcceptReference} strictMode="true" onContinue={this.toggleAssetAvailability.bind(this)}></IAccept>

                <NewRampDialog ref={this.newRampDialogRef} assetId="2" onContinue={this.setNewRamp.bind(this)}></NewRampDialog>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NBar>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ padding: '0 8px' }}>
                                        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Talum dashboard</span>
                                    </div>

                                    <div className="ms-Grid-col ms-xxl6 ms-hiddenXlDown" style={{ textAlign: "center", padding: '0' }}>
                                        {assetStatusesUI}
                                    </div>

                                    <div className="ms-Grid-col ms-sm6 ms-xxl3" style={{ textAlign: "right", padding: '0 12px' }}>
                                        {this.SHOW_ASSET_SETTINGS &&
                                            <CommandButton iconProps={{ iconName: 'SetAction' }} text="Asset actions" menuProps={{
                                                items: [
                                                    {
                                                        key: 'enableDisable',
                                                        text: (<><span>{this.state.underControl ? "Disable AFRR" : "Enable AFRR"} </span> &nbsp;<span>(<FontIcon iconName="OpenSource" style={{ fontSize: '0.8em' }} /> NGEN)</span></>),
                                                        iconProps: { iconName: this.state.underControl === 'true' ? 'Blocked' : 'ReturnToSession' },
                                                        disabled: this.state.underControl === null,
                                                        onClick: () => {
                                                            this.iAcceptReference.current._showDialog();
                                                        }
                                                    },

                                                    {
                                                        key: 'apManual',
                                                        text: (<><span>Set new ramp</span></>),
                                                        iconProps: { iconName: 'ChartYAngle' },
                                                        onClick: () => {
                                                            this.newRampDialogRef.current._showDialog();
                                                        }
                                                    }
                                                ]
                                            }} />
                                        }
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-hiddenXxlUp" style={{ textAlign: "left", padding: '0 8px' }}>
                                        {assetStatusesUI}
                                    </div>
                                </div>
                            </NBar>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <NContainer
                                title="Power chart"
                                isLoading={this.state.chartIsLoading}
                                style={{
                                    height: this.state.powerChartExpanded ? '700px' : '400px',
                                    maxHeight: 'unset'
                                }}
                                rightItems={[
                                    {
                                        key: 'graphIsLive',
                                        type: 'customText',
                                        style: { color: ['NOW', '15M', '30M', '60M'].includes(this.state.chartGraphType) ? getBrandedColor('green') : getBrandedColor('red') },
                                        text: <><FontIcon iconName={['NOW', '15M', '30M', '60M'].includes(this.state.chartGraphType) ? 'Streaming' : 'StreamingOff'} style={{ fontSize: '1.3em', lineHeight: '1em', verticalAlign: 'middle', marginTop: '-6px' }} /></>
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        type: 'chooseOption',
                                        icon: "DateTime2",
                                        key: 'ChooseViewType',
                                        selectedOption: this.state.chartGraphType,
                                        options: [{
                                            key: 'NOW',
                                            text: 'Now',
                                            iconProps: {
                                                iconName: 'ReminderTime'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 2;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: 'NOW' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: 'TODAY',
                                            text: 'Today',
                                            iconProps: {
                                                iconName: 'GotoToday'
                                            },
                                            onClick: () => {
                                                let startOfDay = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').startOf('day');
                                                this.chartMinExtremeTS = 0;
                                                this.chartShowIntervalOfMins = moment.tz(SharedData.get('timestamp') * 1000, 'Europe/Ljubljana').diff(startOfDay, 'minutes');
                                                this.setState({ chartGraphType: 'TODAY' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: 'divider_1',
                                            itemType: ContextualMenuItemType.Divider
                                        },
                                        {
                                            key: '15M',
                                            text: 'Last 15 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 15;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '15M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: '30M',
                                            text: 'Last 30 minutes',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {

                                                this.chartShowIntervalOfMins = 30;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '30M' });
                                                this.reinitChartData();

                                            }
                                        },
                                        {
                                            key: '60M',
                                            text: 'Last hour',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 60;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '60M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: '180M',
                                            text: 'Last 3 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 180;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '180M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: '360M',
                                            text: 'Last 6 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 360;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '360M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: '720M',
                                            text: 'Last 12 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 720;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '720M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        {
                                            key: '1440M',
                                            text: 'Last 24 hours',
                                            iconProps: {
                                                iconName: 'BufferTimeAfter'
                                            },
                                            onClick: () => {
                                                this.chartShowIntervalOfMins = 1440;
                                                this.chartMinExtremeTS = 0;
                                                this.setState({ chartGraphType: '1440M' });
                                                this.reinitChartData();
                                            }
                                        },
                                        ]
                                    },
                                    {
                                        type: 'emptySpace'
                                    },
                                    {
                                        key: 'expandToggle',
                                        type: 'actionButton',
                                        icon: this.state.powerChartExpanded ? 'MiniContractMirrored' : 'MiniExpandMirrored',
                                        title: this.state.powerChartExpanded ? 'Collapse' : 'Expand',
                                        onClick: () => {
                                            this.setState(prevstate => {
                                                return {
                                                    powerChartExpanded: !(prevstate.powerChartExpanded || false)
                                                }
                                            }, () => {
                                                let newHeight = this.state.powerChartExpanded ? 655 : 355;
                                                this.chartRef.current.chart.setSize(null, newHeight, false);
                                                document.getElementById('powerChartId').scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: "center",
                                                });
                                            })
                                        }
                                    }
                                ]}
                            >
                                <div id="powerChartId">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        ref={this.chartRef}
                                        options={this.chartOptions}
                                    />
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="NGEN AGC request AFRR"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor('orange') }}>
                                    {this.state.safrr}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Current actual AFRR"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor('green') }}>
                                    {this.state.pafrr}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Current (+) max AFRR"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor('blue') }}>
                                    {this.state.ap}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Current (-) max AFRR"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor('red') }}>
                                    {this.state.an}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Current base power"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" >
                                    {this.state.b}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Base power - NGEN AGC request"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" >
                                    {this.state.b_minus_safrr}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="NGEN AGC request - Actual AFRR"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" >
                                    {this.state.safrr_minus_pafrr}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="Actual power - NGEN AGC request"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" >
                                    {this.state.p_minus_safrr}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xxl3">
                            <NContainer
                                title="NGEN Market (dynamic)"
                                isLoading={this.state.valuesAreLoading}
                                loadingSpinnerPosition="left"
                            >
                                <div className="number" style={{ backgroundColor: getBrandedColor('yellow') }}>
                                    {this.state.sngendyn}<span>MW</span>
                                </div>
                            </NContainer>
                        </div>
                    </div>
                </div >
            </>
        );
    }

}