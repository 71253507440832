import { FontIcon } from "office-ui-fabric-react";
import React from "react";

/**
 * @export
 * @param {Object} props
 * @param {string} props.username Username
 * @param {function} props.logOut Logout event to execute
 * @return
 */
export function AboutUser(props) {
    const { username, logOut } = props;
    return (
        <div style={{ marginBottom: "20px" }}>
            <hr className="centerAlignedHr" />

            <div
                style={{
                    color: "white",
                    padding: "0px 0px 0px 10px",
                    textAlign: "left",
                }}
            >
                <FontIcon iconName="Contact" style={{ fontSize: "0.8em" }} />
                &nbsp;
                {username}
                &nbsp;
                <span style={{ color: "dodgerblue", cursor: "pointer", "&:hover": { color: "deepskyblue" } }} onClick={logOut}>
                    &gt;Logout&lt;
                </span>
            </div>

            <div
                style={{
                    color: "gray",
                    textAlign: "left",
                    fontStyle: "oblique",
                    padding: "0px 10px 10px 10px",
                    fontSize: "0.8em",
                    // marginTop: '-14px'
                }}
            >
                Powered by <span style={{ color: "lightgray" }}>N</span>
                <span style={{ color: "#5fba46" }}>GEN</span>
            </div>

            {/* <hr className="leftAlignedHr" /> */}
        </div>
    );
}
