/**
 * A Moment round plugin
 *
 * Author:   ALexs
 */
(function (factory) {
    if (typeof module === 'object' && module.exports) {
        module.exports = factory;
    } else {
        // factory(Moment);
    }
})(function (Moment) {
    function magic(_this, precision, key, direction) {
        var methods = {
            hours: { 'name': 'Hours', 'maxValue': 24 },
            minutes: { 'name': 'Minutes', 'maxValue': 60 },
            seconds: { 'name': 'Seconds', 'maxValue': 60 },
            milliseconds: { 'name': 'Milliseconds', 'maxValue': 1000 }
        };
        var keys = {
            'mm': methods.milliseconds.name,
            'milliseconds': methods.milliseconds.name,
            'Milliseconds': methods.milliseconds.name,
            's': methods.seconds.name,
            'seconds': methods.seconds.name,
            'Seconds': methods.seconds.name,
            'm': methods.minutes.name,
            'minutes': methods.minutes.name,
            'Minutes': methods.minutes.name,
            'H': methods.hours.name,
            'h': methods.hours.name,
            'hours': methods.hours.name,
            'Hours': methods.hours.name
        };
        var value = 0;
        var rounded = false;
        var subRatio = 1;
        var maxValue;

        // make sure key is plural
        if (key.length > 1 && key !== 'mm' && key.slice(-1) !== 's') {
            key += 's';
        }
        key = keys[key].toLowerCase();

        //control
        if (!methods[key]) {
            throw new Error('The value to round is not valid. Possibles ["hours", "minutes", "seconds", "milliseconds"]');
        }

        var get = 'get' + methods[key].name;
        var set = 'set' + methods[key].name;

        for (var k in methods) {
            if (k === key) {
                value = _this._d[get]();
                maxValue = methods[k].maxValue;
                rounded = true;
            } else if (rounded) {
                subRatio *= methods[k].maxValue;
                value += _this._d['get' + methods[k].name]() / subRatio;
                _this._d['set' + methods[k].name](0);
            }
        }

        value = Math[direction](value / precision) * precision;
        value = Math.min(value, maxValue);
        _this._d[set](value);

        return _this;
    }

    Moment.prototype.ceil = function (precison, key) {
        return magic(this, precison, key, 'ceil');
    };

    Moment.prototype.floor = function (precison, key) {
        return magic(this, precison, key, 'floor');
    };

    Moment.prototype.round = function (precison, key) {
        return magic(this, precison, key, 'round');
    };

});
