import { DatePicker, DialogFooter, DayOfWeek, Dialog, DialogType, ActionButton } from 'office-ui-fabric-react';
import moment from 'moment-timezone';
import React from 'react';

import { getDayPickerSettings, _datepicker_formatDate, _datepicker_parseDateFromString, restAPIpostAsJson } from '../../shared/utils';
import SharedData from '../../shared/sharedData';

export class NewActivationPlanDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: ''
        }
    }

    showDialog() {
        this.setState({
            date: new Date(SharedData.get('timeyear'), SharedData.get('timemonth'), SharedData.get('timedate')),
            hidden: false
        });
    }

    closeDialog(saveChanges) {
        if (saveChanges) {
            restAPIpostAsJson(
                '/activationplan',
                {
                    asset_id: 3,
                    type: "baseline",
                    points: [{
                        timestamp: moment.tz(
                            {
                                year: this.state.date.getFullYear(),
                                month: this.state.date.getMonth(),
                                date: this.state.date.getDate(),
                            }, 'Europe/Ljubljana').unix(),
                        power: 0
                    }]
                }
            ).then(result => {
                this.setState({ hidden: true }, () => {
                    if (this.props.onContinue) {
                        this.props.onContinue();
                    }
                });
            });
        }
        else {
            this.setState({ hidden: true });
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this.closeDialog.bind(this, false, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "New activation plan"
                    // subText: "Carefully enter the details and continue..."
                }}
                // minWidth='450px'
                modalProps={{
                    isBlocking: false,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false
                }}
            >
                <div className="ms-Grid" dir="ltr" style={{ margin: 0, padding: 0 }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <DatePicker
                                disabled={this.state.readOnly || this.state.mode === 'edit'}
                                onSelectDate={evt => {
                                    if (!evt) {
                                        return;
                                    }
                                    this.setState({ date: evt });
                                }}
                                isRequired={true}
                                value={this.state.date}
                                label="Activation plan date"
                                showMonthPickerAsOverlay={true}
                                today={new Date(SharedData.get('timeyear'), SharedData.get('timemonth'), SharedData.get('timedate'))}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDayPickerSettings()}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                allowTextInput={false}
                                formatDate={(someValue) => {
                                    return _datepicker_formatDate(someValue);
                                }}
                                parseDateFromString={(someValue) => {
                                    return _datepicker_parseDateFromString(this.state.date, someValue);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <DialogFooter>
                    <ActionButton
                        iconProps={{ iconName: 'Save' }}
                        onClick={this.closeDialog.bind(this, true)}
                        text="Save"
                        disabled={!this.state.date}
                    />
                    <ActionButton iconProps={{ iconName: 'Cancel' }} onClick={this.closeDialog.bind(this, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }


}