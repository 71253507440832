import React from 'react';
import { useDropzone } from 'react-dropzone';
import { NLabel } from '../nLabel/nLabel';

export function NFilePicker(props) {

    const _acceptedFileTypes = props.accept || '*/*';
    const _multipleFiles = props.multiple || false;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: _acceptedFileTypes,
        multiple: _multipleFiles,
        onDrop: files => props.onContinue(files)
    });

    const getColor = (props) => {
        if (isDragAccept) {
            return '#00e676';
        }
        if (isDragReject) {
            return '#ff1744';
        }
        if (isDragActive) {
            return '#2196f3';
        }
        return 'rgb(60, 64, 74)';
    }

    return (
        <>
            <NLabel>Choose file:</NLabel>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // padding: '1px',
                borderWidth: '2px',
                borderRadius: '2px',
                borderColor: getColor(props),
                borderStyle: 'dashed',
                // backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                transition: 'border .24s ease -in -out',
                fontSize: '0.8em',
                cursor: 'pointer'
            }}>
                <div
                    style={{
                        // background: 'red',
                        width: '100%',
                        padding: '2opx',
                        margin: '0',
                        textAlign: 'center'
                    }}
                    {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Selected file(s): {acceptedFiles.length > 0 ? acceptedFiles.map(f => f.name).join() : '/'}</p>
                    <p>{props.text || `Drag 'n' drop ${_acceptedFileTypes} file(s) here, or click to select file(s)`}</p>
                </div>
            </div>
        </>
    );
}
