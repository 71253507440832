import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, TextField, ChoiceGroup } from "office-ui-fabric-react";
import React from "react";

export class CustomScheduleDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            customSchedule: "",
            deviationType: "",
            allowedToContinue: false,
        };
    }

    _showDialog(deviationType, currentCustomSchedule) {
        this.setState({
            hidden: false,
            deviationType: deviationType,
            customSchedule: currentCustomSchedule,
            allowedToContinue: false,
        });
    }

    _closeDialog(iAmSure) {
        this.setState({ hidden: true }, () => {
            if (iAmSure) {
                this.props.onContinue(this.state);
            }
        });
    }

    allowedToContinue(state) {
        if (this.state.deviationType === "compareToSchedule") {
            return true;
        } else if (this.state.deviationType === "compareToCustom") {
            return this.state.customSchedule >= 0;
        } else {
            return false;
        }
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this._closeDialog.bind(this, false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Toggle schedule type",
                    // subText: "Set your new desired ramp!"
                }}
                modalProps={{
                    isBlocking: true,
                    dragOptions: true,
                    isDarkOverlay: true,
                    overlay: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <ChoiceGroup
                    required
                    label="Compare deviation to"
                    selectedKey={this.state.deviationType}
                    options={[
                        {
                            key: "compareToSchedule",
                            iconProps: { iconName: "ScheduleEventAction" },
                            text: "SCHEDULE",
                        },
                        {
                            key: "compareToCustom",
                            iconProps: { iconName: "CRMCustomerInsightsApp" },
                            text: "CUSTOM",
                        },
                    ]}
                    onChange={(evt, option) => {
                        this.setState({ deviationType: option.key });
                    }}
                />

                {this.state.deviationType === "compareToCustom" && (
                    <TextField
                        label="Custom hourly scheduled energy (MWh)"
                        type="number"
                        name="customSchedule"
                        min={0}
                        // required={true}
                        value={this.state.customSchedule}
                        onChange={(evt) => {
                            this.setState({
                                customSchedule: Math.abs(Number(evt.target.value)),
                            });
                        }}
                    />
                )}

                <DialogFooter>
                    <PrimaryButton disabled={!this.allowedToContinue()} onClick={this._closeDialog.bind(this, true)} text="Continue" />
                    <DefaultButton onClick={this._closeDialog.bind(this, false)} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }
}
