import Export from "highcharts-export-csv";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

import { NContainer } from "../../components/nContainer/nContainer";
import {
    restAPIget,
    getDefaultChartOptions,
    getBrandedColor,
    setInitialDataToGraphSeries,
    deleteGraphSeries,
    setDataPointsToGraphSeries,
} from "../../shared/utils";
import momentRounder from "../../shared/momentRounder";
import SharedData from "../../shared/sharedData";
import { CommandButton } from "office-ui-fabric-react";
import EventEmitter from "../../shared/EventEmitter";

Export(Highcharts);
momentRounder(moment);

export class SBSDriveByDay extends React.Component {
    constructor(props) {
        super(props);

        this.props.setPath([
            {
                text: "App",
                key: "app",
                onClick: () => {
                    console.log("I was clicked");
                },
            },
            { text: "SIJ Group drive", key: "sijgroupDrive", isCurrentItem: true },
        ]);

        this.workgroups = [
            {
                key: "acroni",
                text: "ACRONI",
                iconProps: { iconName: "Group" },
                ws: SharedData.deepGet("wsChannels", "driveAcroniByDay", "channelId"),
                onClick: this.setGroup.bind(this),
            },
            {
                key: "ravne",
                text: "RAVNE",
                iconProps: { iconName: "Group" },
                ws: SharedData.deepGet("wsChannels", "driveRavneByDay", "channelId"),
                onClick: this.setGroup.bind(this),
            },
            {
                key: "all",
                text: "SIJ GROUP",
                iconProps: { iconName: "HomeGroup" },
                ws: SharedData.deepGet("wsChannels", "driveAllByDay", "channelId"),
                onClick: this.setGroup.bind(this),
            },
        ];

        this.state = {
            graphIsLoading: true,
            datePickerDate: new Date(SharedData.get("timeyear"), SharedData.get("timemonth"), SharedData.get("timedate")),
            selectedWorkgroup: this.workgroups.find((g) => g.key === "all"),
        };

        this.abortController = new AbortController();

        this.chartRef = React.createRef();
        this.chartOptions = getDefaultChartOptions({
            chart: {
                height: "555px",
                tooltipShared: true,
            },
            export: {
                filename: "SIJ Group drive",
                csv: {
                    itemDelimiter: ';',
                    decimalPoint: ','
                }
            },
            yAxis: [
                {
                    id: "energy",
                    name: "Energy (MWh)",
                    min: 0,
                },
            ],
            series: [
                {
                    id: "schedule",
                    name: "Schedule",
                    color: getBrandedColor("orange"),
                    type: "line",
                    step: "left",
                    lineWidth: 4,
                    valueSuffix: "MWh",
                    pointPlacement: "left",
                    zIndex: 3,
                    valueDecimals: 2,
                },
                {
                    id: "realization",
                    name: "Realization",
                    type: "column",
                    color: getBrandedColor("green"),
                    borderColor: "rgba(30, 40, 60, 0.3)",
                    // borderColor: 'rgba(255, 255, 255, 0.3)',
                    pointPlacement: "between",
                    valueSuffix: "MWh",
                    zIndex: 1,
                    valueDecimals: 2,
                },
                {
                    id: "upperLimit",
                    name: "Upper limit",
                    step: "left",
                    type: "line",
                    color: getBrandedColor("red"),
                    pointPlacement: "left",
                    lineWidth: 2,
                    valueSuffix: "MWh",
                    zIndex: 2,
                    valueDecimals: 2,
                    visible: false,
                },
                {
                    id: "lowerLimit",
                    type: "line",
                    step: "left",
                    name: "Lower limit",
                    color: getBrandedColor("blue"),
                    pointPlacement: "left",
                    lineWidth: 2,
                    valueSuffix: "MWh",
                    zIndex: 2,
                    valueDecimals: 2,
                    visible: false,
                },
            ],
        });
    }

    componentDidMount() {
        this._isMounted = true;
        EventEmitter.dispatch("subscribeTo", [this.state.selectedWorkgroup.ws]);
        this.getInitialData(this.state.datePickerDate);

        this.workgroups.forEach((workGroup) => {
            EventEmitter.subscribe(
                `${workGroup.ws}/unset`,
                (newValue) => {
                    if (this._isMounted && !this.state.graphIsLoading && newValue && workGroup.key === this.state.selectedWorkgroup.key) {
                        let dayStart = this.state.selectedDayMoment.clone().startOf("day").unix();
                        let dayEnd = this.state.selectedDayMoment.clone().endOf("day").unix();

                        setDataPointsToGraphSeries({
                            data: Object.values(newValue)
                                .filter((a) => a.intervalStart >= dayStart && a.intervalStart <= dayEnd)
                                .sort((a, b) => a.intervalStart - b.intervalStart)
                                .map((o) => {
                                    return { timestamp: o.intervalStart * 1000, realization: o.overallCalculatedEnergy / 1000 };
                                }),
                            series: ["realization"],
                            chart: this.chartRef.current.chart,
                        });
                    }
                },
                this.constructor.name
            );
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
        this._isMounted = false;
        EventEmitter.dispatch("unsubscribeFrom", [this.state.selectedWorkgroup.ws]);
        this.workgroups.forEach((workGroup) => {
            EventEmitter.unsubscribe(`${workGroup.ws}/unset`, this.constructor.name);
        });
    }

    getInitialData(date) {
        let selectedDay = moment.tz(
            {
                year: date.getFullYear(),
                month: date.getMonth(),
                date: date.getDate(),
            },
            "Europe/Ljubljana"
        );

        let roundInterval = 60 /*s*/ * 15; /*mins*/ // round to 15 minutes

        restAPIget(
            `/measurements/energyandpower/interval/grouped/${this.state.selectedWorkgroup.key}/${selectedDay
                .clone()
                .startOf("day")
                .unix()}/${selectedDay.clone().endOf("day").unix()}/${roundInterval}`,
            this.abortController.signal,
            { route: "" }
        ).then((result) => {
            if (result.isOk && this._isMounted && result.data) {
                let chartData = Object.values(result.data).map((d) => {
                    return {
                        timestamp: Number(d.intervalStart) * 1000,
                        schedule: Number(d.scheduleEnergy) / 1000,
                        realization: Number(d.overallCalculatedEnergy) / 1000,
                        upperLimit: Number(d.upperLimit) / 1000,
                        lowerLimit: Number(d.lowerLimit) / 1000,
                    };
                });

                // push last item duplication in order to make the graph look beatiful
                if (chartData.length) {
                    chartData.push({
                        timestamp: chartData[chartData.length - 1].timestamp + roundInterval * 1000 - 1,
                        schedule: chartData[chartData.length - 1].schedule,
                        // realization: chartData[chartData.length - 1].realization,
                        upperLimit: chartData[chartData.length - 1].upperLimit,
                        lowerLimit: chartData[chartData.length - 1].lowerLimit,
                    });
                }

                setInitialDataToGraphSeries(chartData, ["schedule", "realization", "upperLimit", "lowerLimit"], this.chartRef.current.chart);

                this.setState({ graphIsLoading: false, selectedDayMoment: selectedDay });
            }
        });
    }

    setGroup(event, val) {
        this.setState(
            (prevstate) => {
                EventEmitter.dispatch("unsubscribeFrom", [prevstate.selectedWorkgroup.ws]);
                EventEmitter.dispatch("subscribeTo", [val.ws]);
                return {
                    selectedWorkgroup: {
                        key: val.key,
                        text: val.text,
                        ws: val.ws,
                    },
                    graphIsLoading: true,
                };
            },
            () => {
                deleteGraphSeries({ chart: this.chartRef.current.chart });
                this.getInitialData(this.state.datePickerDate);
            }
        );
    }

    render() {
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <NContainer
                            title={
                                <CommandButton
                                    menuProps={{ items: this.workgroups }}
                                    styles={{ label: { fontSize: "16px", fontWeight: "bold" } }}
                                    iconProps={{ iconName: "" }}
                                    title="workspacePicker"
                                    text={this.state.selectedWorkgroup.text + " drive"}
                                    onChange={(ev, val) => {
                                        console.log(ev, val);
                                    }}
                                />
                            }
                            isLoading={this.state.graphIsLoading}
                            style={{ maxHeight: "600px" }}
                            rightItems={[
                                {
                                    key: "ExportToCSV",
                                    type: "actionButton",
                                    text: "CSV",
                                    icon: "DownloadDocument",
                                    onClick: function () {
                                        this.chartRef.current.chart.downloadCSV();
                                    }.bind(this),
                                },
                                { type: "emptySpace" },
                                {
                                    key: "datePicker",
                                    type: "datePicker",
                                    value: this.state.datePickerDate,
                                    minDate: new Date(2020, 3, 17),
                                    onClick: function (newDate) {
                                        this.setState(
                                            {
                                                datePickerDate: newDate,
                                                graphIsLoading: true,
                                            },
                                            () => {
                                                deleteGraphSeries({ chart: this.chartRef.current.chart });
                                                this.getInitialData(newDate);
                                            }
                                        );
                                    }.bind(this),
                                },
                            ]}
                        >
                            <div id="chart">
                                <HighchartsReact highcharts={Highcharts} ref={this.chartRef} options={this.chartOptions} />
                            </div>
                        </NContainer>
                    </div>
                </div>
            </div>
        );
    }
}
