import moment from 'moment-timezone';

const SharedData = {
    data: {},

    /**
     * @description Set new value in shared data (dictionary)
     * @param  {(String | Number)} key Unique key in dictionary
     * @param  {} value Value for the unique key
     */
    set: function (key, value) {
        if (key === 'timestamp' || key === 'syncedTimestamp') {
            let sloDate = moment.unix(value).tz('Europe/Ljubljana');
            this.data['timeyear'] = sloDate.get('year');
            this.data['timemonth'] = sloDate.get('month');
            this.data['timedate'] = sloDate.get('date');
            this.data['timehour'] = sloDate.get('hour');
            this.data['timeminute'] = sloDate.get('minute');
        }

        switch (key) {
            case "syncedTimestamp":
                this.data['timesync'] = Date.now();
                this.data['timestamp'] = value;
                break;

            default:
                this.data[key] = value;
                break;
        }
    },

    /**
     * @description Get value for key
     * @param  {(String | Number)} key Unique key in dictionary
     */
    get: function (key) {
        return this.data[key];
    },

    /**
     * @description Get value for a chain of keys, eq: assets, key, name results: assets.key.name
     */
    deepGet: function () {
        let recursiveMethod = function (obj, properties) {
            // If we have reached an undefined/null property
            // then stop executing and return undefined.
            if (obj === undefined || obj === null) {
                return;
            }

            // If the path array has no more elements, we've reached
            // the intended property and return its value.
            if (properties.length === 0) {
                return obj;
            }

            // Prepare our found property and path array for recursion
            var foundSoFar = obj[properties[0]];
            var remainingProperties = properties.slice(1);

            return recursiveMethod(foundSoFar, remainingProperties);
        };

        return recursiveMethod(this.data, [...arguments]);
    },

    /**
     * @description Remove key and value for specified key
     * @param  {(String | Number)} key Unique key in dictionary
     */
    delete: function (key) {
        delete this.data[key];
    },

    /**
     * @description Console.log out all the data in the object
     */
    debug: function () {
        console.log(this.data);
    }
}

export default SharedData;