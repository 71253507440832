import React from 'react';

export class NLabel extends React.Component {
    render() {
        return (
            <div style={{
                // webkitFontSmoothing: 'antialiased',
                fontSize: '14px',
                fontWeight: '600',
                color: 'rgb(255, 255, 255)',
                boxSizing: 'border-box',
                boxShadow: 'none',
                marginTop: '0px',
                marginRight: '0px',
                marginBottom: '0px',
                marginLeft: '0px',
                display: 'block',
                paddingTop: '5px',
                paddingRight: '0px',
                paddingBottom: '5px',
                paddingLeft: '0px',
                overflowWrap: 'break-word',
                ...this.props.style
            }}>
                {this.props.children}
            </div>
        );
    }
}